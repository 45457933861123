<nz-content class="content-wrapper">
    <div class="form-container">
      <div class="title">Création de l'administrateur</div>
      <form
        class="form-layout"
        nz-form
        [formGroup]="createForm"
        (ngSubmit)="createAdmin()"
      >
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Prénom</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Le prénom est obligatoire" [nzSpan]="14">
                <input nz-input formControlName="first_name" />
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Nom</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Le nom est obligatoire" [nzSpan]="14">
                <input nz-input formControlName="last_name" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Nom d'utilisateur</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Le nom d'utilisateur est obligatoire" [nzSpan]="14">
                <input nz-input formControlName="username" />
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Email</nz-form-label>
              <nz-form-control nz-col nzErrorTip="L'email est obligatoire" [nzSpan]="14">
                <input nz-input formControlName="email" type="email" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">
          
        </div>
  
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Mot de passe</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Le mot de passe est obligatoire" [nzSpan]="14">
                <nz-input-group [nzSuffix]="suffixTemplate">
                <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" type="password" />
                <ng-template #suffixTemplate>
                    <span
                      nz-icon
                      [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                      (click)="passwordVisible = !passwordVisible"
                    ></span>
                  </ng-template>
            </nz-input-group>
              </nz-form-control>
              
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Confirmation Mot de passe</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Les deux mots de passe doivent être identiques" [nzSpan]="14">
                <nz-input-group [nzSuffix]="confirmsuffixTemplate">
                <input [type]="confirmpasswordVisible ? 'text' : 'password'" nz-input formControlName="confirmPassword" type="password" />
                <ng-template #confirmsuffixTemplate>
                    <span
                      nz-icon
                      [nzType]="confirmpasswordVisible ? 'eye-invisible' : 'eye'"
                      (click)="confirmpasswordVisible = !confirmpasswordVisible"
                    ></span>
                  </ng-template>
            </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Rôle</nz-form-label>
              <nz-form-control nz-col nzErrorTip="Le rôle est obligatoire" [nzSpan]="14">
                <nz-select formControlName="role">
                  <nz-option nzValue="ROLE_SUPER_ADMIN" nzLabel="Super administrateur"></nz-option>
                  <nz-option nzValue="ROLE_ADMIN" nzLabel="Administrateur"></nz-option>
                  <nz-option nzValue="ROLE_CHARGE_AFFAIRES" nzLabel="Chargé d'Affaires"></nz-option>
                  <nz-option nzValue="ROLE_CONTROLEUR_CEE" nzLabel="Contrôleur CEE"></nz-option>
                  <nz-option nzValue="ROLE_CONTROLEUR_CEE_MPR" nzLabel="Contrôleur CEE + MPR"></nz-option>
                  <nz-option nzValue="ROLE_OBSERVATEUR" nzLabel="Observateur"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col [nzSpan]="8">Identifiant CRM</nz-form-label>
              <nz-form-control nz-col [nzSpan]="14">
                <input nz-input formControlName="id_crm" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row class="row-content">
          <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
                >Origine</nz-form-label
              >
              <nz-form-control
                [nzSpan]="14"
                nz-col
                nzErrorTip="Le client est obligatoire"
              >
                <nz-select
                  formControlName="client_id"
                  nzAllowClear="true"
                  [nzDropdownMatchSelectWidth]="false"
                >
                  <nz-option
                    *ngFor="let client of clients"
                    [nzValue]="client.id"
                    [nzLabel]="client.name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
                >Accès clients</nz-form-label
              >
              <nz-form-control
                [nzSpan]="14"
                nz-col
                nzErrorTip="Les accès clients sont obligatoires"
              >
                <nz-select
                  formControlName="clients_access"
                  nzAllowClear="true"
                  nzMode="multiple"
                  [nzDropdownMatchSelectWidth]="false"
                  [ngModel]="clients_access"
                >
                  <nz-option
                    *ngFor="let client of clients"
                    [nzValue]="client.id"
                    [nzLabel]="client.name"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div class="button-wrapper">
          <div class="additional-buttons">
            <button
              nz-button
              nzTooltipTitle="Sauvegarder"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small save-button"
              [disabled]="!checkFormChanges()"
              type="submit"
            >
              <span class="icon">
                <span nz-icon nzType="save"></span>
              </span>
            </button>
            <button
              nz-button
              nzTooltipTitle="Annuler"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small cancel-button"
              (click)="goBack()"
            >
              <span class="icon">
                <span nz-icon nzType="rollback"></span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </nz-content>
  