<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form
        [formGroup]="queryForm"
        [nzSpan]="canAction('AJOUTER_REGLEMENTAIRE') ? 21 : 24"
        nz-col
        nz-form
      >
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
            (keyup.enter)="queryReglementaires()"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher dans les fiches réglementaires ..."
            type="text"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="queryReglementaires()" nz-icon nzType="search"></span>
      </ng-template>
      <div
        *ngIf="canAction('AJOUTER_REGLEMENTAIRE')"
        [nzSpan]="3"
        class="padding-left-10"
        nz-col
      >
        <button
          class="add-button"
          nz-button
          nzType="primary"
          routerLink="/reglementaire/create"
        >
          <span nz-icon nzTheme="outline" nzType="plus-circle"></span>
          Ajouter
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || reglementaires.length === 0" />

    <nz-table
      #fixedTable
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzData]="reglementaires"
      [nzFrontPagination]="false"
      [nzLoading]="isLoadingData"
      [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize"
      [nzShowTotal]="total_reglementaires_template"
      [nzTotal]="total_reglementaires"
      class="projet-list-table"
      nzPaginationPosition="both"
    >
      <ng-template #total_reglementaires_template>
        <span
          >{{ total_reglementaires }}
          {{ total_reglementaires == 1 ? " fiche" : " fiches" }}
        </span>
        <ng-template #popConfirmIcon></ng-template>
      </ng-template>

      <thead class="liste-projets-head">
        <tr>
          <th>Numéro</th>
          <th nzAlign="center">Version</th>
          <th>Titre</th>
          <th>Date de la version</th>
          <th>Date d'abrogation</th>
          <th nzAlign="center">Contrôle sur site</th>
          <th
            *ngIf="
              canEdit('REGLEMENTAIRE') ||
              canAction('MAJ_REGLEMENTAIRE') ||
              canDelete('REGLEMENTAIRE')
            "
            nzAlign="center"
            nzRight
            nzWidth="90px"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td>
            <a routerLink="/reglementaire/{{ data.numero }}">{{
              data.numero
            }}</a>
          </td>
          <td nzAlign="center">{{ data.version_simulateur }}</td>
          <td>{{ data.titre }}</td>
          <td>{{ data.createdAt | date : "dd/MM/YYYY" }}</td>
          <td>{{ data.ends_at | date : "dd/MM/YYYY" }}</td>
          <td nzAlign="center">
            <nz-tag
              [nzBordered]="false"
              [nzColor]="data.IsControlled ? 'green' : 'volcano'"
              class="active-tag"
              >{{ data.IsControlled ? "Oui" : "Non" }}
            </nz-tag>
          </td>
          <td
            *ngIf="
              canEdit('REGLEMENTAIRE') ||
              canAction('MAJ_REGLEMENTAIRE') ||
              canDelete('REGLEMENTAIRE')
            "
            nzAlign="center"
            nzRight
            nzWidth="90px"
          >
            <button
              [nzDropdownMenu]="menu"
              class="action-buttons"
              nz-button
              nz-dropdown
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/reglementaire/{{ data.numero }}">
                  <a
                    ><span nz-icon nzTheme="outline" nzType="eye"></span>
                    Détails</a
                  >
                </li>
                <li
                  *ngIf="canEdit('REGLEMENTAIRE')"
                  nz-menu-item
                  routerLink="/reglementaire/edit/{{ data.numero }}"
                >
                  <a
                    ><span nz-icon nzTheme="outline" nzType="edit"></span>
                    Éditer</a
                  >
                </li>
                <li
                  (nzOnCancel)="cancel()"
                  (nzOnConfirm)="majProjets(data.numero)"
                  *ngIf="canAction('MAJ_REGLEMENTAIRE')"
                  [nzPopconfirmTitle]="
                    'Êtes-vous sûr de vouloir mettre à jour les projets contenant la fiche ' +
                    data.numero +
                    ' ?'
                  "
                  nz-menu-item
                  nz-popconfirm
                >
                  <a
                    ><span nz-icon nzTheme="outline" nzType="edit"></span> MAJ
                    Projets</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
