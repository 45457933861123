import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-list-discussions',
  templateUrl: './list-discussions.component.html',
  styleUrl: './list-discussions.component.css'
})
export class ListDiscussionsComponent {
  pageIndex = 1;
  pageSize = 20;
  totalChats: number = 0;
  isLoadingData = true;
  toogleFilters: boolean = false;
  projets: any = [];
  nzShowArrow = true;

  chats: any[] = [];
  allChecked = false;
  indeterminate = false;
  checkedChats = new Set<number>();
  queryForm: FormGroup = this.fb.group({});
  toggleFilters: boolean = false;
  users: any;
  selected_users: any;
  selected_projets: any;

  constructor(
    private chatService: ChatService,
    private usersService: UsersService,
    private projetsservice: ProjetsService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    const stringControlNames = [
      'search_text',
      'utilisateur',
      'projet'
    ];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(null));
    });
    
    this.usersService.getAllUsers().subscribe((users: any) => {
      this.users = users;
    });
    this.queryForm.get('utilisateur')?.valueChanges.subscribe((userId) => {
      this.queryForm.get('projet')?.setValue(null);
      if (userId) {
        this.GetUserProjects(userId);
      } else {
        this.projets = [];
      }
    });
    this.queryChats();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checkedChats.add(id);
    } else {
      this.checkedChats.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.chats.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.allChecked = this.chats.every((item) =>
      this.checkedChats.has(item.id)
    );
    this.indeterminate =
      this.chats.some((item) => this.checkedChats.has(item.id)) &&
      !this.allChecked;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    console.log(this.queryForm.value);
    this.chatService
      .getAllDiscussionsFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.chats = data.rows;
        this.totalChats = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_users = [];
    this.queryChats();
  }

  cancel(): void { }

  async confirmDelete(): Promise<void> {
    for (const chatId of this.checkedChats) {
      await this.chatService.delete(chatId);
    }
    this.checkedChats.clear();
    this.queryChats();
  }

  async deleteChat(id: number) {
    this.isLoadingData = true;
    await this.chatService.delete(id);
    this.chats = this.chats.filter((o) => o.id != id);
    this.queryChats();
  }

  queryChats() {
    this.isLoadingData = true;
    console.log(this.queryForm.value);
    this.chatService
      .getAllDiscussionsFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.chats = data.rows;
        this.totalChats = data.count;
        this.isLoadingData = false;
      });
  }

  createChat() {
    this.router.navigate(['/messagerie/new']);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  GetUserProjects(user_id: number): any {
    this.projetsservice.GetUserProjects(user_id).subscribe((data) => {
      this.projets = data;
    })
  }
}

