<nz-content class="content-wrapper">
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <h2 class="societe-title">{{ societe.name }}</h2>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col>
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement ce bug ?"
              nzType="default"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              [nzIcon]="popConfirmIcon"
              *ngIf="canDelete('SOCIETES')"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              nz-button
              nzType="default"
              class="secondary-button"
              class="secondary-button mass-action-button margin-left-10"
              routerLink="/societes/edit/{{ societe.id }}"
              *ngIf="canEdit('SOCIETES')"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div nz-row [nzGutter]="16" class="details-row">
      <div nz-col [nzSpan]="12">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="idcard" nzTheme="outline"></span>
            IDENTIFICATION
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Raison sociale
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.name }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Secteur CEE principal
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              {{ societe?.secteur_principal }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Chargé d'affaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              {{ societe?.gestionnaire?.first_name }}
              {{ societe?.gestionnaire?.last_name }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Secteur d'activité
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              {{ societe?.secteur_activite }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">SIRET</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.siret }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">Client</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.source }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">Adresse</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.address }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              N° client externe
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              {{ societe?.num_client_ext }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">Code postal</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.zip }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">Classement</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              {{ societe?.classement }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">Ville</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">{{ societe?.ville }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">ID CRM</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              <a
                href="https://crm.zoho.eu/crm/org20063788205/tab/CustomModule1/{{
                  societe?.idPartenaireCrm
                }}"
                target="_blank"
              >
                {{ societe?.idPartenaireCrm }}</a
              >
            </div>
          </div>
        </nz-card>
      </div>
    
    <div nz-col [nzSpan]="12">
      <div nz-row class="details-container">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="profile" nzTheme="outline"></span> PROFIL
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Apporteur d'affaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              <nz-tag *ngIf="societe?.isApporteurAffaire" nzColor="success">
                <span>Oui</span>
              </nz-tag>
              <nz-tag *ngIf="!societe?.isApporteurAffaire" nzColor="error">
                <span>Non</span>
              </nz-tag>
            </div>
          </div>
          <div nz-row *ngIf="isApporteurAffaire">
            <div nz-col [nzSpan]="10" class="field-grey-label">
              Entreprises travaux partenaires
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="13" class="wrap">
              <div *ngFor="let et of entreprises_travaux">
                <div>{{ et.nom }}</div>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
    <br>
    <div nz-row class="details-container">
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzType="setting" nzTheme="outline"></span> PARAMÉTRAGE
        </h4>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Catégorie de prix
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            {{ societe?.categorie?.nom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé Hors cdp
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            {{ societe?.oblige?.nom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Coup de pouce chauffage résidentiel individuel
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <nz-tag *ngIf="societe?.isCPResidentielChauffag" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPResidentielChauffag" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Coup de pouce rénovation globale des BRC
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <nz-tag *ngIf="societe?.isCPRenoGlobaleCollectif" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPRenoGlobaleCollectif" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Coup de pouce chauffage tertiaire et résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <nz-tag *ngIf="societe?.isCPTertiaireChauffage" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPTertiaireChauffage" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Coup de pouce Pilotage connecté
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <nz-tag *ngIf="societe?.isCPPilotageConnecte" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.isCPPilotageConnecte" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Accès partagé aux projets
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <nz-tag *ngIf="societe?.shared_access" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!societe?.shared_access" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé cdp précaire Chauffage BAR indiv
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            {{ societe?.oblige_precaire?.nom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé cdp classique Chauffage BAR indiv
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            {{ societe.oblige_cdp?.nom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé Coup de pouce réno globale collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <ng-container
            *ngIf="
              societe.oblige_cdp_reno_globale_collectif?.nom;
              else fetchNom
            "
          >
            {{ societe.oblige_cdp_reno_globale_collectif?.nom }}
          </ng-container>
          <ng-template #fetchNom>
            {{ getObligeNomById(societe.oblige_cdp_reno_globale_collectif) }}
          </ng-template>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé Coup de pouce Tertiaire et Résidentiel collectif
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            {{ societe.oblige_cdp_bat_res_coll?.nom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="10" class="field-grey-label">
            Obligé Coup de pouce pilotage connecté
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="13" class="wrap">
            <ng-container
              *ngIf="societe.oblige_cdp_pilotage_connecte?.nom; else fetchNom"
            >
              {{ societe.oblige_cdp_pilotage_connecte?.nom }}
            </ng-container>
            <ng-template #fetchNom>
              {{ getObligeNomById(societe.oblige_cdp_pilotage_connecte) }}
            </ng-template>
          </div>
        </div>
      </nz-card>
    </div>
    <br>
    <div nz-row class="details-container">
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzType="team" nzTheme="outline"></span> UTILISATEURS
        </h4>
        <ul nz-list [nzDataSource]="societe.users" nzSize="small">
          @for (user of societe.users; track user) {
          <li nz-list-item nzNoFlex>
            <ul nz-list-item-actions></ul>
            <a href="/utilisateurs/{{ user.id }}" target="_blank">{{
              user.firstname + " " + user.lastname
            }}</a>
          </li>
          }
        </ul>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
