import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from 'src/app/services/loading.service';
import { ObligesService } from 'src/app/services/obliges.service';

@Component({
  selector: 'app-edit-obliges',
  templateUrl: './edit-obliges.component.html',
  styleUrl: './edit-obliges.component.css',
})
export class EditObligesComponent {
  editForm: FormGroup;
  oblige: any;
  oblige_id: number;
  isFormModified: boolean;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});
  isFormSubmitting = false;

  controlArray: Array<{ index: number; show: boolean }> = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private obligeService: ObligesService,
    private message: NzMessageService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) { }
  ngOnInit(): void {
    this.loadingService.upLoading();
    this.oblige_id = this.route.snapshot.params['id'];
    this.obligeService
      .getObligeById(this.oblige_id)
      .subscribe((oblige: any) => {
        this.oblige = oblige;
        this.loadingService.offLoading();
      });
    this.editForm = this.fb.group({
      nom: ['', Validators.required],
      nomOfficiel: ['', Validators.required],
      mentions: [''],
    });
  }
  createBasicMessage(): void {
    this.message.success('la modification est faite avec succès', {
      nzDuration: 3000,
    });
  }
  goBack() {
    this.router.navigate(['/obliges']);
  }

  editOblige() {
    if (this.editForm.valid) {
      this.isFormSubmitting = true;
      const payload = { ...this.oblige };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      console.log('payload :', payload);
      this.obligeService
        .editOblige(this.oblige_id, payload)
        .subscribe((updatedOblige: any) => {
          this.oblige = updatedOblige;
          this.createBasicMessage();
          this.router.navigate(['/obliges']);
        });
    } else {
      console.log('Form is invalid');
    }
  }
  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine;
    return this.isFormModified;
  }

}
