import {Component} from '@angular/core';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css'
})
export class ForgetPasswordComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private notification: NzNotificationService) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

  }

  send() {
    this.authService.sendResetLink(this.form.value.email).subscribe({
      next: (res) => {
        this.notification.success('Succès', 'Un lien de réinitialisation a été envoyé à votre adresse e-mail', {
          nzClass: 'success',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#f6ffed',
          }
        });
        this.router.navigate(['/auth/login'])
      },
      error: (err) => {
        this.notification.error('Erreur', err.error.message, {
          nzClass: 'error',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#fdeded',
          }
        });
      }
    })
  }
}
