import { Component } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PrixService } from 'src/app/services/prix.service';

@Component({
  selector: 'app-create-categorie',
  templateUrl: './create-categorie.component.html',
  styleUrl: './create-categorie.component.css'
})
export class CreateCategorieComponent {
  categorie_prix: any;
  editForm: FormGroup;
  tranches_prix: any;
  rem_partenaire: any;
  editedIntervals: boolean;
  
  constructor(
    private route: ActivatedRoute,
    private prixService: PrixService,
    private message: NzMessageService,
    private router: Router,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnInit() {
    this.editForm = this.fb.group({
      code: ['', Validators.required],
      nom: ['', Validators.required],
      prix_cdp: ['', Validators.required],
      prix_precaire: ['', Validators.required],
      prix_cdp_chauffage_precaire_boosted: ['', Validators.required],
      prix_cdp_chauffage_modeste_boosted: ['', Validators.required],
      prix_cdp_chauffage_classique_boosted: ['', Validators.required],
      prix_cdp_reno_globale_collectif: ['', Validators.required],
      prix_cdp_pilotage_connecte: ['', Validators.required],
    });

    this.tranches_prix = [
      {
        volume_min: 0,
        volume_max: '+∞',
        prix: undefined,
      }
    ]
    
    this.rem_partenaire = [
      {
        value: 0,
        block_auto_cee: false,
      }
    ]

    this.editForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  createCategoriePrix() {
    let categorie = this.editForm.value;
    categorie.tranches_prix = this.tranches_prix;
    categorie.renumerations = this.rem_partenaire;
    categorie.tranches_prix[categorie.tranches_prix.length - 1].volume_max = '-1';

    this.prixService.createCategorie(categorie).subscribe(
      () => {
        this.message.success('Catégorie de prix créée avec succès', {
          nzDuration: 3000,
        });
        this.goBack();
      },
      () => {
        this.message.error(
          "Une erreur est survenue lors de la création de votre catégorie de prix.",
          {
            nzDuration: 3000,
          }
        );
      }
    );
  }

  checkFormChanges(): boolean {
    return (
      (!this.editForm.pristine || this.editedIntervals) &&
      this.isValidTranchesPrix() &&
      this.isValidRemPartenaire() &&
      this.editForm.valid
    );
  }

  goBack() {
    this.router.navigate(['/categories-prix']);
  }

  addTranchePrix(index: number) {
    this.tranches_prix.splice(index + 1, 0, {
      volume_min: undefined,
      volume_max: undefined,
      prix: undefined,
    });
    this.fixerIntervalle();
    this.editIntervals();
  }

  removeTranchePrix(index: number) {
    if (this.tranches_prix.length > 1) {
      this.tranches_prix.splice(index, 1);
      this.fixerIntervalle();
      this.editIntervals();
    }
  }

  addRemPartenaire(index: number) {
    this.rem_partenaire.splice(index + 1, 0, {
      value: undefined,
      block_auto_cee: false,
    });
    this.editIntervals();
  }

  removeRemPartenaire(index: number) {
    if (this.rem_partenaire.length > 1) {
      this.rem_partenaire.splice(index, 1);
      this.editIntervals();
    }
  }

  fixerIntervalle() {
    this.tranches_prix[0].volume_min = 0;
    this.tranches_prix.forEach((t: any, index: number) => {
      if (t.volume_max === '+∞' && index !== this.tranches_prix.length - 1) {
        t.volume_max = undefined;
      }
    });
    this.tranches_prix[this.tranches_prix.length - 1].volume_max = '+∞';
  }

  editIntervals() {
    this.editedIntervals = true;
  }

  isValidTranchesPrix() {
    let valid_form = true;
    this.tranches_prix.forEach((t: any) => {
      if (
        t.volume_min === undefined ||
        t.volume_min === '' ||
        t.volume_max === undefined ||
        t.volume_max === '' ||
        t.prix === undefined ||
        t.prix === ''
      ) {
        valid_form = false;
      }
    });
    if (this.tranches_prix.length > 1) {
      for (let i = 1; i < this.tranches_prix.length; i++) {
        if (
          this.tranches_prix[i].volume_min !==
          this.tranches_prix[i - 1].volume_max
        ) {
          valid_form = false;
        }
      }
    }
    return valid_form;
  }

  isValidRemPartenaire() {
    let valid_form = true;
    this.rem_partenaire.forEach((r: any) => {
      if (r.value === undefined || r.value === '') {
        valid_form = false;
      }
    });
    return valid_form;
  }
}
