import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProjetsService } from 'src/app/services/projets.service';

@Component({
  selector: 'app-details-projet',
  templateUrl: './details-projet.component.html',
  styleUrls: ['./details-projet.component.css'],
})
export class DetailsProjetComponent implements OnInit {
  projet: any;
  fiches_cee: any[] = [];
  documents: any[] = [];
  logs: any[] = [];
  projet_id: any;
  active_tab: string = 'GLOBAL';
  is_zni: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private projetsService: ProjetsService,
    private documentService: DocumentService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.projet_id = this.route.snapshot.params['id'];
    this.projetsService
      .getProjetById(this.projet_id)
      .subscribe((projet: any) => {
        this.authService.canAccessClientData(projet.source);
        this.projet = projet;
        this.fiches_cee = projet.fiches;
        this.documents = projet.documents;
        this.is_zni =
          projet.zip.startsWith('97') || projet.zip.startsWith('20');
        this.loadingService.offLoading();
      });

    this.projetsService
      .getProjectLogs(this.projet_id)
      .subscribe((logs: any) => {
        this.logs = logs;
      });
  }

  onTabChange(selected_tab: string) {
    this.active_tab = selected_tab;
  }

  getProjectStageTagColor(projet: any) {
    if (projet.hasReuploadedFiles) {
      return 'purple';
    }

    if (projet.is_blocked) {
      return 'red';
    }

    switch (projet.statut.code) {
      case 'OD':
      case 'PAVT2':
      case 'PAVT4':
      case 'PAPT2':
      case 'PAPT5':
      case 'PAPT7':
      case 'CTRL':
      case 'DCEEC':
      case 'OR':
      case 'ECPNCEE':
      case 'VPNCEE':
        return 'orange';
      case 'PAVT3':
      case 'PAPT3':
      case 'PAPT6':
        return 'volcano';
      default:
        return 'geekblue';
    }
  }

  edit(item: any) {}

  getDocExtension(doc: any) {
    return doc ? doc.name.split('.').pop().toUpperCase() || '' : '';
  }

  previewDocument(document: any) {
    this.documentService.previewDocument(document.id).subscribe((res: any) => {
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  downloadDocument(document: any) {
    this.documentService.previewDocument(document.id).subscribe((res: any) => {
      importedSaveAs(res, document.name);
    });
  }

  bloquerProjet() {
    this.projetsService.bloquerProjet(this.projet.id).subscribe((res: any) => {
      this.projet = res;
    });
  }

  debloquerProjet() {
    this.projetsService
      .debloquerProjet(this.projet.id)
      .subscribe((res: any) => {
        this.projet = res;
      });
  }

  restoreProjet() {
    this.projetsService.restoreProject(this.projet.id).subscribe(
      (res) => {
        this.projetsService
          .getProjetById(this.projet_id)
          .subscribe((projet: any) => {
            this.projet = projet;
            this.message.success('Ce projet a été restauré avec succès.');
          });
      },
      (err) => {
        this.message.error(
          'Une erreur est survenue lors de restauration du projet.'
        );
      }
    );
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name, this.projet);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
