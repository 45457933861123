<nz-sider
  [(nzCollapsed)]="sidebarService.isCollapsed"
  [nzTrigger]="null"
  class="menu-sidebar"
  nzBreakpoint="md"
  nzCollapsible
  nzWidth="256px"
>
  <div class="sidebar-logo">
    <a (click)="sidebarService.toogle()" target="_blank">
      <div *ngIf="loadingAvatar; else avatarContent">
        <nz-spin nzSimple></nz-spin>
      </div>
      
      <ng-template #avatarContent>
        <nz-avatar nzIcon="user" [nzSrc]="imageSrc"></nz-avatar>
        
      </ng-template>
      <h1>Bonjour {{ getUsername() }}</h1>
    </a>
  </div>

  <ul
    [nzInlineCollapsed]="sidebarService.isCollapsed"
    class="menu-entry"
    nz-menu
    nzMode="inline"
    nzTheme="dark"
  >
    <li
      class="margin-top-bottom-4"
      nz-menu-item
      nzMatchRouter
      routerLink="/dashboard"
      *ngIf="canAccess('DASHBOARD')"
    >
      <span class="sidebar-icon" nz-icon nzType="dashboard"></span>
      <span>Dashboard</span>
    </li>

    <li
      (nzOpenChange)="openHandler('projets')"
      [(nzOpen)]="openMap['projets']"
      nz-submenu
      nzIcon="folder-open"
      nzTitle="Projets"
    >
      <ul>
        <li *ngIf="canAccess('PROJETS')">
          <a nz-menu-item nzMatchRouter routerLink="/projets"
            >Tous les projets</a
          >
        </li>
        <li *ngIf="canAccess('MES-PROJETS') && hasProjects">
          <a nz-menu-item nzMatchRouter routerLink="/mes-projets"
            >Mes Projets</a
          >
        </li>
        <li *ngIf="canAccess('CORBEILLE')">
          <a nz-menu-item nzMatchRouter routerLink="/corbeille">Corbeille</a>
        </li>
      </ul>
    </li>

    <li
      (nzOpenChange)="openHandler('contacts')"
      [(nzOpen)]="openMap['contacts']"
      nz-submenu
      nzIcon="team"
      nzTitle="Gestion des contacts"
    >
      <ul>
        <li *ngIf="canAccess('SOCIETES')">
          <a nz-menu-item nzMatchRouter routerLink="/societes">Sociétés</a>
        </li>
        <li *ngIf="canAccess('UTILISATEURS')">
          <a nz-menu-item nzMatchRouter routerLink="/utilisateurs"
            >Utilisateurs</a
          >
        </li>
        <li *ngIf="canAccess('BENEFICIAIRES')">
          <a nz-menu-item nzMatchRouter routerLink="/beneficiaires"
            >Bénéficiaires</a
          >
        </li>
        <li *ngIf="canAccess('MANAGERS')">
          <a nz-menu-item nzMatchRouter routerLink="/managers">Rôle Manager</a>
        </li>
      </ul>
    </li>

    <li
      (nzOpenChange)="openHandler('communications')"
      [(nzOpen)]="openMap['communications']"
      nz-submenu
      nzIcon="message"
      nzTitle="Communications"
      *ngIf="canAccess('COMMUNICATIONS')"
    >
      <ul>
        <li *ngIf="canAccess('MESSAGERIE')">
          <a nz-menu-item nzMatchRouter routerLink="/messagerie">Messagerie</a>
        </li>
        <li *ngIf="canAccess('BANDEAU-INFO')">
          <a nz-menu-item nzMatchRouter routerLink="/bandeau-info"
            >Bandeau Info</a
          >
        </li>
      </ul>
    </li>

    <li
      (nzOpenChange)="openHandler('parametrage')"
      [(nzOpen)]="openMap['parametrage']"
      nz-submenu
      nzIcon="setting"
      nzTitle="Paramétrages"
    >
      <ul>
        <li *ngIf="canAccess('PRIX')">
          <a nz-menu-item nzMatchRouter routerLink="/categories-prix">Prix</a>
        </li>
        <li *ngIf="canAccess('OBLIGES')">
          <a nz-menu-item nzMatchRouter routerLink="/obliges">Obligés</a>
        </li>
        <li *ngIf="canAccess('REGLEMENTAIRE')">
          <a nz-menu-item nzMatchRouter routerLink="/reglementaire"
            >Réglementaire</a
          >
        </li>
        <li *ngIf="canAccess('GESTION-COMMERCIALE')">
          <a nz-menu-item nzMatchRouter routerLink="/gestion-commerciale"
            >Gestion commerciale</a
          >
        </li>
        <li *ngIf="canAccess('PERSONNALISER-NEOSCOPE')">
          <a nz-menu-item nzMatchRouter routerLink="/personnaliser-neoscope"
            >Personnalisation</a
          >
        </li>
        <li *ngIf="canAccess('STATUTS-CEE')">
          <a nz-menu-item nzMatchRouter routerLink="/statuts-cee"
            >Liste des statuts CEE</a
          >
        </li>
        <li *ngIf="canAccess('STATUTS-MPR')">
          <a nz-menu-item nzMatchRouter routerLink="/statuts-mpr"
            >Liste des statuts MPR</a
          >
        </li>
        <li *ngIf="canAccess('ADMINISTRATEURS')">
          <a nz-menu-item nzMatchRouter routerLink="/administrateurs"
            >Administrateurs</a
          >
        </li>
      </ul>
    </li>

    <li
      (nzOpenChange)="openHandler('hub_developpeur')"
      [(nzOpen)]="openMap['hub_developpeur']"
      nz-submenu
      nzIcon="bug"
      nzTitle="Hub Développeur"
      *ngIf="canAccess('HUB-DEVELOPPEUR')"
    >
      <ul>
        <li *ngIf="canAccess('BUGS-ZOHO')">
          <a nz-menu-item nzMatchRouter routerLink="/bugs-zoho">Bugs Zoho</a>
        </li>
        <li *ngIf="canAccess('JOURNALISATION')">
          <a nz-menu-item nzMatchRouter routerLink="/journalisation"
            >Journalisation</a
          >
        </li>
      </ul>
    </li>

    <li
      *ngIf="canAccess('CLIENTS-NEOSCOPE')"
      nz-menu-item
      nzMatchRouter
      routerLink="/clients-neoscope"
    >
      <span class="sidebar-icon" nz-icon nzType="desktop"></span>
      <span *ngIf="!sidebarService.isCollapsed">Clients Neoscope</span>
    </li>
    <li
      class="margin-top-bottom-4"
      nz-menu-item
      nzMatchRouter
      routerLink="/profile"
    >
      <span class="sidebar-icon" nz-icon nzType="user"></span>
      <span>Mon profile</span>
    </li>
    <li (click)="logout()" class="logout" nz-menu-item nzMatchRouter>
      <span class="sidebar-icon" nz-icon nzType="logout"></span>
      <span>Se déconnecter</span>
    </li>
  </ul>
</nz-sider>
