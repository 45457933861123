import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  token: string = '';
  passwordVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.hasSpecialCharacter,
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.hasSpecialCharacter,
          ],
        ],
      },
      { validators: this.checkPasswords },
    );
  }

  hasSpecialCharacter(control: any) {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (specialCharacters.test(control.value)) {
      return null;
    }
    return { specialCharacter: true };
  }

  ngOnInit() {
    this.token = <string>this.route.snapshot.paramMap.get('token');
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  send() {
    if (this.form.invalid) {
      this.notification.error(
        'Erreur',
        this.form.errors?.['notSame']
          ? 'Les mots de passe ne correspondent pas'
          : 'Les mots de passe ne doivent pas être vides et doivent contenir au moins 8 caractères avec un caractère spécial',

        {
          nzClass: 'error',
          nzAnimate: true,
          nzStyle: {
            width: '350px',
            background: '#fdeded',
          },
        },
      );
      return;
    }

    this.authService
      .resetPassword(this.token, this.form.value.password)
      .subscribe({
        next: (res) => {
          this.notification.success(
            'Succès',
            'Votre mot de passe a été réinitialisé avec succès',
            {
              nzClass: 'success',
              nzAnimate: true,
              nzStyle: {
                width: '350px',
                background: '#f6ffed',
              },
            },
          );
          this.router.navigate(['/login']);
        },
        error: (err) => {
          this.notification.error('Erreur', err.error.message, {
            nzClass: 'error',
            nzAnimate: true,
            nzStyle: {
              width: '350px',
              background: '#fdeded',
            },
          });
        },
      });
  }
}
