<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form nz-form [formGroup]="queryForm" nz-col [nzSpan]="24">
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Recherche par titre, lien ou paramètres.."
            (keyup.enter)="queryZohoBugs()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryZohoBugs()"></span>
      </ng-template>
    </div>
    <br *ngIf="isLoadingData || zohoBugs.length === 0" />

    <nz-table
      #fixedTable
      [nzData]="zohoBugs"
      [nzFrontPagination]="false"
      [nzTotal]="total_zoho_bugs"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_zoho_bugs_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzLoading]="isLoadingData"
      class="zoho-bugs-list-table"
    >
    <ng-template #total_zoho_bugs_template>
      <span>{{ total_zoho_bugs }}{{ total_zoho_bugs == 1 ? ' bug' : ' bugs' }}</span>
      <ng-template #popConfirmIcon> </ng-template>
    </ng-template>
      <thead class="list-zoho-bugs-head">
        <tr>
          <th nzAlign="center">ID</th>
          <th nzAlign="center">Titre</th>
          <th nzAlign="center">Date</th>
          <th nzAlign="center">Corrigé</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td nzAlign="center">{{ data.id }}</td>
          <td nzAlign="center">{{ data.title }}</td>
          <td nzAlign="center">{{ data.createdAt | date : "dd/MM/yyyy - HH:mm" }}</td>
          <td nzAlign="center">
            <nz-tag *ngIf="data.fixed" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!data.fixed" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="zoho-bugs-actions-button"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/bugs-zoho/details/{{ data.id }}">
                  <a>
                    <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                    Détails
                  </a>
                </li>
                <li
                  *ngIf="!data.fixed"
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir modifier le bug sélectionné ?"
                  (nzOnConfirm)="resoudre(data.id)"
                  (nzOnCancel)="cancel()"
                >
                  <a>
                    <span
                      nz-icon
                      nzType="check-circle"
                      nzTheme="outline"
                    ></span>
                    Résolu
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
