<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>

  <div class="form-container">
    <div class="title">IDENTITÉ</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editUser()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Civilité</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Veuillez sélectionner la civilité !">
              <nz-select
                formControlName="civilite"
                [ngModel]="user?.civilite ?? ''"
                class="edit-field"
              >
                <nz-option nzValue="Madame" nzLabel="Madame"></nz-option>
                <nz-option nzValue="Monsieur" nzLabel="Monsieur"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Société</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner la société !"
            >
              <nz-select
                nzShowSearch
                formControlName="societe_id"
                [ngModel]="user.societe?.id ?? ''"
                [nzNotFoundContent]="add_new_societe"
                class="edit-field"
              >
              <ng-template #add_new_societe>
                <a href="/societes/create" target="_blank" class="underline">Créer une nouvelle société</a>
              </ng-template>
                <nz-option
                  *ngFor="let societe of societes"
                  [nzValue]="societe.id"
                  [nzLabel]="societe.name"
                ></nz-option>
                <nz-option
                  nzCustomContent
                  nzValue="add_new_societe"
                >
                  <a href="/societes/create" target="_blank" class="underline">Créer une nouvelle société</a>
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8">Nom</nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.lastname ?? ''"
                  formControlName="lastname"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Données diverses</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.socialReason ?? ''"
                  formControlName="socialReason"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Prénom
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.firstname ?? ''"
                  formControlName="firstname"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >ID CRM</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.idCrmContactPartenaire ?? ''"
                  formControlName="idCrmContactPartenaire"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">CONTACT</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editUser()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Adresse complète</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.adresse ?? ''"
                  formControlName="adresse"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Téléphone</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez renseigner 10 chiffres pour ce champ !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.phone ?? ''"
                  formControlName="phone"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8">Ville</nz-form-label>
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.ville ?? ''"
                  formControlName="ville"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8">Email</nz-form-label>
            <nz-form-control nz-col [nzSpan]="14" [nzErrorTip]="emailErrorTpl">
              <ng-template #emailErrorTpl let-control>
                @if (control.errors?.['email']) {
                  Ce n'est pas une adresse e-mail valide !
                }
                @if (control.errors?.['required']) {
                  Ce champ est obligatoire !
                }
              </ng-template>
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.email ?? ''"
                  formControlName="email"
                  name="email"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nzRequired nz-col [nzSpan]="8"
              >Code Postal
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez renseigner 5 chiffres pour ce champ !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="user?.codePostal ?? ''"
                  formControlName="codePostal"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24"></div>
      </div>
    </form>
  </div>
  <div class="form-container">
    <div class="title">PRÉFÉRENCES</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editUser()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Chargé d'Affaires</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-select
                formControlName="superAdmin_id"
                [ngModel]="user.superAdmin_id ?? ''"
                class="edit-field"
              >
                <nz-option
                  *ngFor="let chargeAffaires of chargesAffaires"
                  [nzValue]="chargeAffaires.id"
                  [nzLabel]="getName(chargeAffaires)"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Activation compte</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-select nzSize="small" formControlName="is_active" [ngModel]="user?.is_active ?? ''">
                <nz-option [nzValue]="1" nzLabel="Actif"></nz-option>
                <nz-option [nzValue]="0" nzLabel="En attente"></nz-option>
                <nz-option [nzValue]="-1" nzLabel="Refusé"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Rôle manager</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-switch
                nzSize="small"
                formControlName="isManager"
                [ngModel]="user?.isManager ?? ''"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"
              ></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Choix Newsletter</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-switch
                nzSize="small"
                formControlName="newsletter"
                [ngModel]="user?.newsletter ?? ''"
                nzCheckedChildren="Accepté"
                nzUnCheckedChildren="Refusé"
              ></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
    <div class="button-wrapper">
      <div class="additional-buttons">
        <button
          nz-button
          nzTooltipTitle="Sauvegarder"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small save-button"
          [disabled]="!checkFormChanges()"
          (click)="editUser()"
        >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
        </button>
        <button
          nz-button
          nzTooltipTitle="Annuler"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small cancel-button"
          (click)="cancelChanges()"
        >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</nz-content>
<ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
<ng-template #unCheckedTemplate
  ><span nz-icon nzType="close"></span
></ng-template>
<ng-template #popConfirmIcon> </ng-template>
