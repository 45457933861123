import {Component} from '@angular/core';
import {FormControl, FormGroup, FormRecord, NonNullableFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ReglementairesService} from 'src/app/services/reglementaires.service';

@Component({
  selector: 'app-create-reglementaire',
  templateUrl: './create-reglementaire.component.html',
  styleUrl: './create-reglementaire.component.css'
})
export class CreateReglementaireComponent {
  createForm: FormGroup;
  reglementaire: any;
  isFormModified: boolean;

  controlArray: Array<{ index: number; show: boolean }> = [];
  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{header: 1}, {header: 2}],
      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],
      [{indent: '-1'}, {indent: '+1'}],
      [{direction: 'rtl'}],
      [{size: ['small', false, 'large', 'huge']}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{color: []}, {background: []}],
      [{font: []}],
      [{align: []}],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };


  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private reglementaireService: ReglementairesService,
    private message: NzMessageService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      numero: ['', Validators.required],
      titre: ['', Validators.required],
      version_simulateur: ['', [
        Validators.required,
        Validators.pattern(/^A\d{2}-\d{1,2}$/)
      ]],
      infobulle: [''],
      IsControlled: [false],
      createdAt: [],
      infos_obligatoires: [''],
      ends_at: [null, Validators.required],
      pdf_link: ['', Validators.required],
      has_docs_justificatifs: [false],
      docs_justificatifs: [''],
      has_mpr: [false],
      infos_obligatoires_mpr: [''],
    });
  }

  createBasicMessage(): void {
    this.message.success('La création a été effectuée avec succès.', {
      nzDuration: 3000
    });
  }

  createReglementaire() {
    if (this.createForm.valid) {
      const payload = {...this.reglementaire};
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });

      if (payload.ends_at) {
        payload.ends_at = new Date(payload.ends_at).toISOString();
      }
      console.log(payload)
      this.reglementaireService
        .createReglementaire(payload)
        .subscribe((updatedReglementaire: any) => {
          this.reglementaire = updatedReglementaire;
          this.createBasicMessage();
          this.createForm.markAsPristine();
          this.router.navigate(['/reglementaire']);
        });
    } else {
      Object.values(this.createForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack() {
    this.router.navigate(['/reglementaire']);
  }
}
