import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ZohoBugsService } from 'src/app/services/zohobugs.service';

@Component({
  selector: 'app-list-zoho-bugs',
  templateUrl: './list-zoho-bugs.component.html',
  styleUrl: './list-zoho-bugs.component.css'
})
export class ListZohoBugsComponent {
  pageIndex = 1;
  pageSize = 20;
  total_zoho_bugs: number = 0;
  isLoadingData = true;
  toogleFilters = false;

  zohoBugs: any[] = [];
  all_checked = false;
  nzShowArrow = true;
  indeterminate = false;
  queryForm: FormGroup;

  constructor(
    private zohoBugsService: ZohoBugsService,
    private fb: FormBuilder,
    private message: NzMessageService,
  ) {
    this.queryForm = this.fb.group({});
  }

  ngOnInit() {
    const stringControlNames = [
      'search_text',
    ];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });

    this.queryZohoBugs();
  }
  
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.zohoBugsService
      .getAllzohoBugsFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.zohoBugs = data.rows;
        this.total_zoho_bugs = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.queryZohoBugs();
  }

  cancel(): void { }
  queryZohoBugs() {
    this.isLoadingData = true;
    this.zohoBugsService
      .getAllzohoBugsFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.zohoBugs = data.rows;
        this.total_zoho_bugs = data.count;
        this.isLoadingData = false;
      });
  }

  resoudre(id: number) {
    this.isLoadingData = true;
    this.zohoBugsService
      .update(id, { fixed: true })
      .subscribe(() => {
        this.queryZohoBugs();
        this.isLoadingData = false;
        this.createBasicMessage();
      })

  }
  createBasicMessage(): void {
    this.message.success('La modification est faite avec succès', {
      nzDuration: 3000
    });
  }
}