<div nz-row>
  <div nz-col nzSpan="24">
    <nz-card class="box">
      <h4 class="info-card-title">
        <ng-template #titre_fiche>
          <a
            class="titre-fiche"
            href="https://neovee.fr/{{ fiche.op_code }}"
            target="_blank"
            >{{ fiche_cee.titre | lowercase }}</a
          >
        </ng-template>
        <span
          nz-icon
          nzType="info-circle"
          nzTheme="outline"
          class="fiche-icon"
          nz-tooltip
          [nzTooltipTitle]="titre_fiche"
        ></span>
        <a
          href="https://crm.zoho.eu/crm/org20063788205/tab/Potentials/{{
            fiche.idFichecrm
          }}"
          target="_blank"
          class="external-link"
        >
          {{ fiche.reference }}</a
        >
        - {{ fiche.op_code }}
        <nz-tag [nzColor]="'green'" [nzBordered]="false" class="fiche-tag">
          <span nz-icon nzType="trophy" nzTheme="outline"></span>
          <span>{{ fiche.montant_energie | number : "1.3-3" : "fr" }} Mwh</span>
        </nz-tag>
        <nz-tag
          [nzColor]="'purple'"
          [nzBordered]="false"
          class="fiche-tag"
          *ngIf="fiche.type_cp === 'Coup de Pouce Pilotage Connecte'"
        >
          <span nz-icon nzType="rocket" nzTheme="outline"></span>
          <span>Coup de pouce Pilotage Connecté</span>
        </nz-tag>
        <nz-tag
          [nzColor]="'geekblue'"
          [nzBordered]="false"
          class="fiche-tag"
          *ngIf="fiche.type_cp === 'Coup de Pouce Reno Globale Collectif'"
        >
          <span nz-icon nzType="rocket" nzTheme="outline"></span>
          <span>Coup de pouce Réno Globale</span>
        </nz-tag>
        <nz-tag
          [nzColor]="'orange'"
          [nzBordered]="false"
          class="fiche-tag"
          *ngIf="
            ['Coup de pouce Standard', 'Coup de pouce +'].includes(
              fiche.type_cp
            )
          "
        >
          <span nz-icon nzType="rocket" nzTheme="outline"></span>
          <span>Coup de pouce Chauffage Indiv</span>
        </nz-tag>
        <nz-tag
          [nzColor]="'lime'"
          [nzBordered]="false"
          class="fiche-tag"
          *ngIf="fiche.type_cp === 'Coup de pouce'"
        >
          <span nz-icon nzType="rocket" nzTheme="outline"></span>
          <span
            >Coup de pouce chauffage tertiaire et résidentiel collectif</span
          >
        </nz-tag>
        <nz-tag
          [nzColor]="'magenta'"
          [nzBordered]="false"
          class="fiche-tag"
          *ngIf="is_zni"
        >
          <span nz-icon nzType="rocket" nzTheme="outline"></span>
          <span>Bonification ZNI</span>
        </nz-tag>
        <nz-tag class="fiche-tag" [nzBordered]="false">
          <span nz-icon nzType="clock-circle"></span>
          <span>
            {{ fiche.ficheDateTime | date : "dd/MM/YYYY HH:mm" }}</span
          ></nz-tag
        >
      </h4>
      <div *ngIf="fiche_labels">
        {{ fiche.titre }}
        <div *ngFor="let item of fiche_object | keyvalue">
          <div nz-row *ngIf="fiche_labels[item.key + '']">
            <div nz-col [nzSpan]="14" class="field-grey-label">
              {{ fiche_labels[item.key + ""] }}
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="9" class="align-text-right">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div *ngFor="let item of fiche_object | keyvalue">
          <div nz-row *ngIf="commun_fiche_labels[item.key + ''] && item.value">
            <div nz-col [nzSpan]="14" class="field-grey-label">
              {{ commun_fiche_labels[item.key + ""] }}
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="9" class="align-text-right">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>
