<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="canDelete('PROJETS') ? 16 : 21"
      >
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher dans les projets ..."
            (keyup.enter)="queryProjects()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryProjects()"></span>
      </ng-template>
      <div class="padding-left-10" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="default"
          class="filters-toogle secondary-button"
          (click)="toogleFilters = !toogleFilters"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
      <div
        class="padding-left-10"
        nz-col
        [nzSpan]="5"
        *ngIf="canDelete('PROJETS')"
      >
        <button
          nz-button
          nzType="default"
          class="filters-toogle secondary-button"
          (click)="openDeleteAllModal()"
        >
          <span nz-icon nzType="delete"></span>
          Suppression avancée
        </button>
      </div>
    </div>
    <nz-modal
      [(nzVisible)]="showDeleteAllModal"
      nzTitle="Suppression avancée"
      (nzOnCancel)="showDeleteAllModal = !showDeleteAllModal"
      (nzOnOk)="deleteRangeProjet()"
      nzOkText="Supprimer"
      [nzOkDisabled]="!supp_all_date"
    >
      <ng-container *nzModalContent>
        <div nz-row nzJustify="center">
          <div nz-col nzSpan="24">
            <label
              >Supprimer tous les projets passés en perdu entre les deux dates
              suivantes :
            </label>
          </div>
          <br /><br />
          <div nz-col nzSpan="16">
            <nz-range-picker
              class="range-date-picker"
              nzFormat="dd-MM-YYYY"
              [(ngModel)]="supp_all_date"
            ></nz-range-picker>
          </div>
        </div>
      </ng-container>
    </nz-modal>
    <br *ngIf="isLoadingData || projets.length === 0" />
    <div *ngIf="toogleFilters" class="filters-panel">
      <form nz-form [formGroup]="queryForm" class="ant-advanced-search-form">
        <div nz-row>
          <div nz-col nzSpan="20">
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="statut-mpr"
                      nzPlaceHolder="Statut MPR"
                      formControlName="statut_mpr"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_statuts_mpr"
                    >
                      <nz-option
                        *ngFor="let statut of statuts_MPR"
                        [nzValue]="statut.code"
                        [nzLabel]="statut.label"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="projet_fiches"
                      nzPlaceHolder="Fiche"
                      formControlName="projet_fiches"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_fiches"
                    >
                      <nz-option
                        *ngFor="let fiche_cee of fiches_cee"
                        [nzValue]="fiche_cee.numero"
                        [nzLabel]="fiche_cee.numero"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="secteur"
                      nzPlaceHolder="Secteur"
                      formControlName="secteur"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_secteurs"
                    >
                      <nz-option
                        nzValue="RESIDENTIEL"
                        nzLabel="Résidentiel"
                      ></nz-option>
                      <nz-option
                        nzValue="TERTIAIRE"
                        nzLabel="Tertiaire"
                      ></nz-option>
                      <nz-option
                        nzValue="INDUSTRIE"
                        nzLabel="Industrie"
                      ></nz-option>
                      <nz-option
                        nzValue="AGRICULTURE"
                        nzLabel="Agriculture"
                      ></nz-option>
                      <nz-option
                        nzValue="TRANSPORT"
                        nzLabel="Transport"
                      ></nz-option>
                      <nz-option nzValue="RESEAU" nzLabel="Réseau"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="type_de_projet"
                      nzPlaceHolder="Type de Projet"
                      formControlName="type_de_projet"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_types_de_projet"
                    >
                      <nz-option
                        nzValue="Coup de Pouce +"
                        nzLabel="CDP Chauffage Résidentiel Individuel +"
                      ></nz-option>
                      <nz-option
                        nzValue="Coup de Pouce Standard"
                        nzLabel="CDP Chauffage Résidentiel Individuel Standard"
                      ></nz-option>
                      <nz-option
                        nzValue="Coup de pouce"
                        nzLabel="CDP Chauffage Tertiaire et Résidentiel Collectif"
                      ></nz-option>
                      <nz-option
                        nzValue="Coup de Pouce Pilotage Connecte"
                        nzLabel="CDP Pilotage Connecté"
                      ></nz-option>
                      <nz-option
                        nzValue="Coup de Pouce Reno Globale Collectif"
                        nzLabel="CDP Rénovation Performante de BRC"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="projet-bloque"
                      nzPlaceHolder="Projet bloqué"
                      formControlName="projet_bloque"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        nzValue="1"
                        nzLabel="Projets bloqués"
                      ></nz-option>
                      <nz-option
                        nzValue="0"
                        nzLabel="Projets débloqués"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4" nzAlign="bottom">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="oblige"
                      nzPlaceHolder="Obligé"
                      formControlName="oblige"
                      [nzShowArrow]="nzShowArrow"
                      nzMode="multiple"
                      nzAllowClear="true"
                      nzSize="small"
                      [nzDropdownMatchSelectWidth]="false"
                      [(ngModel)]="selected_obliges"
                    >
                      <nz-option
                        *ngFor="let oblige of obliges"
                        [nzValue]="oblige.id"
                        [nzLabel]="oblige.nom"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="societe"
                      formControlName="societe"
                      nzPlaceHolder="Société"
                      [nzShowArrow]="nzShowArrow"
                      nzShowSearch
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let societe of societes"
                        [nzValue]="societe.id"
                        [nzLabel]="societe.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="utilisateur"
                      formControlName="utilisateur"
                      nzPlaceHolder="Utilisateur"
                      [nzShowArrow]="nzShowArrow"
                      nzShowSearch
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let user of users"
                        [nzValue]="user.id"
                        [nzLabel]="user.firstname + ' ' + user.lastname"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="commercial"
                      formControlName="commercial"
                      nzPlaceHolder="Chargé d'Affaires"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let commercial of commercials"
                        [nzValue]="commercial.id"
                        [nzLabel]="commercial.last_name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="controleur"
                      formControlName="controleur"
                      nzPlaceHolder="Contrôleur"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let controleur of controleurs"
                        [nzValue]="controleur.id"
                        [nzLabel]="controleur.last_name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4" *ngIf="canAction('FILTER_BY_CLIENT')">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="version"
                      formControlName="version"
                      nzPlaceHolder="Client"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let client of clients"
                        [nzValue]="client.tag"
                        [nzLabel]="client.tag"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="4" nzJustify="center">
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="default"
                nzShape="round"
                nzSize="small"
                (click)="resetForm()"
                class="filter-button"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="primary"
                nzShape="round"
                nzSize="small"
                class="filter-button blue-grey-button-color"
                (click)="queryProjects()"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <nz-table
      #fixedTable
      [nzData]="projets"
      [nzFrontPagination]="false"
      [nzTotal]="total_projects"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_projects_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzScroll]="{ x: '2000px' }"
      [nzLoading]="isLoadingData"
      [nzPageIndex]="1"
      class="projet-list-table"
    >
      <ng-template #total_projects_template>
        <button
          *ngIf="checked_projects.size > 0 && canDelete('PROJETS')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement tous les projets sélectionnés ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <button
          *ngIf="checked_projects.size > 0"
          nz-button
          nzType="default"
          class="secondary-button mass-action-button"
          (click)="exporter()"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button>
        <span>{{ total_projects }}{{ total_projects == 1 ? ' projet' : ' projets' }} </span>
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>

      <thead class="liste-projets-head">
        <tr>
          <th
            nzWidth="30px"
            [(nzChecked)]="all_checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th>Référence</th>
          <th>Projet</th>
          <th nzWidth="130px">Statut CEE</th>
          <th>Volume Mwh</th>
          <th>Société</th>
          <th>Utilisateur</th>
          <th>Obligé</th>
          <th>Bénéficiaire</th>
          <th>Chargé d'Affaires</th>
          <th>Contrôleur</th>
          <th>Client</th>
          <th>Département</th>
          <th>Dernière activité</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td
            [nzChecked]="checked_projects.has(data.id)"
            (nzCheckedChange)="onItemChecked(data.id, $event)"
          ></td>
          <td>
            <a
              href="https://crm.zoho.eu/crm/org20063788205/tab/Potentials/{{
                data.idProjetcrm
              }}"
              target="_blank"
              >{{ data.numeroDossier }}</a
            >
          </td>
          <td>
            <a routerLink="/projets/{{ data.id }}">{{ data.name }}</a>
          </td>
          <td>
            <nz-tag
              [nzColor]="getProjectStageTagColor(data)"
              [nzBordered]="false"
              class="status-tag"
              >{{ data.statut.label }}</nz-tag
            >
          </td>
          <td class="align-right">
            {{ data.resultat | number : "1.3-3" : "fr" }}
          </td>
          <td>
            <a
              *ngIf="data.societe"
              routerLink="/societes/{{ data.societe.id }}"
              >{{ data.societe.name }}</a
            >
          </td>
          <td>
            <a
              *ngIf="data.user"
              routerLink="/utilisateurs/{{ data.user.id }}"
              >{{ data.user.firstname + " " + data.user.lastname }}</a
            >
          </td>
          <td>
            <a routerLink="/obliges/{{ data.oblige?.id }}">{{
              data.oblige?.nom
            }}</a>
          </td>
          <td>
            <a
              *ngIf="data.beneficiaire"
              routerLink="/beneficiaires/{{ data.beneficiaire.id }}"
              >{{ data.beneficiaire.prenom + " " + data.beneficiaire.nom }}</a
            >
          </td>
          <td>
            <a routerLink="/administrateurs/{{ data.admin?.id }}">{{
              data.admin?.last_name
            }}</a>
          </td>
          <td>
            <a routerLink="/administrateurs/{{ data.controleur?.id }}">{{
              data.controleur?.last_name
            }}</a>
          </td>
          <td>{{ data.source }}</td>
          <td>{{ data.departement }}</td>
          <td>
            {{ data.lasModifiedBackProjet | date : "dd/MM/YYYY HH:mm" }}
          </td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/projets/{{ data.id }}">
                  <a
                    ><span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails</a
                  >
                </li>
                <li
                  nz-menu-item
                  (click)="restoreProjet(data)"
                  *ngIf="canAction('RESTAURER_PROJET')"
                >
                  <a
                    ><span nz-icon nzType="reload" nzTheme="outline"></span>
                    Restaurer</a
                  >
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement le projet sélectionné ?"
                  (nzOnConfirm)="deleteProject(data.id)"
                  (nzOnCancel)="cancel()"
                  *ngIf="canDelete('PROJETS')"
                >
                  <a
                    ><span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
