import { Component, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-edit-bandeau-info',
  templateUrl: './edit-bandeau-info.component.html',
  styleUrl: './edit-bandeau-info.component.css',
})
export class EditBandeauInfoComponent implements OnInit {
  editForm: FormGroup;
  bandeau: any;
  bandeau_id: number;
  clients: any[];
  isFormSubmitting: boolean = false;

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private message: NzMessageService,
    private neoscopeService: NeoscopeService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {
    this.editForm = this.fb.group({
      label: ['', Validators.required],
      plateforme: [{ disabled: true, value: '' }, Validators.required],
      is_active: [false, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      content: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.loadingService.upLoading();
    this.bandeau_id = this.route.snapshot.params['id'];
    this.configService
      .getBandeauById(this.bandeau_id)
      .subscribe((bandeau: any) => {
        this.bandeau = bandeau;
        this.editForm.patchValue(bandeau);
        this.loadingService.offLoading();
      });

    this.neoscopeService
      .getAllClientsNeoscope(1, 100, {})
      .subscribe((res: any) => {
        this.clients = res.rows;
      });
  }

  editBandeau() {
    if (this.editForm.valid) {
      this.configService
        .editBandeau(this.bandeau_id, this.editForm.value)
        .subscribe({
          next: (updatedBandeau: any) => {
            this.isFormSubmitting = true;
            this.bandeau = updatedBandeau;
            this.createBasicMessage();
            this.goBack();
          },
          error(error) {
            console.error('Error updating bandeau:', error);
          },
        });
    }
  }

  createBasicMessage(): void {
    this.message.success('la modification est faite avec succès', {
      nzDuration: 3000,
    });
  }
  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  goBack() {
    this.router.navigate(['/bandeau-info']);
  }
}
