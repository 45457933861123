import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { SocietesService } from 'src/app/services/societes.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-corbeille-projets',
  templateUrl: './corbeille-projets.component.html',
  styleUrl: './corbeille-projets.component.css'
})
export class CorbeilleProjetsComponent {
  pageIndex = 1;
  pageSize = 20;
  total_projects: number = 0;
  client = 'Neoscope';
  isLoadingData = true;
  toogleFilters = false;
  supp_all_date: any;
  showDeleteAllModal = false;
  clients: any[] = [];
  projets: any[] = [];
  statuts: any[] = [];
  statuts_MPR: any[] = [];
  fiches_cee: any[] = [];
  obliges: any[] = [];
  commercials: any[] = [];
  controleurs: any[] = [];
  societes: any[] = [];
  users: any[] = [];
  selected_statuts_cee = [];
  selected_statuts_mpr = [];
  selected_fiches = [];
  selected_types_de_projet = [];
  selected_obliges = [];
  selected_secteurs = [];

  all_checked = false;
  nzShowArrow = true;
  indeterminate = false;
  checked_projects = new Set<number>();
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});

  constructor(
    private projetsService: ProjetsService,
    private usersService: UsersService,
    private societesService: SocietesService,
    private fb: NonNullableFormBuilder,
    private datePipe: DatePipe,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private neoscopeService: NeoscopeService
  ) {}

  ngOnInit() {
    const stringControlNames = [
      'search_text',
      'projet_bloque',
      'societe',
      'utilisateur',
      'commercial',
      'controleur',
      'version',
    ];
    const arrayControlNames = [
      'statut_cee',
      'statut_mpr',
      'projet_fiches',
      'secteur',
      'type_de_projet',
      'oblige',
    ];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    arrayControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control([]));
    });

    this.projetsService.getAllActiveStatuts().subscribe((statuts: any) => {
      this.statuts = statuts;
    });

    this.projetsService.getAllStatutsMPR().subscribe((statuts: any) => {
      this.statuts_MPR = statuts;
    });

    this.projetsService.getAllFichesCEE().subscribe((fiches_cee: any) => {
      this.fiches_cee = fiches_cee;
    });

    this.projetsService.getAllObliges().subscribe((obliges: any) => {
      this.obliges = obliges;
    });

    this.usersService.getAllCommercials().subscribe((commercials: any) => {
      this.commercials = commercials;
    });

    this.usersService.getAllControleurs().subscribe((controleurs: any) => {
      this.controleurs = controleurs;
    });

    this.usersService.getAllUsers().subscribe((users: any) => {
      this.users = users;
    });

    this.societesService.getAllSocietes().subscribe((societes: any) => {
      this.societes = societes;
    });
    this.neoscopeService.getClientsNeoscopeByIDs(this.authService.getUserClientsAccess()).subscribe((res: any) => {
      this.clients = res;
    })
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_projects.add(id);
    } else {
      this.checked_projects.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.projets.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.all_checked = this.projets.every((item) =>
      this.checked_projects.has(item.id)
    );
    this.indeterminate =
      this.projets.some((item) => this.checked_projects.has(item.id)) &&
      !this.all_checked;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.projetsService
      .getCorbeilleProjets(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.projets = data.rows;
        this.total_projects = data.count;
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_fiches = [];
    this.selected_obliges = [];
    this.selected_secteurs = [];
    this.selected_statuts_cee = [];
    this.selected_statuts_mpr = [];
    this.selected_types_de_projet = [];
    this.queryProjects();
  }

  cancel(): void {}

  exporter() {
    if (this.all_checked) {
      this.projetsService
        .exportAllProjects(this.client, this.queryForm.value)
        .subscribe((x) => {
          importedSaveAs(x, 'export_projets.xlsx');
        });
    } else {
      this.projetsService
        .exportProjects(Array.from(this.checked_projects))
        .subscribe((x) => {
          importedSaveAs(x, 'export_projets.xlsx');
        });
    }
  }

  async confirmDelete(): Promise<void> {
    for (const project of this.checked_projects) {
      await this.projetsService.deleteProject(project);
    }
    this.checked_projects.clear();
    this.queryProjects();
  }

  async confirmCorbeille(): Promise<void> {
    for (const project of this.checked_projects) {
      await this.projetsService.corbeilleProject(project);
    }
    this.checked_projects.clear();
    this.queryProjects();
  }

  async deleteProject(projet_id: number) {
    this.isLoadingData = true;
    await this.projetsService.deleteProject(projet_id);
    this.projets = this.projets.filter((p) => p.id !== projet_id);
    this.isLoadingData = false;
  }

  queryProjects() {
    this.isLoadingData = true;
    console.log(this.queryForm.value);
    this.projetsService
    
      .getCorbeilleProjets(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.projets = data.rows;
        this.total_projects = data.count;
        this.isLoadingData = false;
      });
  }

  getProjectStageTagColor(projet: any) {
    if(projet.hasReuploadedFiles){
      return 'purple';
    }

    if (projet.is_blocked) {
      return 'red';
    }

    switch (projet.statut.code) {
      case 'OD':
      case 'PAVT2':
      case 'PAVT4':
      case 'PAPT2':
      case 'PAPT5':
      case 'PAPT7':
      case 'CTRL':
      case 'DCEEC':
      case 'OR':
      case 'ECPNCEE':
      case 'VPNCEE':
        return 'orange';
      case 'PAVT3':
      case 'PAPT3':
      case 'PAPT6':
        return 'volcano';
      default:
        return 'geekblue';
    }
  }

  deleteRangeProjet(){
    this.showDeleteAllModal = false;
    this.isLoadingData = true;
    const debut = this.datePipe.transform(this.supp_all_date[0], 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.supp_all_date[1], 'yyyy-MM-dd');
    this.projetsService.deleteManyProjectsByDatePerdu(debut, end).subscribe((result) => {
      if(result === 0){
        this.message.success(`Aucun projet n'a été passé en perdu durant la période choisie.`, {
          nzDuration: 3000
        });
        this.isLoadingData = false;
      }
      else{
        this.message.success(`Vous venez de supprimer ${result} projet(s) définitivement.`, {
          nzDuration: 3000
        });
        this.queryProjects();
      }
    }, (error) => {
      this.message.error(`Une erreur est survenue lors de la suppression des projets. Veuillez essayer plus tard ou contacter le service informatique si l'erreur persisite`, {
        nzDuration: 5000
      });
    });
  }

  openDeleteAllModal(){
    this.checked_projects.clear();
    this.showDeleteAllModal = !this.showDeleteAllModal;
  }

  restoreProjet(projet: any){
    this.projetsService.restoreProject(projet.id).subscribe(res => {
      this.message.success("Le projet " + projet.numeroDossier + " - " + projet.name + " a été restauré avec succès.");
      this.queryProjects();
    }, err => {
      this.message.error("Une erreur est survenue lors de restauration du projet " + projet.numeroDossier + " - " + projet.name);
    })
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string){
    return this.authService.canAction(action_name);
  }

}
