import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { DocumentService } from './document.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isCollapsed = false;
  private avatarSubject = new BehaviorSubject<string | null>(null);
  avatar$ = this.avatarSubject.asObservable();

  constructor(
    private router: Router,
    private authService: AuthService,
    private documentService: DocumentService, 
  ) {}

  toogle() {
    this.isCollapsed = !this.isCollapsed;
    if (this.router.url.includes('categories-prix/')) {
      setTimeout(() => {
        const tranchePrixComponent = document.querySelector('#tranche-prix');
        if (tranchePrixComponent) {
          const width: number = tranchePrixComponent
            ? tranchePrixComponent.getBoundingClientRect().width
            : 0;

          const tranchePrixDescriptions = document.getElementsByClassName(
            'ant-steps-item-description'
          );
          for (
            let i = 0;
            i < Array.from(tranchePrixDescriptions).length - 1;
            i++
          ) {
            const htmlElement = Array.from(tranchePrixDescriptions)[
              i
            ] as HTMLElement;
            htmlElement.style.transition = 'all .2s';
            htmlElement.style.minWidth = width + 'px';
            htmlElement.style.textAlign = 'center';
            
          }
        }
      }, 100);
    }
  }

  loadAvatar(): void {
    this.authService.getCurrentUser().subscribe(
      (user: any) => {
        const avatar = user.avatar;
        if (avatar) {
          this.documentService.previewDocumentByName(avatar, 'avatar').subscribe(
            (res: any) => {
              const blob = new Blob([res], { type: 'image/jpeg' });
              const avatarUrl = URL.createObjectURL(blob);
              this.avatarSubject.next(avatarUrl); 
            },
            (error) => {
              this.avatarSubject.next(null);
            }
          );
        } else {
          this.avatarSubject.next(null);
        }
      },
      (error) => {
        this.avatarSubject.next(null);
      }
    );
  }

  updateAvatar(newAvatarUrl: string) {
    this.avatarSubject.next(newAvatarUrl);
  }

}
