<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>
  <div class="form-container">
    <div class="title">Personnalisation générale</div>
    <form class="form-layout" nz-form [formGroup]="editForm">
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Nom Client</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le nom du client est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.client_name"
                  formControlName="client_name"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8" nzTooltipTitle=" Le changement n'est pas immédiat, il sera soumis au Super Administrateur de Neoscope pour application."
              >URL Neoscope</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'url de Neoscope est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group
                nzPrefix="https://"
                nzSuffix=".neoscope.eu"
                class="edit-field"
              >
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_neoscope"
                  formControlName="url_neoscope"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Couleur principale</nz-form-label
            >
            <div class="format">
              <nz-color-picker
                nzFormat="hex"
                [(ngModel)]="neoscope.primary_color"
                [nzValue]="neoscope.primary_color"
                formControlName="primary_color"
              ></nz-color-picker>
              HEX: {{ neoscope.primary_color }}
            </div>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Couleur secondaire (textes)</nz-form-label
            >
            <div class="format">
              <nz-color-picker
                nzFormat="hex"
                [(ngModel)]="neoscope.secondary_color"
                [nzValue]="neoscope.secondary_color"
                formControlName="secondary_color"
                
              ></nz-color-picker>
              HEX: {{ neoscope.secondary_color }}
            </div>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4" nzTooltipTitle="Taille recommandée : 150 x 30 pixels, soit format 1/5">Logo</nz-form-label>
            <div nz-col [nzSpan]="8" class="center">
              <img nz-image width="200px" [src]="logo" alt="" />
            </div>
            <nz-upload
              nz-col
              [nzSpan]="12"
              [nzShowUploadList]="false"
              [nzAction]="
                environment.apiUrl + '/documents/file/upload/type=logo'
              "
              [nzHeaders]="{ authorization: 'Bearer ' + access_token }"
              (nzChange)="handleChange($event, 'logo')"
              nzAccept=".png"
            >
              <button nz-button>
                <span nz-icon nzType="upload"></span>
                Changer le logo
              </button>
            </nz-upload>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4"
              >Favicon</nz-form-label
            >
            <div nz-col nzSpan="8" class="center">
              <img nz-image width="50px" [nzSrc]="favicon" alt="" />
            </div>
            <nz-upload
              nz-col
              [nzSpan]="12"
              [nzShowUploadList]="false"
              [nzAction]="
                environment.apiUrl + '/documents/file/upload/type=favicon'
              "
              [nzHeaders]="{ authorization: 'Bearer ' + access_token }"
              (nzChange)="handleChange($event, 'favicon')"
            >
              <button nz-button>
                <span nz-icon nzType="upload"></span>
                Changer le favicon
              </button>
            </nz-upload>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4"
              >Icône PWA</nz-form-label
            >
            <div nz-col nzSpan="8" class="center">
              <img nz-image width="50px" [nzSrc]="icon_pwa" alt="" />
            </div>
            <nz-upload
              nz-col
              [nzSpan]="12"
              [nzShowUploadList]="false"
              [nzAction]="
                environment.apiUrl + '/documents/file/upload/type=icon_pwa'
              "
              [nzHeaders]="{ authorization: 'Bearer ' + access_token }"
              (nzChange)="handleChange($event, 'icon_pwa')"
            >
              <button nz-button>
                <span nz-icon nzType="upload"></span>
                Changer l'icône PWA
              </button>
            </nz-upload>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Header</div>
    <form class="form-layout" nz-form [formGroup]="editForm">
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Titre (35 caractères recommandés)</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le titre est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nzmax
                  nz-input
                  [(ngModel)]="neoscope.title"
                  formControlName="title"
                  maxlength="35"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Couleur arrière plan</nz-form-label
            >
            <div class="format">
              <nz-color-picker
                nzFormat="hex"
                [(ngModel)]="neoscope.header_bg_color"
                [nzValue]="neoscope.header_bg_color"
                formControlName="header_bg_color"
              ></nz-color-picker>
              HEX: {{ neoscope.header_bg_color }}
            </div>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>
  <div class="form-container">
    <div class="title">Footer</div>
    <form class="form-layout" nz-form [formGroup]="editForm">
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Site web</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le site web est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.website"
                  formControlName="website"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Numéro de téléphone</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le numéro de téléphone est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.phone"
                  formControlName="phone"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Adresse</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'adresse est obligatoire"
              [nzSpan]="14"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.adresse"
                  formControlName="adresse"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Couleur arrière plan</nz-form-label
            >
            <div class="format">
              <nz-color-picker
                nzFormat="hex"
                [(ngModel)]="neoscope.footer_bg_color"
                [nzValue]="neoscope.footer_bg_color"
                formControlName="footer_bg_color"
              ></nz-color-picker>
              HEX: {{ neoscope.footer_bg_color }}
            </div>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >Lien Réseau Social</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le lien du réseau social est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_social_media"
                  formControlName="url_social_media"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >URL page CGU</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'url de la page CGU est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_cgu"
                  formControlName="url_cgu"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >URL Page CGV</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'url de la page CGV est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_cgv"
                  formControlName="url_cgv"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >URL page contact</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'url de la page contact est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_contact"
                  formControlName="url_contact"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >URL page mentions légales</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="L'url de la page mentions légales est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="neoscope.url_mentions_legales"
                  formControlName="url_mentions_legales"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >Texte footer</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Le texte du footer est obligatoire"
              [nzSpan]="19"
            >
              <textarea
                class="default-border-radius"
                rows="4"
                nz-input
                [(ngModel)]="neoscope.footer_text"
                formControlName="footer_text"
              ></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            nz-button
            nzTooltipTitle="Sauvegarder"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small save-button"
            [disabled]="!checkFormChanges()"
            (click)="editNeoscope()"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            nz-button
            nzTooltipTitle="Annuler"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small cancel-button"
            (click)="goBack()"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
