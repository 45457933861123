<div class="custom-container">
  <nz-card class="custom-card">
    <div class="logo-container">
      <img alt="Logo" class="logo-img" src="assets/icons/neovee-vert.png">
    </div>
    <form [formGroup]="form" class="custom-form">
      <fieldset>
        <div class="custom-form-field">
          <label class="custom-label" for="username">Email</label>
          <input
            [ngClass]="{
            'error-input': form.get('email')?.errors && form.get('email')?.touched
          }"
            class="custom-input"
            formControlName="email"
            id="username"
            name="email"
            nz-input
            placeholder="name@example.com"
            type="text"
          />
        </div>
        <p
          *ngIf="
            form.get('email')?.errors?.['required'] && form.get('email')?.touched
          "
          class="error-message"
        >
          S'il vous plaît, entrez une adresse email.
        </p>
        <p
          *ngIf="
            form.get('email')?.errors?.['email'] && form.get('email')?.touched
          "
          class="error-message"
        >
          S'il vous plaît, entrez un email valide.
        </p>
      </fieldset>
      <div class="flex-container">
        <button
          (click)="send()"
          [disabled]="form.invalid"
          class="custom-button"
          nz-button
          type="submit"
        >
          Récupérer le mot de passe
        </button>
      </div>
    </form>
  </nz-card>
</div>

