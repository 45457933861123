import { Component, HostListener } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { PrixService } from 'src/app/services/prix.service';
import { ProjetsService } from 'src/app/services/projets.service';

@Component({
  selector: 'app-list-prix',
  templateUrl: './list-prix.component.html',
  styleUrl: './list-prix.component.css',
})
export class ListPrixComponent {
  constructor(
    private prixService: PrixService,
    private projetsService: ProjetsService,
    private message: NzMessageService,
    private fb: NonNullableFormBuilder,
    private readonly authService: AuthService
  ) {}
  isLoadingData = true;
  total_categorie_societes: number = 0;
  checked_categorie_societes = new Set<string>();
  all_checked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize = 10;
  client = 'Neoscope';
  nzShowArrow = true;
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  categorie_societes: any[] = [];
  isDesktop: boolean = true;

  ngOnInit(): void {
    const stringControlNames = ['search_text'];

    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewport();
  }

  private checkViewport() {
    this.isDesktop = window.innerWidth > 768;
  }
  exporter() {}

  queryCategorieSociete() {
    this.isLoadingData = true;
    this.prixService
      .getAllCategorieSociete(
        this.pageIndex,
        this.pageSize,
        this.client,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.categorie_societes = data.rows;
        this.total_categorie_societes = data.count;
        this.isLoadingData = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.prixService
      .getAllCategorieSociete(
        this.pageIndex,
        this.pageSize,
        this.client,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.categorie_societes = data.rows;
        this.total_categorie_societes = data.count;
        this.isLoadingData = false;
      });
  }

  async confirmDelete(): Promise<void> {
    for (const categorie of this.checked_categorie_societes) {
      await this.prixService.deleteCategorie(categorie);
    }
    this.checked_categorie_societes.clear();
    this.queryCategorieSociete();
  }

  cancel(): void {}

  async deleteCategorie(categorie_code: string) {
    await this.prixService.deleteCategorie(categorie_code);
    this.queryCategorieSociete();
  }

  updateCheckedSet(code: string, checked: boolean): void {
    if (checked) {
      this.checked_categorie_societes.add(code);
    } else {
      this.checked_categorie_societes.delete(code);
    }
  }

  onItemChecked(code: string, checked: boolean): void {
    this.updateCheckedSet(code, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.categorie_societes.forEach((item) =>
      this.updateCheckedSet(item.code, value)
    );
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.all_checked = this.categorie_societes.every((item) =>
      this.checked_categorie_societes.has(item.code)
    );
    this.indeterminate =
      this.categorie_societes.some((item) =>
        this.checked_categorie_societes.has(item.code)
      ) && !this.all_checked;
  }

  resetForm(): void {
    this.queryForm.reset();
  }

  majPrixCategorie(categorie_code: string) {
    this.isLoadingData = true;
    this.projetsService
      .majPrixCategorie(categorie_code)
      .subscribe((res: any) => {
        this.isLoadingData = false;
        if (res > 0) {
          this.message.success(
            'Les prix de la catégorie ' +
              categorie_code +
              ' sont en cours de mise à jour. ' +
              res +
              ' projets sont concernés. Vous serez informée par e-mail dès que le script sera terminé.'
          );
        } else {
          this.message.success(
            "Aucun projet en cours n'est affecté à la catégorie de prix " +
              categorie_code
          );
        }
      });
  }

  majPrixAllCategorie() {
    this.isLoadingData = true;
    this.projetsService.majPrixAllCategorie().subscribe((res: any) => {
      this.isLoadingData = false;
      if (res > 0) {
        this.message.success(
          "La mise des prix est en cours d'exécution. " +
            res +
            ' projets sont concernés. Vous serez informée par e-mail dès que le script sera terminé.'
        );
      } else {
        this.message.success('Aucun projet en cours trouvé.');
      }
    });
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
