<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form nz-form [formGroup]="queryForm" nz-col [nzSpan]="21">
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher un Manager par nom"
            (keyup.enter)="queryManagers()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search" (click)="queryManagers()"></span>
      </ng-template>
      <div class="padding-left-10" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="primary"
          class="add-button"
          (click)="CreateManagement()"
        >
          <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
          Ajouter
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || managers.length === 0" />

    <nz-table
      #fixedTable
      [nzData]="managers"
      [nzFrontPagination]="false"
      [nzTotal]="totalManagers"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_managers_template"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzLoading]="isLoadingData"
      class="manager-list-table"
    >
      <ng-template #total_managers_template>
        <button
          *ngIf="checkedManagers.size > 0"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement tous les managers sélectionnés ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <span>{{ totalManagers }} {{ totalManagers === 1 ? ' Rôle Manager' : ' Rôle Managers' }}</span>
        <ng-template #popConfirmIcon></ng-template>
      </ng-template>

      <thead class="list-managers-head">
        <tr>
          <th
            nzWidth="30px"
            [(nzChecked)]="allChecked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th>Manager</th>
          <th>Managé</th>
          <th>État</th>
          <th>Date</th>
          <th nzRight nzWidth="90px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of fixedTable.data">
          <td
            [nzChecked]="checkedManagers.has(data.id)"
            (nzCheckedChange)="onItemChecked(data.id, $event)"
          ></td>
          <td>
            <a routerLink="/utilisateurs/{{ data.manager.id }}"
              >{{ data.manager.firstname }} {{ data.manager.lastname }}</a
            >
          </td>
          <td>
            <a routerLink="/utilisateurs/{{ data.managed.id }}"
              >{{ data.managed.firstname }} {{ data.managed.lastname }}</a
            >
          </td>
          <td>
            <nz-tag *ngIf="data.is_active" nzColor="success">
              <span>Actif</span>
            </nz-tag>
            <nz-tag *ngIf="data.is_refused" nzColor="error">
              <span>Refusé</span>
            </nz-tag>
            <nz-tag *ngIf="!data.is_active && !data.is_refused" nzColor="gold">
              <span>En attente</span>
            </nz-tag>
          </td>
          <td>{{ data.created_at | date : "dd/MM/yyyy - HH:mm" }}</td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="manager-actions-button"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item routerLink="/managers/edit/{{ data.id }}">
                  <a>
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer
                  </a>
                </li>
                <li
                  nz-menu-item
                  nz-popconfirm
                  nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement le manager sélectionné ?"
                  (nzOnConfirm)="deleteManager(data.id)"
                  (nzOnCancel)="cancel()"
                >
                  <a>
                    <span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
