<nz-content>
  <div class="inner-content">
    <div nz-row class="search-panel" nzJustify="space-between">
      <form
        nz-form
        [formGroup]="queryForm"
        nz-col
        [nzSpan]="21"
        nzSm="21"
        nzXs="24"
      >
        <nz-input-group
          nz-col
          [nzSpan]="24"
          [nzSuffix]="suffixIconSearch"
          nzSize="large"
          class="main-search"
        >
          <input
            type="text"
            nz-input
            formControlName="search_text"
            class="main-search-input"
            placeholder="Rechercher un bénéficiaire, client ..."
            (keyup.enter)="queryBeneficiaires()"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
      <div class="filter-container" nz-col [nzSpan]="3">
        <button
          nz-button
          nzType="default"
          class="filters-toogle"
          (click)="toggleFilters = !toggleFilters"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || beneficiaires.length === 0" />
    <div *ngIf="toggleFilters" class="filters-panel">
      <form nz-form [formGroup]="queryForm" class="ant-advanced-search-form">
        <div nz-row>
          <div nz-col nzSpan="20">
            <div nz-row class="filter-fields">
              <div nz-col [nzSpan]="6" *ngIf="canAction('FILTER_BY_CLIENT')">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="source"
                      nzPlaceHolder="Client"
                      formControlName="source"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        *ngFor="let client of clients"
                        [nzValue]="client.tag"
                        [nzLabel]="client.tag"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="6">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      class="custom-filter-field"
                      id="type"
                      nzPlaceHolder="Type de bénéficiaire"
                      formControlName="type"
                      nzSize="small"
                      nzAllowClear="true"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                      <nz-option
                        nzValue="Personne physique"
                        nzLabel="Personne physique"
                      ></nz-option>
                      <nz-option
                        nzValue="Personne morale"
                        nzLabel="Personne morale"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="4" nzJustify="center">
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="primary"
                nzShape="round"
                nzSize="small"
                class="filter-button blue-grey-button-color"
                (click)="queryBeneficiaires()"
              >
                Appliquer
              </button>
            </div>
            <div nz-row nzJustify="center">
              <button
                nz-button
                nzBlock
                nzType="default"
                nzShape="round"
                nzSize="small"
                (click)="resetForm()"
                class="filter-button"
              >
                Effacer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <nz-table
      *ngIf="isDesktop"
      #fixedTable
      [nzData]="beneficiaires"
      [nzFrontPagination]="false"
      [nzTotal]="total_beneficiaires"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      nzPaginationPosition="both"
      [nzShowTotal]="total_beneficiaires_template"
      [nzScroll]="{ x: '1300px' }"
      [nzLoading]="isLoadingData"
      class="beneficiaires-list-table"
      (nzQueryParams)="onQueryParamsChange($event)"
    >
      <ng-template #total_beneficiaires_template>
        <button
          *ngIf="checked_beneficiaires.size > 0 && canDelete('BENEFICIAIRES')"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement tous les utilisateurs sélectionnés ?"
          nzType="default"
          class="secondary-button mass-action-button"
          (nzOnConfirm)="confirmDelete()"
          (nzOnCancel)="cancel()"
          [nzIcon]="popConfirmIcon"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <button
          *ngIf="checked_beneficiaires.size > 0"
          nz-button
          nzType="default"
          class="secondary-button mass-action-button"
          (click)="exporter()"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button>
        <span>{{ total_beneficiaires }} {{ total_beneficiaires == 1 ? ' Bénéficiaire' : ' Bénéficiaires' }}</span>
        <ng-template #popConfirmIcon> </ng-template>
      </ng-template>
      <thead>
        <tr>
          <th
            nzWidth="30px"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <th
            *ngFor="let column of listOfColumn"
            class="header-container"
            [nzWidth]="column.width"
          >
            <span class="table-header">{{ column.title }}</span>
          </th>
          <th nzRight nzWidth="100px" nzAlign="center">Actions</th>
        </tr>
      </thead>
      <tbody class="table-container">
        <tr *ngFor="let beneficiaire of fixedTable.data">
          <td
            [nzChecked]="checked_beneficiaires.has(beneficiaire.id)"
            (nzCheckedChange)="onItemChecked(beneficiaire.id, $event)"
          ></td>
          <td>
            <a routerLink="/beneficiaires/{{ beneficiaire.id }}"
              >{{ beneficiaire.prenom }} {{ beneficiaire.nom }}</a
            >
          </td>
          <td>{{ beneficiaire.raison_sociale }}</td>
          <td>{{ beneficiaire.telephone }}</td>
          <td>{{ beneficiaire.email }}</td>
          <td>{{ beneficiaire.adresse }}</td>
          <td>{{ beneficiaire.projet?.source }}</td>
          <td nzRight nzWidth="90px" nzAlign="center">
            <button
              nz-button
              nz-dropdown
              [nzDropdownMenu]="menu"
              class="action-buttons"
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <a routerLink="/beneficiaires/{{ beneficiaire.id }}">
                  <li nz-menu-item>
                    <span nz-icon nzType="eye" nzTheme="outline"></span>
                    Détails
                  </li>
                </a>
                <a routerLink="/beneficiaires/edit/{{ beneficiaire.id }}">
                  <li
                    nz-menu-item
                    *ngIf="canEdit('BENEFICIAIRES')"
                  >
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                    Éditer
                  </li></a
                >
                <li nz-menu-item *ngIf="canDelete('BENEFICIAIRES')">
                  <a
                    ><span nz-icon nzType="delete" nzTheme="outline"></span>
                    Supprimer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="!isDesktop">
      <div *ngFor="let beneficiaire of beneficiaires">
        <nz-card
          class="nz-card"
          routerLink="/beneficiaires/{{ beneficiaire.id }}"
        >
          <div nz-row nzJustify="space-between" nzAlign="middle">
            <div
              nz-col
              [nzSpan]="20"
              class="title"
              *ngIf="beneficiaire.type === 'Personne physique'"
            >
              {{ beneficiaire.prenom }} {{ beneficiaire.nom }}
            </div>
            <div
              nz-col
              [nzSpan]="20"
              class="title"
              *ngIf="beneficiaire.type === 'Personne morale'"
            >
              {{ beneficiaire.raison_sociale }}
            </div>
            <div
              nz-col
              [nzSpan]="4"
              nz-icon
              nzType="setting"
              nzTheme="outline"
            ></div>
          </div>
          <div>
            <nz-tag nzColor="green" [nzBordered]="false" class="status-tag">
              <span nz-icon nzType="bank" nzTheme="outline"></span>
              <span>{{ beneficiaire.projet?.source }} </span>
            </nz-tag>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
</nz-content>
