import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

import {ChatService} from '../../../services/chat.service';
import { MessageListComponent } from './components/message-list/message-list.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(MessageListComponent) messageListComponent: MessageListComponent;
  messages: any[] = [];
  loading: boolean = false;
  chat: any;
  headerData: any;
  roomname: string;
  private id: number;

  constructor(private route: ActivatedRoute, private chatService: ChatService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.chatService.getAllMessages(this.id).subscribe((data: any) => {
      this.chat = data;
      this.messages = data.messages;
      this.headerData = {
        titre: data.titre,
        partenaire: data.partenaire,
      };
    });

    this.roomname = 'room-' + this.id;
    this.chatService.joinRoom(this.roomname);

    
  }

  ngAfterViewInit() {
    const messageSub = this.chatService.onMessage().subscribe((message: any) => {
      console.log(message);
      this.messages.push(message);
    }, err => {
      console.log(err);
    });

  }

  ngOnDestroy() {
    this.chatService.leaveRoom(this.roomname);
  }
}
