import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormRecord, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ManagerService } from 'src/app/services/manager.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-create-management',
  templateUrl: './create-management.component.html',
  styleUrl: './create-management.component.css'
})
export class CreateManagementComponent {
  createForm: FormGroup;
  manager: any;
  isFormModified: boolean;
  users: any[]

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private managerService: ManagerService,
    private message: NzMessageService,
    private router: Router,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      managerId: ['', Validators.required],
      managedId: ['', Validators.required],
      etat_management: ['', Validators.required],
    });
    this.usersService.getAllUsers().subscribe((users: any) => {
      this.users = users;
    });
  }

  createBasicMessage(): void {
    this.message.success('La création est faite avec succès', {
      nzDuration: 3000
    });
  }

  createManager(): void {
    if (this.createForm.valid) {
      const payload = { ...this.manager };
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });
      this.managerService.createManager(payload)
        .subscribe((updatedManager: any) => {
          this.manager = updatedManager;
          this.createBasicMessage();
          this.router.navigate(['/managers']);
        });
    } else {
      Object.values(this.createForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack(): void {
    this.router.navigate(['/managers']);
  }
}
