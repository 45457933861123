<nz-content>
    <br *ngIf="data.length ===0">
    <nz-table #basicTable [nzData]="data" [nzLoading]="isLoadingData" [nzPageSize]="pageSize"
              [nzPaginationPosition]="'top'" class="statut-list-table"
    >
        <thead class="liste-statuts-head">
        <tr>
            <th *ngFor="let column of columns"
                [nzWidth]="column.name === 'Actions' ? '100px' : null"
            >{{ column.name }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let statuts of basicTable.data">
            <td>{{ statuts.id }}</td>
            <td>{{ statuts.code }}</td>
            <td>{{ statuts.label }}</td>
            <td *ngIf="canEdit('STATUTS')" nzAlign="center" nzRight nzWidth="90px">
                <button
                        [nzDropdownMenu]="menu"
                        nz-button
                        nz-dropdown
                        style="border: none"
                >
                    <span nz-icon nzType="setting"></span>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <li (click)="navigateToEdit(statuts.id, statuts.label)" nz-menu-item>
                            <a><span nz-icon nzTheme="outline" nzType="edit"></span> Éditer</a>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </td>
        </tr>
        </tbody>
    </nz-table>

</nz-content>
