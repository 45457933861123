import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { PrixService } from 'src/app/services/prix.service';
import { SocietesService } from 'src/app/services/societes.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-societes',
  templateUrl: './list-societes.component.html',
  styleUrls: ['./list-societes.component.css'],
})
export class ListSocietesComponent implements OnInit {
  societes: any[] = [];
  checked_societes = new Set<number>();
  categories: any[] = [];
  commercials: any[] = [];
  clients: any[] = [];
  checked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize = 20;
  client = 'Neoscope';
  total_societes: number = 0;
  isLoadingData = true;
  nzShowArrow = true;
  selected_gestionnaires = [];
  selected_categories = [];
  selected_secteurs = [];
  toggleFilters = false;
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  isDesktop: boolean = true;

  listOfColumn = [
    { title: 'Raison Sociale', width: '200px' },
    { title: "Chargé d'Affaires", width: '160px' },
    { title: 'Catégorie de prix', width: '200px' },
    { title: 'Client', width: '130px' },
    { title: 'Secteur', width: '190px' },
    { title: "Nombre d'Utilisateurs", width: '170px' },
  ];

  constructor(
    private societesService: SocietesService,
    private adminsService: AdminsService,
    private prixService: PrixService,
    private readonly authService: AuthService,
    private fb: NonNullableFormBuilder,
    private neoscopeService: NeoscopeService
  ) {}

  ngOnInit(): void {
    const stringControlNames = ['source', 'search_text'];
    const arrayControlNames = ['categorie', 'gestionnaire', 'secteur'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    arrayControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control([]));
    });

    this.checkViewport();

    this.adminsService.getAllCommercials().subscribe((commercials: any) => {
      this.commercials = commercials.rows;
    });

    this.prixService
      .getAllCategorieSociete(this.pageIndex, this.pageSize, this.client, {})
      .subscribe((data: any) => {
        data.rows.forEach((categorie: any) => {
          if (!this.categories.includes(categorie.code)) {
            this.categories.push(categorie);
          }
        });
      });
    this.neoscopeService
      .getClientsNeoscopeByIDs(this.authService.getUserClientsAccess())
      .subscribe((res: any) => {
        this.clients = res;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewport();
  }

  private checkViewport() {
    this.isDesktop = window.innerWidth > 768;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_societes.add(id);
    } else {
      this.checked_societes.delete(id);
    }
    console.log(this.checked_societes);
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.societes.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.societes.every((item) =>
      this.checked_societes.has(item.id)
    );
    this.indeterminate =
      this.societes.some((item) => this.checked_societes.has(item.id)) &&
      !this.checked;
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.societesService
      .getFilterSocietes(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.societes = data.rows;
        this.total_societes = data.count;
        console.log(data);
        this.isLoadingData = false;
      });
  }

  querySocietes() {
    this.isLoadingData = true;
    this.societesService
      .getFilterSocietes(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.societes = data.rows;
        this.total_societes = data.count;
        console.log(data);
        this.isLoadingData = false;
      });
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_gestionnaires = [];
    this.selected_categories = [];
    this.querySocietes();
  }
  cancel(): void {}

  exporter() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    if (this.checked) {
      this.societesService
        .exportAllSocietes(this.queryForm.value)
        .subscribe((x) => {
          importedSaveAs(x, `Export sociétés ${day}${month}${year}.xlsx`);
        });
    } else {
      this.societesService
        .exportSocietes(Array.from(this.checked_societes))
        .subscribe((x) => {
          importedSaveAs(x, `Export sociétés ${day}${month}${year}.xlsx`);
        });
    }
  }

  async confirmDelete(): Promise<void> {
    this.isLoadingData = true;
    for (const societe of this.checked_societes) {
      await this.societesService.deleteSociete(societe);
    }
    this.checked_societes.clear();
    this.querySocietes();
  }

  async delete(societe_id: number) {
    this.isLoadingData = true;
    await this.societesService.deleteSociete(societe_id);
    this.querySocietes();
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
