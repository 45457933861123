<nz-content class="content-wrapper">
    <ng-template #suffixIconEdit></ng-template>
    <div class="form-container">
      <div class="title">
        Modifier le bénéficiaire : {{ beneficiaire.nom }} {{ beneficiaire.prenom }}
      </div>
      <form
        class="form-layout"
        nz-form
        [formGroup]="editForm"
      >
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Type Bénéficiaire</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Type Bénéficiaire est obligatoire"
                [nzSpan]="14"
              >
                <nz-select formControlName="type">
                  <nz-option nzValue="Personne physique" nzLabel="Personne physique"></nz-option>
                  <nz-option nzValue="Personne morale" nzLabel="Personne morale"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Civilité</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Civilité est obligatoire"
                [nzSpan]="14"
              >
                <nz-select formControlName="civilite">
                  <nz-option nzValue="Mme" nzLabel="Mme"></nz-option>
                  <nz-option nzValue="M." nzLabel="M."></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Nom</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Nom est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="nom" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Prénom</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Prénom est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="prenom" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">

          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col [nzRequired]="isMorale" [nzSpan]="8">Fonction</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Adresse est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="fonction" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Téléphone</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Veuillez renseigner 10 chiffres pour ce champ !"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="telephone" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          
        </div>



        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzRequired]="isMorale" nzLabelWrap nz-col [nzSpan]="8">Email</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Veuillez renseigner une adresse mail valide !"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="email" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap [nzRequired]="isMorale" nz-col [nzRequired]="isMorale" [nzSpan]="8">Raison sociale</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Raison sociale est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="raison_sociale" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          
        </div>
  

        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Adresse des travaux</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="ce champ est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="adresse_facture" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col [nzRequired]="isMorale" [nzSpan]="8">SIREN </nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="SIREN  est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="siren" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label [nzRequired]="!isMorale" nzLabelWrap nz-col [nzSpan]="8">Adresse de la résidence principale </nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="ce champ est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="adresse" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col [nzRequired]="isMorale" [nzSpan]="8">Adresse du siège social</nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="ce champ est obligatoire"
                [nzSpan]="14"
              >
                <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input nz-input formControlName="adresseSiege" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          
        </div>

        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">
                Remise sur devis/facture CEE
              </nz-form-label>
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="14"
              >
                <nz-switch
                  formControlName="remisSurDevisFacture"
                  [nzCheckedChildren]="'Oui'"
                  [nzUnCheckedChildren]="'Non'"
                ></nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div>
          
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
                >Remise sur devis/facture MPR</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="14"
              >
                <nz-switch
                formControlName="remisSurDevisMPR"
                [nzCheckedChildren]="'Oui'"
                [nzUnCheckedChildren]="'Non'"
                ></nz-switch>
              </nz-form-control>
            </nz-form-item>
          </div>
  
          
        </div>

        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4">Commentaire</nz-form-label>
            <nz-form-control
              nz-col
              nzErrorTip="Adresse est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input formControlName="commentaire" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
  
        <div class="button-wrapper">
          <div class="additional-buttons">
            <button
              nz-button
              nzTooltipTitle="Sauvegarder"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small save-button"
              [disabled]="!checkFormChanges()"
              (click)="editBeneficiaire()"
            >
              <span class="icon">
                <span nz-icon nzType="save"></span>
              </span>
            </button>
            <button
              nz-button
              nzTooltipTitle="Annuler"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small cancel-button"
              (click)="cancelChanges()"
            >
              <span class="icon">
                <span nz-icon nzType="rollback"></span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </nz-content>
  