import { Component } from '@angular/core';
import {
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.css',
})
export class EditProfileComponent {
  editForm: FormGroup;
  passwordForm: FormGroup;
  oblige: any;
  oblige_id: number;
  isFormModified: boolean;
  currentUser: any;
  isFormSubmitting: boolean = false;
  isVisible: boolean = false;
  uploading: boolean = false;
  passwordVisible: boolean = false;
  newpasswordVisible: boolean = false;
  newpasswordconfirmedVisible: boolean = false;
  fileList: File[] = [];
  imageSrc: string = 'assets/icons/neovee-vert.png';
  loadingImage: boolean = true;
  constructor(
    private authService: AuthService,
    private fb: NonNullableFormBuilder,
    private AdminsService: AdminsService,
    private message: NzMessageService,
    private readonly loadingService: LoadingService,
    private readonly DocumentService: DocumentService,
    private SidebarService: SidebarService,
  ) { }

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.authService.getCurrentUser().subscribe({
      next: (user: any) => {
        this.currentUser = user;
        this.loadingService.offLoading();
        if (this.currentUser.avatar) {
          this.DocumentService.previewDocumentByName(user.avatar, 'avatar').subscribe(
            (res: any) => {
              const blob = new Blob([res], { type: 'image/jpeg' });
              this.imageSrc = URL.createObjectURL(blob);
              this.loadingService.offLoading();
              this.loadingImage = false;
            },
            (err: any) => {
              this.loadingImage = false;
              this.imageSrc = "error";
            }
          );
        } else {
          this.loadingImage = false;
          this.imageSrc = 'assets/icons/neovee-vert.png';
        }
        
      },
    });
    this.editForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      username: ['', Validators.required],
      twofactorAuthenticationEnabled: [],
      avatar: [null],
      deleteAvatar: [false],
    });
    this.passwordForm = this.fb.group({
      oldpassword: ['', Validators.required],
      newpassword: [
        '',
        [
          Validators.required,
          this.hasSpecialCharacter,
          Validators.minLength(8),
        ],
      ],
      newpasswordconfirmed: ['', Validators.required],
    });
    this.passwordForm.valueChanges.subscribe(() => {
      this.passwordForm.get('newpassword')?.value ===
        this.passwordForm.get('newpasswordconfirmed')?.value
        ? this.passwordForm.get('newpasswordconfirmed')?.setErrors(null)
        : this.passwordForm
          .get('newpasswordconfirmed')
          ?.setErrors({ notSame: true });
    });
  }

  hasSpecialCharacter(control: any) {
    const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (specialCharacters.test(control.value)) {
      return null;
    }
    return { specialCharacter: true };
  }

  ChangePassword(): void {
    if (this.passwordForm.valid) {
      this.uploading = true;
      this.isVisible = false;
      this.authService
        .resetAdminPassword(
          this.passwordForm.get('oldpassword')?.value,
          this.passwordForm.get('newpassword')?.value
        )
        .subscribe(
          (result: any) => {
            this.createBasicMessage();
            this.passwordForm.reset();
          },
          (err: any) => {
            if (err.status === 401) {
              this.createWrongCredentialsError();
            } else {
              this.createFailureMessage();
              this.passwordForm.reset();
            }
          }
        );
      this.uploading = false;
    } else {
      Object.values(this.passwordForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  onFileSelect(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imageSrc = e.target.result;
      reader.readAsDataURL(file as any);
      this.editForm.patchValue({ avatar: file });
      this.fileList = [file];
    }
  }

  handleUpload(): void {
    if (this.fileList.length === 0) {
      return;
    }
    this.uploading = true;
    const formData = new FormData();
    formData.append('file', this.fileList[0] as any);
    console.log(formData);
  }

  DeleteAvatar(): void {
    this.imageSrc = 'assets/icons/neovee-vert.png';
    this.editForm.patchValue({
      avatar: null,
      deleteAvatar: true,
    });
    this.editForm.get('deleteAvatar')?.markAsDirty();
  }

  editAdmin(): void {
    if (this.editForm.valid) {
      const formData = new FormData();
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          formData.append(key, this.editForm.controls[key].value);
        }
      });

      if (this.fileList && this.fileList.length > 0) {
        formData.append('file', this.fileList[0]);
      }

      this.uploading = true;
      this.AdminsService.editAdmin(this.currentUser.id, formData).subscribe(
        (updatedAdmin: any) => {
          this.isFormSubmitting = true;
          this.currentUser = updatedAdmin;
          this.uploading = false;
          this.SidebarService.loadAvatar();
          this.createBasicMessage();
        },
        (error) => {
          this.uploading = false;
          this.message.error('Une erreur est survenue. Veuillez réessayer plus tard.');
        }
      );
    } else {
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine || this.fileList.length > 0;
    return this.isFormModified;
  }
  goBack(): void { }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  createBasicMessage(): void {
    this.message.success('Vos modifications ont été enregistrées avec succès.', {
      nzDuration: 3000,
    });
  }

  createFailureMessage(): void {
    this.message.error(
      'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique.',
      {
        nzDuration: 3000,
      }
    );
  }

  createWrongCredentialsError(): void {
    this.message.error('mot de passe incorrect', {
      nzDuration: 3000,
    });
  }
}
