<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Créer une nouvelle discussion</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="createForm"
      (ngSubmit)="createDiscussion()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >Titre (40 caractères max)</nz-form-label
            >
            <nz-form-control nz-col [nzErrorTip]="titreerror" [nzSpan]="19">
              <ng-template #titreerror let-control>
                @if (control.errors?.['required']) { Ce champ est obligatoire !
                } @if (control.errors?.['maxlength']) { Veuillez renseigner 40
                chiffres pour ce champ ! }
              </ng-template>
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <textarea
                  formControlName="titre"
                  nz-input
                  rows="1"
                  maxlength="40"
                ></textarea>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Utilisateur
            </nz-form-label>
            <nz-form-control
              nz-col
              nzErrorTip="Titre est obligatoire"
              [nzSpan]="14"
            >
              <nz-select
                class="custom-filter-field"
                formControlName="partenaireId"
                nzSize="small"
                nzShowSearch
              >
                <nz-option
                  *ngFor="let user of users"
                  [nzValue]="user.id"
                  [nzLabel]="user.firstname + ' ' + user.lastname"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Projet</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Projet est obligatoire"
              [nzSpan]="14"
            >
              <nz-select
                [nzDisabled]="projets.length === 0"
                class="custom-filter-field"
                formControlName="projetId"
                nzSize="small"
                nzShowSearch
              >
                <nz-option
                  *ngFor="let projet of projets"
                  [nzValue]="projet.id"
                  [nzLabel]="projet.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            nz-button
            nzTooltipTitle="Sauvegarder"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small save-button"
            [disabled]="!checkFormChanges()"
            type="submit"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            nz-button
            nzTooltipTitle="Annuler"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small cancel-button"
            (click)="goBack()"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
