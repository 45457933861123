<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>
  <div class="form-container">
    <div class="title">Modification de l'obligé {{oblige?.name}}</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
    <div nz-row class="row-content">
      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Nom abrégé</nz-form-label>
          <nz-form-control nz-col nzErrorTip="Ce champ est obligatoire et ne doit pas dépasser 10 caractères" [nzSpan]="14">
            <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
              <input
                nz-input
                [(ngModel)]="oblige.nom"
                formControlName="nom"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8">Nom officiel</nz-form-label>
          <nz-form-control nz-col nzErrorTip="Ce champ est obligatoire et ne doit pas dépasser 30 caractères" [nzSpan]="14">
            <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
              <input
                nz-input
                [(ngModel)]="oblige.nomOfficiel"
                formControlName="nomOfficiel"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row class="row-content">
      <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col [nzSpan]="4">Mention légale</nz-form-label>
          <nz-form-control nz-col [nzSpan]="19">
            <fieldset disabled>
              <textarea class="default-border-radius" formControlName="mentions" nz-input rows="3" placeholder="Mention légale"></textarea>
            </fieldset>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="button-wrapper">
      <div class="additional-buttons">
        <button
          nz-button
          nzTooltipTitle="Sauvegarder"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small save-button"
          [disabled]="!checkFormChanges()"
          type="submit"
          (click)="editOblige()"
        >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
        </button>
        <button
          nz-button
          nzTooltipTitle="Annuler"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small cancel-button"
          (click)="goBack()"
        >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
        </button>
      </div>
    </div>
    </form>
  </div>
</nz-content>
