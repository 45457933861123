<nz-content>
    <div class="inner-content">
      <div nz-row>
        <div nz-col nzSpan="16">
          <div nz-row class="align-items">
            <h2 class="beneficiary-title">
              {{ oblige.nom }}
            </h2>
            <!-- <nz-tag class="timestamp" [nzBordered]="true">
              <span nz-icon nzType="clock-circle"></span>
              <span
                >Créé :
                {{ beneficiaire.createdAt | date : "dd/MM/YYYY HH:mm" }}</span
              >
            </nz-tag> -->
          </div>
        </div>
        <div nz-col nzSpan="8" class="action-buttons">
          <div nz-row nzJustify="end">
            <div nz-col class="margin-right" >
              <button
                nz-button
                nz-popconfirm
                nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?"
                nzType="default"
                class="secondary-button mass-action-button"
                (nzOnConfirm)="confirmDelete()"
                (nzOnCancel)="cancel()"
                *ngIf="canDelete('BENEFICIAIRES')"
              >
                <span nz-icon nzType="delete"></span>
                <span class="action-text">Supprimer</span>
              </button>
              <button
                nz-button
                nzType="default"
                class="secondary-button margin-left-10"
                routerLink="/obliges/edit/{{ oblige.id }}"
                *ngIf="canEdit('BENEFICIAIRES')"
              >
                <span nz-icon nzType="edit"></span>
                Éditer
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <nz-divider></nz-divider>
  
      <div nz-row class="details-container">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="info-circle" nzTheme="outline"></span> Détails d'obligé
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">
                Nom officiel
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">{{ oblige.nomOfficiel }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Mention légale</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ oblige.mentions }}
            </div>
          </div>
        </nz-card>
      </div>
    </div>
    <ng-template #popConfirmIcon> </ng-template>
  </nz-content>
  