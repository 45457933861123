import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from 'src/app/services/loading.service';
import { ZohoBugsService } from 'src/app/services/zohobugs.service';

@Component({
  selector: 'app-details-zoho-bugs',
  templateUrl: './details-zoho-bugs.component.html',
  styleUrl: './details-zoho-bugs.component.css'
})
export class DetailsZohoBugsComponent implements OnInit {
  zohoBug: any;
  id: number;
  isLoadingData: boolean;
  constructor(
    private route: ActivatedRoute,
    private zohobugService: ZohoBugsService,
    private message: NzMessageService,
    private router: Router,
    private readonly loadingService: LoadingService,
  ) { }
  ngOnInit() {
    this.loadingService.upLoading();
    this.id = this.route.snapshot.params['id'];
    this.zohobugService.getZohoBugyid(this.id).subscribe((data: any) => {
      this.zohoBug = data;
      this.loadingService.offLoading();
    })
  }
  async confirmDelete() {
    await this.zohobugService.delete(this.id);
    this.createDeleteMessage();
    this.router.navigate(['/bugs-zoho']);
  }
  cancel() { }
  resoudre(id: number) {
    this.isLoadingData = true;
    this.zohobugService
      .update(id, { fixed: true })
      .subscribe(() => {
        this.isLoadingData = false;
        this.createBasicMessage();
        this.router.navigate(['/bugs-zoho']);
      })

  }
  createBasicMessage(): void {
    this.message.success('La modification est faite avec succès', {
      nzDuration: 3000
    });
  }
  createDeleteMessage(): void {
    this.message.success('La suppression est faite avec succès', {
      nzDuration: 3000
    });
  }
}
