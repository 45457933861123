import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { SocietesService } from 'src/app/services/societes.service';

@Component({
  selector: 'app-details-societe',
  templateUrl: './details-societe.component.html',
  styleUrl: './details-societe.component.css'
})
export class DetailsSocieteComponent {
  societe_id: any;
  societe: any;
  isApporteurAffaire: any;
  entreprises_travaux: any;
  obliges: any;
  constructor(
    private societesService: SocietesService,
    private projetservice: ProjetsService,
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService
  ) { }
  ngOnInit(): void {
    this.loadingService.upLoading();
    this.societe_id = this.route.snapshot.params['id'];
    try {
      this.societesService
        .getSocieteById(this.societe_id)
        .subscribe((societe: any) => {
          this.authService.canAccessClientData(societe.source);
          this.societe = societe;
          this.isApporteurAffaire = societe.isApporteurAffaire;
          this.entreprises_travaux = societe.entreprises_travaux;
          this.loadingService.offLoading();
        });
    } catch (error) {
      console.error('Error fetching societe', error);
    }

    this.projetservice.getAllObliges()
    .subscribe((data: any) =>{
      this.obliges = data;
    })
  }
  getObligeNomById(id: number): string {
    const oblige = this.obliges.find((item: any) => item.id === id);
    return oblige?.nom?  oblige.nom : ''  ;
  }

  async confirmDelete() {
    await this.societesService.delete(this.societe_id);
    this.createDeleteMessage();
    this.router.navigate(['/societes']);
  }
  createDeleteMessage(): void {
    this.message.success('La suppression est faite avec succès', {
      nzDuration: 3000
    });
  }
  cancel() {}

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
