import { Component, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { environment } from 'src/environments/environment';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'app-personnalisation',
  templateUrl: './personnalisation.component.html',
  styleUrl: './personnalisation.component.css',
})
export class PersonnalisationComponent implements OnInit {
  editForm: FormGroup;
  isFormModified: boolean;
  neoscope: any = {};
  client_id: number;
  access_token: any;
  environment: any;
  logo: any;
  favicon: any;
  icon_pwa: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService,
    private neoscopeService: NeoscopeService,
    private documentsService: DocumentService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.editForm = this.fb.group({
      primary_color: ['', Validators.required],
      secondary_color: ['', Validators.required],
      header_bg_color: ['', Validators.required],
      footer_bg_color: ['', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(35)]],
      client_name: ['', Validators.required],
      phone: ['', Validators.required],
      url_neoscope: ['', Validators.required],
      website: ['', Validators.required],
      url_linkedin: ['', Validators.required],
      url_cgu: ['', Validators.required],
      url_cgv: ['', Validators.required],
      url_contact: ['', Validators.required],
      url_mentions_legales: ['', Validators.required],
      footer_text: ['', Validators.required],
      adresse: ['', Validators.required],
      logo: [''],
      favicon: [''],
      icon_pwa: [''],
    });
    this.access_token = localStorage.getItem('token');
    this.environment = environment;

    this.neoscopeService
      .getClientsNeoscopeConfiguration(1)
      .subscribe((config: any) => {
        this.neoscope = config;
        this.editForm.patchValue({
          logo: config.logo,
          favicon: config.favicon,
          icon_pwa: config.icon_pwa,
        });
        this.loadingService.offLoading();
        this.documentsService
          .previewDocumentByName(config.logo, 'logo')
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.logo = reader.result;
            };
            reader.readAsDataURL(res);
          });
        this.documentsService
          .previewDocumentByName(config.favicon, 'favicon')
          .subscribe((res: any) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.favicon = reader.result;
            };
            reader.readAsDataURL(res);
          });
        if (config.icon_pwa) {
          this.documentsService
            .previewDocumentByName(config.icon_pwa, 'icon_pwa')
            .subscribe((res: any) => {
              const reader = new FileReader();
              reader.onload = () => {
                this.icon_pwa = reader.result;
              };
              reader.readAsDataURL(res);
            });
        }
      });
  }

  editNeoscope() {
    this.neoscopeService
      .editNeoscopeConfiguration(this.neoscope.client_id, this.editForm.value)
      .subscribe(
        (new_config) => {
          this.neoscope = new_config;
          this.message.success('La modification est faite avec succès', {
            nzDuration: 3000,
          });
        },
        (error) => {
          this.message.error(
            "Une erreur est survenue lors de l'enregistrement des modifications.",
            {
              nzDuration: 3000,
            }
          );
        }
      );
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.editForm.pristine;
    return (
      this.isFormModified && this.editForm.valid && this.logo && this.favicon
    );
  }

  async handleChange(
    info: NzUploadChangeParam,
    file_type: string
  ): Promise<void> {
    if (info.file.status === 'done') {
      if (file_type === 'logo') {
        this.logo = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          logo: info.file.response.filename,
        });
      } else if (file_type === 'favicon') {
        this.favicon = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          favicon: info.file.response.filename,
        });
      } else if (file_type === 'icon_pwa') {
        this.icon_pwa = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          icon_pwa: info.file.response.filename,
        });
      }
    } else if (info.file.status === 'error') {
      this.message.error(
        `Une erreur est survenue lors de chargement du fichier.`
      );
    }
  }

  goBack() {
    // this.router.navigate(['/categories-prix']);
  }
}
