<nz-content class="content-wrapper">
    <ng-template #suffixIconEdit> </ng-template>
    <div class="form-container">
      <div class="title">
        Identification
      </div>
      <form
        class="form-layout"
        nz-form
        [formGroup]="editForm"
        (ngSubmit)="createCategoriePrix()"
      >
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="6"
                >Code</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Le nom est obligatoire"
                [nzSpan]="16"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="code"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-template #cadenas><span nz-icon nzType="lock"></span></ng-template>
          <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="6"
                >Nom</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Le nom est obligatoire"
                [nzSpan]="16"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="nom"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
  
        <div class="button-wrapper">
          <div class="additional-buttons">
            <button
              nz-button
              nzTooltipTitle="Sauvegarder"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small save-button"
              [disabled]="!checkFormChanges()"
              type="submit"
            >
              <span class="icon">
                <span nz-icon nzType="save"></span>
              </span>
            </button>
            <button
              nz-button
              nzTooltipTitle="Annuler"
              nzTooltipPlacement="leftTop"
              nz-tooltip
              class="circle-button small cancel-button"
              (click)="goBack()"
            >
              <span class="icon">
                <span nz-icon nzType="rollback"></span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="form-container">
      <div class="title">
        Prix Coup de Pouce
      </div>
      <form
        class="form-layout"
        nz-form
        [formGroup]="editForm"
        (ngSubmit)="createCategoriePrix()"
      >
        <div nz-row class="row-content">
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Chauffage résidentiel individuel Classique (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Chauffage résidentiel individuel Précaire (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_precaire"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Chauffage résidentiel individuel Précaire Boosté (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp_chauffage_precaire_boosted"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Chauffage résidentiel individuel Modeste Boosté (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp_chauffage_modeste_boosted"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Chauffage résidentiel individuel Classique Boosté (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp_chauffage_classique_boosted"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Rénovation globale collectif (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp_reno_globale_collectif"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Pilotage connecté (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Ce champ est obligatoire"
                [nzSpan]="3"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    formControlName="prix_cdp_pilotage_connecte"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
    <div class="form-container">
      <div class="title">
        Tranches de Prix
      </div>
        <div
          nz-row
          *ngFor="let t of tranches_prix; index as i"
        >
          <div nz-col [nzSpan]="6" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >De (GWh cc)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Champ obligatoire"
                [nzSpan]="12"
              >
                <nz-input-group class="edit-field" [nzSuffix]="tranches_prix[i].volume_min === 0 ? cadenas : ''">
                  <input
                    nz-input
                    [readOnly]="tranches_prix[i].volume_min === 0"
                    [(ngModel)]="tranches_prix[i].volume_min"
                    (ngModelChange)="editIntervals()"
                    [ngClass]="tranches_prix[i].volume_min === 0 ? 'disabled-input': ''"
                    [ngModelOptions]="{ standalone: true }"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >à (GWh cc)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Champ obligatoire"
                [nzSpan]="12"
              >
                <nz-input-group class="edit-field" [nzSuffix]="tranches_prix[i].volume_max === '+∞' ? cadenas : ''">
                  <input
                    nz-input
                    [(ngModel)]="tranches_prix[i].volume_max"
                    (ngModelChange)="editIntervals()"
                    [readOnly]="tranches_prix[i].volume_max === '+∞'"
                    [ngClass]="tranches_prix[i].volume_max === '+∞' ? 'disabled-input': ''"
                    [ngModelOptions]="{ standalone: true }"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Prix (€)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Champ obligatoire"
                [nzSpan]="12"
              >
                <nz-input-group class="edit-field">
                  <input
                    nz-input
                    [(ngModel)]="tranches_prix[i].prix"
                    (ngModelChange)="editIntervals()"
                    [ngModelOptions]="{ standalone: true }"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" class="align-center">
            <button nz-button nzType="default" nzShape="circle" class="margin-right-10" (click)="removeTranchePrix(i)">
              <span nz-icon nzType="delete"></span>
            </button>
            <button nz-button nzType="default" nzShape="circle" (click)="addTranchePrix(i)">
              <span nz-icon nzType="plus"></span>
            </button>
          </div>
        </div>
    </div>
    <div class="form-container">
      <div class="title">
        Rémunérations partenaires possibles
      </div>
      <div
          nz-row
          *ngFor="let t of rem_partenaire; index as i"
        >
          <div nz-col [nzSpan]="6" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="12"
                >Valeur (en %)</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Champ obligatoire"
                [nzSpan]="12"
              >
                <nz-input-group class="edit-field" [nzSuffix]="rem_partenaire[i].value === 0 ? cadenas : ''">
                  <input
                    nz-input
                    [readOnly]="rem_partenaire[i].value === 0"
                    [(ngModel)]="rem_partenaire[i].value"
                    (ngModelChange)="editIntervals()"
                    [ngClass]="rem_partenaire[i].value === 0 ? 'disabled-input': ''"
                    [ngModelOptions]="{ standalone: true }"
                    required
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="8" nzSm="8" nzXs="24">
            <nz-form-item>
              <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="20"
                >Offre CEE automatique</nz-form-label
              >
              <nz-form-control
                nz-col
                nzErrorTip="Champ obligatoire"
                [nzSpan]="4"
              >
                <nz-input-group class="edit-field">
                  <nz-switch
                  [(ngModel)]="rem_partenaire[i].block_auto_cee"
                  (ngModelChange)="editIntervals()"
                  [nzCheckedChildren]="'Bloquée'"
                  [nzUnCheckedChildren]="'Activée'"
                ></nz-switch>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" class="align-center">
            <button nz-button nzType="default" nzShape="circle" class="margin-right-10" (click)="removeRemPartenaire(i)" [disabled]="rem_partenaire[i].value === 0">
              <span nz-icon nzType="delete"></span>
            </button>
            <button nz-button nzType="default" nzShape="circle" (click)="addRemPartenaire(i)">
              <span nz-icon nzType="plus"></span>
            </button>
          </div>
        </div>
    </div>
  </nz-content>
  