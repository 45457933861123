<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="projet-title">{{ user.firstname }} {{ user.lastname }}</h2>
          <nz-tag
            [nzColor]="getStatus().color"
            [nzBordered]="false"
            class="status-tag"
          >
            <span nz-icon nzType="tag" nzTheme="outline"></span>
            <span>{{ getStatus().text }}</span>
          </nz-tag>
          <nz-tag class="timestamp" [nzBordered]="true">
            <span nz-icon nzType="clock-circle"></span>
            <span
              >Créé :
              {{ user.registrationDateTime | date : "dd/MM/YYYY HH:mm" }}</span
            >
          </nz-tag>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col class="margin-right" *ngIf="canDelete('UTILISATEURS')">
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ?"
              nzType="default"
              class="secondary-button mass-action-button"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              [nzIcon]="popConfirmIcon"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
          </div>
          <div nz-col *ngIf="canEdit('UTILISATEURS')">
            <button
              nz-button
              nzType="default"
              class="secondary-button"
              routerLink="/utilisateurs/edit/{{ user.id }}"
            >
              <span nz-icon nzType="edit"></span>
              <span class="action-text">Éditer</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4 class="subtitle">Détails Utilisateur</h4>
    <div class="details-container">
      <div nz-col nzSpan="8" nzSm="8" nzXs="24">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="user" nzTheme="outline"></span> Identité
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Civilité</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ user.civilite }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Prénom</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ user.firstname }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Nom</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ user.lastname }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">ID CRM</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ user.id ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Société</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              <a routerLink="/societes/{{ user.societe_id }}">
                {{ user.societe?.name ?? "" }}</a
              >
            </div>
          </div>
        </nz-card>
      </div>
      <div nz-col nzSpan="10" nzSm="10" nzXs="24">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="contacts" nzTheme="outline"></span>
            Contact
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Adresse complète</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="15" class="wrap">
              {{ user.adresse ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Code postal</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="15" class="wrap">
              {{ user.codePostal ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Ville</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="15" class="wrap">
              {{ user.ville ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Email</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="15" class="wrap">
              {{ user.email ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Téléphone</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="15" class="wrap">
              {{ user.phone ?? "" }}
            </div>
          </div>
        </nz-card>
      </div>
      <div nz-col nzSpan="6" nzSm="6" nzXs="24">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="bars" nzTheme="outline"></span>
            Préférences
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="12" class="field-grey-label">Client</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="11" class="wrap">
              {{ user.source ?? "" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="12" class="field-grey-label">
              Choix Newsletter
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <nz-switch
              nzSize="small"
              [nzDisabled]="true"
              [ngModel]="user.newsletter ?? 0"
              nzCheckedChildren="Refusé"
              nzUnCheckedChildren="Accepté"
            ></nz-switch>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="12" class="field-grey-label">
              Rôle manager
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <nz-switch
              nzSize="small"
              [nzDisabled]="true"
              [ngModel]="user.isManager ?? 0"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
            ></nz-switch>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
  <ng-template #checkedTemplate
    ><span nz-icon nzType="check"></span
  ></ng-template>
  <ng-template #unCheckedTemplate
    ><span nz-icon nzType="close"></span
  ></ng-template>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
