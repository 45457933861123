<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="24" nz-col nz-form>
        <nz-input-group [nzSuffix]="suffixIconSearch" class="main-search" nzSize="large">
          <input
            (keyup.enter)="queryStatus()"
            [nzAutocomplete]="auto"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher un Bandeau..."
            type="text"
          />
          <nz-autocomplete #auto>
            <nz-auto-option *ngFor="let bandeau of filteredData" [nzValue]="bandeau.label">
              {{ bandeau.label }}
            </nz-auto-option>
          </nz-autocomplete>
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="queryStatus()" nz-icon nzType="search"></span>
      </ng-template>
    </div>

    <br *ngIf="isLoadingData || filteredData.length === 0"/>

    <nz-table
      #fixedTable
      [nzData]="filteredData"
      [nzLoading]="isLoadingData"
      [nzTotal]="totalBandeaus"
      class="bandeau-list-table"
      nzPaginationPosition="top"
      [nzShowTotal]="totalBandeaustemplate"
    >
    <ng-template #totalBandeaustemplate>
      <span>{{ totalBandeaus }} {{ totalBandeaus == 1 ? ' Bandeau' : ' Bandeaux' }}</span>
      <ng-template #popConfirmIcon> </ng-template>
    </ng-template>
      <thead class="liste-projets-head">
      <tr>
        <th *ngFor="let column of listOfColumns" [nzSortDirections]="column.sortDirections" [nzSortFn]="column.sortFn"
            [nzSortOrder]="column.sortOrder" [nzWidth]="column.name === 'Actions' ? '100px' : null"
        >
          {{ column.name }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let bandeau of filteredData">
        <td>{{ bandeau.label }}</td>
        <td>{{ bandeau.plateforme }}</td>
        <td>
          <nz-tag *ngIf="bandeau.is_active" nzColor="success">
            <span>Oui</span>
          </nz-tag>
          <nz-tag *ngIf="!bandeau.is_active" nzColor="error">
            <span>Non</span>
          </nz-tag>
        </td>
        <td>{{ bandeau.start_date | date:'dd/MM/yyyy - HH:mm' }}</td>
        <td>{{ bandeau.end_date | date:'dd/MM/yyyy - HH:mm' }}</td>
        <td *ngIf="canEdit('BANDEAU-INFO')">
          <button [nzDropdownMenu]="menu" nz-button nz-dropdown style="border: none">
            <span nz-icon nzType="setting"></span>
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li (click)="editBandeau(bandeau.id, bandeau.label)" nz-menu-item>
                <a><span nz-icon nzTheme="outline" nzType="edit"></span> Éditer</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
