import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjetsService {
  constructor(private http: HttpClient) {}

  getAllProjets(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/admin/page=${page}&limit=${limit}`,
      query
    );
  }

  getAllProjetsByRole(
    page: number,
    limit: number,
    query: any,
    role: any,
    adminId: any
  ) {
    return this.http.post(
      `${environment.apiUrl}/projets/admin/role/page=${page}&limit=${limit}`,
      query
    );
  }

  getCorbeilleProjets(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/admin/corbeille/page=${page}&limit=${limit}`,
      query
    );
  }

  getProjetById(project_id: number) {
    return this.http.get(`${environment.apiUrl}/projets/${project_id}`);
  }

  getAllActiveStatuts() {
    return this.http.get(`${environment.apiUrl}/projets/statuts/all-active`);
  }

  getAllStatutsMPR() {
    return this.http.get<any[]>(`${environment.apiUrl}/projets/statuts-mpr`);
  }

  getAllStatutsForBo() {
    return this.http.get<any[]>(`${environment.apiUrl}/projets/statuts-bo`);
  }

  editStatutMPR(statut_id: number, payload: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/statuts-mpr/edit/${statut_id}`,
      { label: payload }
    );
  }

  editStatut(statut_id: number, payload: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/statuts/edit/${statut_id}`,
      { label: payload }
    );
  }

  editProject(project_id: number, payload: any) {
    return this.http.put(
      `${environment.apiUrl}/projets/edit/${project_id}`,
      payload
    );
  }

  deleteProject(project_id: number) {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/projets/${project_id}`)
    );
  }

  restoreProject(project_id: number) {
    return this.http.put(
      `${environment.apiUrl}/projets/restore/${project_id}`,
      {}
    );
  }

  deleteManyProjectsByDatePerdu(date_debut: any, date_fin: any) {
    return this.http.post(`${environment.apiUrl}/projets/multi-delete-perdu`, {
      debut: date_debut,
      end: date_fin,
    });
  }

  corbeilleProject(project_id: number) {
    return firstValueFrom(
      this.http.put(`${environment.apiUrl}/projets/corbeille/${project_id}`, {})
    );
  }

  exportProjects(project_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/projets/export`,
      { ids: project_ids },
      { responseType: 'blob' }
    );
  }

  exportAllProjects(query: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/export-all`,
      query,
      { responseType: 'blob' }
    );
  }

  getAllFichesCEE() {
    return this.http.get(`${environment.apiUrl}/reglementaires/fiches/all`);
  }

  getProjectLogs(project_id: number) {
    return this.http.get(
      `${environment.apiUrl}/logging/project/id=` + project_id
    );
  }

  bloquerProjet(projet_id: number) {
    return this.http.put(
      `${environment.apiUrl}/projets/bloquer/${projet_id}`,
      {}
    );
  }

  debloquerProjet(projet_id: any) {
    return this.http.put(
      `${environment.apiUrl}/projets/debloquer/${projet_id}`,
      {}
    );
  }

  generateNoticeImportante(projet_id: any) {
    return this.http.post(
      `${environment.apiUrl}/projets/generate-notice-importante/${projet_id}`,
      {}
    );
  }

  majPrixCategorie(categorie_code: string) {
    return this.http.post(
      `${environment.apiUrl}/projets/maj_prix/${categorie_code}`,
      {}
    );
  }

  majPrixAllCategorie() {
    return this.http.post(`${environment.apiUrl}/projets/maj_prix_all`, {});
  }

  majProjetsReglementaire(numero_fiche: string) {
    return this.http.post(
      `${environment.apiUrl}/projets/maj_reglementaire/${numero_fiche}`,
      {}
    );
  }

  hasProjet(user_id: number) {
    return this.http.get(
      `${environment.apiUrl}/projets/has_projets/${user_id}`
    );
  }

  GetUserProjects(user_id:number) {
    return this.http.get(
      `${environment.apiUrl}/projets/all-user-projets/${user_id}`
    );
  }
}
