import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BeneficiairesService } from 'src/app/services/beneficiaires.service';

@Component({
  selector: 'app-edit-beneficiaire',
  templateUrl: './edit-beneficiaire.component.html',
  styleUrl: './edit-beneficiaire.component.css'
})
export class EditBeneficiaireComponent {
  editForm: FormGroup;
  beneficiaire: any;
  beneficiaireId: any;
  initialBeneficiaireState: any;
  isFormModified: boolean = false;
  isMorale: boolean;
  remisSurDevisFacture: boolean;
  isFormSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private beneficiairesService: BeneficiairesService,
    private message: NzMessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.remisSurDevisFacture = true;
    this.beneficiaireId = this.route.snapshot.params['id'];
    this.loadBeneficiaire();
    this.createForm();
    this.editForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
      const remisSurDevisFactureValue = this.editForm.get('remisSurDevisFacture')?.value;
      if (remisSurDevisFactureValue === 'OUI') {
        this.editForm.get('remisSurDevisFacture')?.setValue(true);
      }
    });
  }

  loadBeneficiaire(): void {
    this.beneficiairesService.getBeneficiaireById(this.beneficiaireId).subscribe((beneficiaire: any) => {
      this.beneficiaire = beneficiaire;
      const patchedData = {
        ...beneficiaire,
        remisSurDevisFacture: beneficiaire.remisSurDevisFacture == "OUI",
        remisSurDevisMPR: beneficiaire.remisSurDevisMPR == 'OUI',
      };
      this.editForm.patchValue(patchedData);
      this.initialBeneficiaireState = { ...beneficiaire };
    });
  }

  createForm(): void {
    this.editForm = this.fb.group({
      type: ['', Validators.required],
      civilite: ['', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      fonction: [''],
      telephone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: [''],
      raison_sociale: [''],
      adresse: ['', Validators.required],
      siren: [''],
      adresseSiege: [''],
      adresse_facture: [''],
      commentaire: [''],
      certificationEligiblite: [],
      alertSignataire: [],
      remisSurDevisFacture: [true,Validators.required],
      remisSurDevisMPR: [Validators.required]
    });

    this.editForm.get('type')?.valueChanges.subscribe((value) => {
      this.toggleMoraleFields(value === 'Personne morale');
    });
  }

  toggleMoraleFields(isMorale: boolean): void {
    this.isMorale = isMorale;
    if (isMorale) {
      this.editForm.get('fonction')?.setValidators(Validators.required);
      this.editForm.get('raison_sociale')?.setValidators(Validators.required);
      this.editForm.get('adresseSiege')?.setValidators(Validators.required);
      this.editForm.get('email')?.setValidators([Validators.email, Validators.required]);
      this.editForm.get('adresse_facture')?.clearValidators();
    } else {
      this.editForm.get('fonction')?.clearValidators();
      this.editForm.get('raison_sociale')?.clearValidators();
      this.editForm.get('adresseSiege')?.clearValidators();
      this.editForm.get('email')?.clearValidators();
      this.editForm.get('adresse_facture')?.setValidators(Validators.required);
    }
    this.editForm.get('fonction')?.updateValueAndValidity();
    this.editForm.get('raison_sociale')?.updateValueAndValidity();
    this.editForm.get('adresseSiege')?.updateValueAndValidity();
    this.editForm.get('email')?.updateValueAndValidity();
    this.editForm.get('adresse_facture')?.updateValueAndValidity();
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  editBeneficiaire(): void {
    if (this.editForm.valid) {
      let payload = this.editForm.getRawValue();
      payload = {
        ...payload,
        remisSurDevisFacture: payload.remisSurDevisFacture ? 'OUI' : 'NON',
        remisSurDevisMPR: payload.remisSurDevisMPR ? 'OUI' : 'NON',
      };
      this.beneficiairesService.editBeneficiaire(this.beneficiaireId, payload).subscribe({
        next: (updatedBeneficiaire: any) => {
          this.isFormSubmitting = true;
          this.message.create('success', 'Modifications enregistrées');
          this.router.navigate(['/beneficiaires']);
        },
        error: (error: any) => {
          console.error('Erreur lors de la mise à jour:', error);
        }
      });
    } else {
      console.log('Formulaire invalide');
      Object.values(this.editForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      });
    }
  }

  cancelChanges(): void {
    this.router.navigate(['/beneficiaires']);
  }
}
