import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { BeneficiairesService } from 'src/app/services/beneficiaires.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-details-beneficiaire',
  templateUrl: './details-beneficiaire.component.html',
  styleUrl: './details-beneficiaire.component.css',
})
export class DetailsBeneficiaireComponent {
  beneficiaire_id: any;
  beneficiaire: any;
  constructor(
    private beneficiaireservice: BeneficiairesService,
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService
  ) {}
  ngOnInit(): void {
    this.loadingService.upLoading();
    this.beneficiaire_id = this.route.snapshot.params['id'];
    this.beneficiaireservice
      .GetBeneficiairebyId(this.beneficiaire_id)
      .subscribe((data: any) => {
        this.authService.canAccessClientData(data.projet.source);
        this.beneficiaire = data;
        this.loadingService.offLoading();
      });
  }
  async confirmDelete() {
    await this.beneficiaireservice.delete(this.beneficiaire_id);
    this.createDeleteMessage();
    this.router.navigate(['/societes']);
  }
  createDeleteMessage(): void {
    this.message.success('La suppression est faite avec succès', {
      nzDuration: 3000,
    });
  }
  cancel() {}

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }
}
