import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { environment } from 'src/environments/environment';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrl: './edit-client.component.css',
})
export class EditClientComponent {
  editForm: FormGroup;
  client_neoscope: any = {};
  neoscope: any = {};
  client_id: number;
  isFormModified: boolean;
  access_token: any;
  environment: any;
  logo: any;
  favicon: any;
  icon_pwa: any;
  isFormSubmitting: boolean = false;

  controlArray: Array<{ index: number; show: boolean }> = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private neoscopeService: NeoscopeService,
    private message: NzMessageService,
    private documentsService: DocumentService,
    private router: Router,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.editForm = this.fb.group({
      name: ['', Validators.required],
      tag: ['', Validators.required],
      crm: ['', Validators.required],
      id_crm: ['', Validators.required],
      is_active: ['', Validators.required],
      primary_color: ['', Validators.required],
      secondary_color: ['', Validators.required],
      header_bg_color: ['', Validators.required],
      footer_bg_color: ['', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(35)]],
      client_name: ['', Validators.required],
      phone: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.required,
        ],
      ],
      url_neoscope: ['', Validators.required],
      website: ['', Validators.required],
      url_social_media: ['', Validators.required],
      url_cgu: ['', Validators.required],
      url_cgv: ['', Validators.required],
      url_contact: ['', Validators.required],
      url_mentions_legales: ['', Validators.required],
      footer_text: ['', Validators.required],
      adresse: ['', Validators.required],
      logo: [''],
      favicon: [''],
      icon_pwa: [''],
    });
    this.client_id = this.route.snapshot.params['id'];
    this.neoscopeService
      .getClientNeoscope(this.client_id)
      .subscribe((client: any) => {
        this.client_neoscope = client;
      });

    this.neoscopeService
      .getClientsNeoscopeConfiguration(this.client_id)
      .subscribe((config: any) => {
        config.url_neoscope = config.url_neoscope.replace('https://', '');
        this.neoscope = config;
        this.editForm.patchValue({
          logo: config.logo,
          favicon: config.favicon,
          icon_pwa: config.icon_pwa,
        });

        this.documentsService
          .previewDocumentByName(config.logo, 'logo')
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.logo = reader.result;
            };
            reader.readAsDataURL(res);
            this.editForm.markAsPristine();
          });
        this.documentsService
          .previewDocumentByName(config.favicon, 'favicon')
          .subscribe((res: any) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.favicon = reader.result;
            };
            reader.readAsDataURL(res);
            this.editForm.markAsPristine();
          });
        this.documentsService
          .previewDocumentByName(config.icon_pwa, 'icon_pwa')
          .subscribe((res: any) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.icon_pwa = reader.result;
            };
            reader.readAsDataURL(res);
            this.loadingService.offLoading();
            this.editForm.markAsPristine();
          });
      });
    this.access_token = localStorage.getItem('token');
    this.environment = environment;
  }

  editClientNeoscope() {
    if (this.editForm.valid && this.logo && this.favicon) {
      
      console.log(this.editForm.value);
      const payload = { ...this.editForm.value };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      payload.url_neoscope = 'https://' + payload.url_neoscope;
      this.neoscopeService
        .editNeoscopeClient(this.client_id, payload)
        .subscribe(
          (updatedClient: any) => {
            this.isFormSubmitting = true;
            this.client_neoscope = updatedClient;
            this.message.success('Vos modifications ont été enregistrées avec succès.', {
              nzDuration: 3000,
            });
            this.goBack();
          },
          (error) => {
            this.message.error(
              "Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique.",
              {
                nzDuration: 3000,
              }
            );
          }
        );
    } else {
      console.log('Form is invalid');
    }
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine;
    return (
      this.isFormModified && this.editForm.valid && this.logo && this.favicon
    );
  }

  goBack() {
    this.router.navigate(['/clients-neoscope']);
  }

  async handleChange(
    info: NzUploadChangeParam,
    file_type: string
  ): Promise<void> {
    console.log('change');
    if (info.file.status === 'done') {
      if (file_type === 'logo') {
        this.logo = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          logo: info.file.response.filename,
        });
      } else if (file_type === 'favicon') {
        this.favicon = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          favicon: info.file.response.filename,
        });
      } else if (file_type === 'icon_pwa') {
        this.icon_pwa = await getBase64(info.file.originFileObj!);
        this.editForm.patchValue({
          icon_pwa: info.file.response.filename,
        });
      }
    } else if (info.file.status === 'error') {
      this.message.error(
        `Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique.`
      );
    }
  }
}
