<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Nouveau Manager</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="createForm"
      (ngSubmit)="createManager()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Manager</nz-form-label
            >
            <nz-form-control nzErrorTip="Le champ Manager est obligatoire">
              <nz-select
                class="custom-filter-field"
                id="utilisateur"
                formControlName="managerId"
                nzPlaceHolder="Utilisateur"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let user of users"
                  [nzValue]="user.id"
                  [nzLabel]="user.firstname + ' ' + user.lastname"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="8"
              >Managé</nz-form-label
            >
            <nz-form-control nzErrorTip="Le champ Managé est obligatoire">
              <nz-select
                class="custom-filter-field"
                id="utilisateur"
                formControlName="managedId"
                nzPlaceHolder="Utilisateur"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let user of users"
                  [nzValue]="user.id"
                  [nzLabel]="user.firstname + ' ' + user.lastname"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >État</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="8">
              <nz-select
                class="custom-filter-field"
                formControlName="etat_management"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option nzValue="1" nzLabel="Actif"></nz-option>
                <nz-option nzValue="0" nzLabel="En attente"></nz-option>
                <nz-option nzValue="-1" nzLabel="Refusé"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            nz-button
            nzTooltipTitle="Sauvegarder"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small save-button"
            [disabled]="!checkFormChanges()"
            type="submit"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            nz-button
            nzTooltipTitle="Annuler"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small cancel-button"
            (click)="goBack()"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
