<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>
  <div class="form-container">
    <div class="title">
      Paramétrer la fiche : {{ reglementaire.numero }}
    </div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="14" nzSm="14" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="7"
              >Titre</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Titre est obligatoire"
              [nzSpan]="16"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="reglementaire.titre"
                  formControlName="titre"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="10" nzSm="10" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="10"
              >Version FOST</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Veuillez renseigner une version Fost Valide"
              [nzSpan]="10"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="reglementaire.version_simulateur"
                  formControlName="version_simulateur"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap>Date de la version</nz-form-label>
            <nz-form-control [nzSpan]="14" nz-col nzErrorTip="Date de la version est obligatoire">
              <nz-date-picker
                nzFormat="dd/MM/yyyy"
                formControlName="createdAt"
                [ngModel]="reglementaire.createdAt"
                class="full-width default-border-radius"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="13"
              >Contrôle sur site</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Contrôle sur site est obligatoire"
              [nzSpan]="14"
            >
              <nz-switch
                [(ngModel)]="reglementaire.IsControlled"
                formControlName="IsControlled"
                [nzCheckedChildren]="'Oui'"
                [nzUnCheckedChildren]="'Non'"
              ></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4"
              >Infobulle à afficher (150 caractères max)</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Infobulle est obligatoire"
              [nzSpan]="19"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <textarea
                    [(ngModel)]="reglementaire.infobulle"
                    formControlName="infobulle"
                    nz-input
                    rows="2"
                    maxlength="150"
                  ></textarea>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            nz-button
            nzTooltipTitle="Sauvegarder"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small save-button"
            [disabled]="!checkFormChanges()"
            (click)="editReglementaire()"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            nz-button
            nzTooltipTitle="Annuler"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small cancel-button"
            (click)="goBack()"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
