import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { switchMap } from 'rxjs';
import { AdminsService } from 'src/app/services/admins.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-details-admin',
  templateUrl: './details-admin.component.html',
  styleUrl: './details-admin.component.css'
})
export class DetailsAdminComponent {
  admin: any;
  admin_id: number;
  isFormModified: boolean;
  clients: any[];
  clients_access: any[];
  client: any;
  accessibleClients: any;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminsService,
    private message: NzMessageService,
    private router: Router,
    private readonly authService: AuthService,
    private neoscopeService: NeoscopeService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.admin_id = this.route.snapshot.params['id'];
    this.adminService.getAdminById(this.admin_id).pipe(
      switchMap((admin: any) => {
        this.admin = admin;
        this.clients_access = JSON.parse(admin.clients_access);
    
        return this.neoscopeService.getAllClientsNeoscope(1, 100, {});
      })
    ).subscribe((res: any) => {
      this.clients = res.rows;
      this.client = this.clients.find(c => c.id === this.admin.client_id);
      this.accessibleClients = this.clients.filter(client => this.clients_access.includes(client.id));
      this.loadingService.offLoading();
    });
  }

  getStatus() {
    const status = this.admin.is_active ?
      { color: 'green', text: 'Actif' } :
      { color: 'red', text: 'Inactif' };
    return status;
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  async confirmDelete(): Promise<void> {
    if (this.admin) {
      await this.adminService.deleteAdmin(this.admin_id);
    }
    this.router.navigate(['/administrateurs']);
  }

  cancel(): void { }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  goBack(): void {
    this.router.navigate(['/administrateurs']);
  }
}
