import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {

  transform(value: Date | string, formatToday: string = 'HH:mm', formatOther: string = 'dd/MM HH:mm'): string {
    if (!value) return '';

    const date = new Date(value);
    const today = new Date();

    const isToday = date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();

    const options: Intl.DateTimeFormatOptions = isToday
      ? { hour: '2-digit', minute: '2-digit' }
      : { year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' }; 

    return date.toLocaleString('en-GB', options);
  }

}
