import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

import { ChatService } from '../../../services/chat.service';
import { MessageListComponent } from './components/message-list/message-list.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(MessageListComponent) messageListComponent: MessageListComponent;
  messages: any[] = [];
  loading: boolean = false;
  chat: any;
  headerData: any;
  roomname: string;
  private id: number;

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.upLoading();
    this.id = this.route.snapshot.params['id'];
    this.chatService.getAllMessages(this.id).subscribe((data: any) => {
      this.chat = data;
      this.messages = data.messages;
      this.headerData = {
        titre: data.titre,
        user: data.user,
      };
      this.loadingService.offLoading();
    });

    this.roomname = 'room-' + this.id;
    this.chatService.joinRoom(this.roomname);
  }

  ngAfterViewInit() {
    const messageSub = this.chatService.onMessage().subscribe(
      (message: any) => {
        this.messages.push(message);
        this.HandleNewMessage(message);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  HandleNewMessage(newMessage:any) {
    if (this.messageListComponent) {
      this.messageListComponent.addNewMessage(newMessage)
    }
  }

  ngOnDestroy() {
    this.chatService.leaveRoom(this.roomname);
  }
}
