<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div class="align-items" nz-row>
          <h2 class="beneficiary-title">
            {{ reglementaire.numero }} - {{ reglementaire.titre }}
          </h2>
          <nz-tag [nzBordered]="true" class="timestamp">
            <span nz-icon nzType="clock-circle"></span>
            <span
            >Créé :
              {{ reglementaire.createdAt | date : "dd/MM/YYYY HH:mm" }}</span
            >
          </nz-tag>
        </div>
      </div>
      <div class="action-buttons" nz-col nzSpan="8">
        <div nz-row nzJustify="end">
          <div class="margin-right" nz-col>
            <button
              (nzOnCancel)="cancel()"
              (nzOnConfirm)="confirmDelete()"
              *ngIf="canDelete('REGLEMENTAIRE')"
              class="secondary-button mass-action-button"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?"
              nzType="default"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              *ngIf="canEdit('REGLEMENTAIRE')"
              class="secondary-button margin-left-10"
              nz-button
              nzType="default"
              routerLink="/reglementaire/edit/{{ reglementaire.numero }}"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <div class="details-container" nz-row>
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzTheme="outline" nzType="info-circle"></span> Détails
          du Reglementaire
        </h4>

        <div nz-row>
          <div [nzSpan]="8" class="field-grey-label" nz-col>
            Version FOST
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>{{ reglementaire.version_simulateur }}</div>
        </div>
        <div nz-row>
          <div [nzSpan]="8" class="field-grey-label" nz-col>Contrôle sur site</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>
            <nz-tag *ngIf="reglementaire.IsControlled" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!reglementaire.IsControlled" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div [nzSpan]="8" class="field-grey-label" nz-col>Infobulle à afficher</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="14" class="wrap" nz-col>{{ reglementaire.infobulle }}</div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon></ng-template>
</nz-content>
