<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>

  <div class="form-container">
    <div class="title">IDENTIFICATION</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Raison sociale</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="societe?.name"
                  formControlName="name"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Secteur CEE principal
            </nz-form-label>
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-select
                formControlName="secteur_principal"
                [ngModel]="societe?.secteur_principal"
                class="edit-field"
              >
                <nz-option
                  nzValue="RESIDENTIEL INDIVIDUEL"
                  nzLabel="Résidentiel Individuel"
                ></nz-option>
                <nz-option
                  nzValue="RESIDENTIEL COLLECTIF"
                  nzLabel="Résidentiel Collectif"
                ></nz-option>
                <nz-option nzValue="TERTIAIRE" nzLabel="Tertiaire"></nz-option>
                <nz-option nzValue="INDUSTRIE" nzLabel="Industrie"></nz-option>
                <nz-option nzValue="TRANSPORT" nzLabel="Transport"></nz-option>
                <nz-option
                  nzValue="AGRICULTURE"
                  nzLabel="Agriculture"
                ></nz-option>
                <nz-option nzValue="RESEAU" nzLabel="Réseaux"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Chargé d'Affaires</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="14"
          >
            <nz-select
              [ngModel]="societe?.gestionnaire?.id"
              class="edit-field"
              formControlName="gestionnaire_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let admin of adminsCommerciaux"
                [nzValue]="admin.id"
                [nzLabel]="admin.first_name + ' ' + admin.last_name"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Secteur d'activité</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-select
                formControlName="secteur_activite"
                [ngModel]="societe?.secteur_activite"
                class="edit-field"
              >
                <nz-option
                  nzValue="RESIDENTIEL INDIVIDUEL"
                  nzLabel="Résidentiel Individuel"
                ></nz-option>
                <nz-option
                  nzValue="RESIDENTIEL COLLECTIF"
                  nzLabel="Résidentiel Collectif"
                ></nz-option>
                <nz-option nzValue="TERTIAIRE" nzLabel="Tertiaire"></nz-option>
                <nz-option nzValue="INDUSTRIE" nzLabel="Industrie"></nz-option>
                <nz-option nzValue="TRANSPORT" nzLabel="Transport"></nz-option>
                <nz-option
                  nzValue="AGRICULTURE"
                  nzLabel="Agriculture"
                ></nz-option>
                <nz-option nzValue="RESEAU" nzLabel="Réseaux"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired nzErrorTip="Ce champ doit avoir 14 chiffres !">SIRET</nz-form-label>
            <nz-form-control
              nz-row
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ doit avoir 14 chiffres !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="societe?.siret"
                  formControlName="siret"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Client</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="14"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.source"
              class="edit-field"
              [nzPlaceHolder]="societe?.source"
              formControlName="source"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let client of clients"
                [nzValue]="client.name"
                [nzLabel]="client.name"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Adresse</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <textarea
                  nz-input
                  formControlName="address"
                  autoSize="true"
                  class="empty-paddings"
                ></textarea>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >N° client externe</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="societe?.num_client_ext"
                  formControlName="num_client_ext"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Code postal</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez renseigner un code postal valide !"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [ngModel]="" formControlName="zip" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Classement</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="societe?.classement"
                  formControlName="classement"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8">Ville</nz-form-label>
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [ngModel]="" formControlName="ville" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >ID CRM</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [ngModel]="societe?.idPartenaireCrm" formControlName="idPartenaireCrm" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired>Statut paiement Anticipé</nz-form-label>
            <nz-form-control nz-col [nzSpan]="14" nzErrorTip="Ce champ est obligatoire !">
              <nz-select
                formControlName="fast_cash_status"
                [ngModel]="societe?.fast_cash_status"
                class="edit-field"
              >
                <nz-option nzValue="NC" nzLabel="NC"></nz-option>
                <nz-option nzValue="Manque infos Installateur" nzLabel="Manque infos Installateur"></nz-option>
                <nz-option nzValue="Edition Convention" nzLabel="Edition Convention"></nz-option>
                <nz-option nzValue="Activé" nzLabel="Activé"></nz-option>
                <nz-option nzValue="Activée (CEE + MPR)" nzLabel="Activée (CEE + MPR)"></nz-option>
                <nz-option nzValue="Activée (CEE seul)" nzLabel="Activée (CEE seul)"></nz-option>
                <nz-option nzValue="Suspendu" nzLabel="Suspendu"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Limite Paiement Anticipé</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [ngModel]="societe?.fast_cash_limit" formControlName="fast_cash_limit" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">PROFIL</div>

    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="10">
              Apporteur d'affaires
            </nz-form-label>
            <nz-form-control nz-col [nzSpan]="14">
              <nz-switch
                formControlName="isApporteurAffaire"
                [(ngModel)]="isApporteurAffaire"
              ></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="0"></div>

        <div *ngIf="isApporteurAffaire" nz-row class="row-content">
          <div nz-col [nzSpan]="24">
            <div *ngIf="entreprises_travaux.length > 0">
              <div
                *ngFor="let et of entreprises_travaux; index as i"
                nz-row
                class="row-content"
              >
                <div nz-col [nzSpan]="10" nzSm="10" nzXs="24">
                  <nz-form-item>
                    <nz-form-label nzLabelWrap nz-col [nzSpan]="12">
                      Raison sociale entreprise travaux partenaire
                    </nz-form-label>
                    <nz-form-control nz-col [nzSpan]="12">
                      <nz-input-group
                        [nzSuffix]="suffixIconEdit"
                        class="edit-field"
                      >
                        <input
                          nz-input
                          [(ngModel)]="entreprises_travaux[i].nom"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="10" nzSm="10" nzXs="24">
                  <nz-form-item>
                    <nz-form-label nzLabelWrap nz-col [nzSpan]="8">
                      ID CRM
                    </nz-form-label>
                    <nz-form-control nz-col [nzSpan]="16">
                      <nz-input-group
                        [nzSuffix]="suffixIconEdit"
                        class="edit-field"
                      >
                        <input
                          nz-input
                          [(ngModel)]="entreprises_travaux[i].id_crm"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="4" class="align-center">
                  <button
                    nz-button
                    nzType="default"
                    nzShape="circle"
                    (click)="deleteEntrepriseTravaux(i)"
                  >
                    <span nz-icon nzType="delete"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div nz-col [nzSpan]="24">
            <button
              nz-button
              nzType="default"
              class="add-partner-button"
              (click)="addNewEntrepriseTravaux()"
              [disabled]="false"
            >
              <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
              Ajouter un élèment
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- </form>
  </div> -->
  <div class="form-container">
    <div class="title">PARAMÉTRAGE</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12" nzRequired
              >Catégorie de prix</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="10" nzErrorTip="Ce champ est obligatoire !">
              <nz-select
                formControlName="categorie_code"
                [ngModel]="societe?.categorie?.code"
                class="edit-field"
              >
                <nz-option
                  *ngFor="let categorie of categories"
                  [nzValue]="categorie.code"
                  [nzLabel]="categorie.nom"
                ></nz-option>
              </nz-select>
              <a href="categories-prix" target="_blank" class="see-all-categories"
                >Voir toutes les catégories</a
              >
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Accès partagé aux projets</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Champ obligatoire"
              [nzSpan]="4"
            >
              <nz-input-group class="edit-field">
                <nz-switch
                [ngModel]="societe?.shared_access"
                formControlName="shared_access"
                [nzUnCheckedChildren]="'Inactif'"
                [nzCheckedChildren]="'Actif'"
              ></nz-switch>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Obligé Hors cdp</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige?.id"
              class="edit-field"
              formControlName="oblige_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Obligé cdp précaire Chauffage BAR indiv</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige_precaire?.id"
              class="edit-field"
              [nzPlaceHolder]="societe?.oblige_precaire?.nom"
              formControlName="oblige_precaire_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Coup de pouce chauffage résidentiel individuel</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Champ obligatoire"
              [nzSpan]="4"
            >
              <nz-input-group class="edit-field">
                <nz-switch
                [ngModel]="societe?.isCPResidentielChauffage"
                formControlName="isCPResidentielChauffage"
                [nzUnCheckedChildren]="'Inactif'"
                [nzCheckedChildren]="'Actif'"
              ></nz-switch>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Obligé cdp classique Chauffage BAR indiv</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige_cdp?.id"
              class="edit-field"
              [nzPlaceHolder]="societe.oblige_cdp?.nom"
              formControlName="oblige_cdp_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Coup de pouce rénovation globale des BRC</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Champ obligatoire"
              [nzSpan]="4"
            >
              <nz-input-group class="edit-field">
                <nz-switch
                [ngModel]="societe?.isCPRenoGlobaleCollectif"
                formControlName="isCPRenoGlobaleCollectif"
                [nzUnCheckedChildren]="'Inactif'"
                [nzCheckedChildren]="'Actif'"
              ></nz-switch>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Obligé Coup de pouce réno globale collectif</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige_cdp_reno_globale_collectif?.id || societe?.oblige_cdp_reno_globale_collectif"
              class="edit-field"
              [nzPlaceHolder]="societe.oblige_cdp_reno_globale_collectif?.nom"
              formControlName="oblige_cdp_reno_globale_collectif"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Coup de pouce chauffage tertiaire et résidentiel
              collectif</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Champ obligatoire"
              [nzSpan]="4"
            >
              <nz-input-group class="edit-field">
                <nz-switch
                [ngModel]="societe?.isCPTertiaireChauffage"
                formControlName="isCPTertiaireChauffage"
                [nzUnCheckedChildren]="'Inactif'"
                [nzCheckedChildren]="'Actif'"
              ></nz-switch>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label
              nzLabelWrap
              nz-col
              [nzSpan]="12"
              class="margin-bottom"
              >Obligé Coup de pouce Tertiaire et Résidentiel
              collectif</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige_cdp_bat_res_coll?.id"
              class="edit-field"
              [nzPlaceHolder]="societe.oblige_cdp_bat_res_coll?.nom"
              formControlName="oblige_cdp_bat_res_coll_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Coup de pouce Pilotage connecté</nz-form-label
            >
            <nz-form-control
              nz-col
              nzErrorTip="Champ obligatoire"
              [nzSpan]="4"
            >
              <nz-input-group class="edit-field">
                <nz-switch
                [ngModel]="societe?.isCPPilotageConnecte"
                formControlName="isCPPilotageConnecte"
                [nzUnCheckedChildren]="'Inactif'"
                [nzCheckedChildren]="'Actif'"
              ></nz-switch>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="12"
              >Obligé Coup de pouce pilotage connecté</nz-form-label
            >
            <nz-form-control
            nz-col
            [nzSpan]="10"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="societe?.oblige_cdp_pilotage_connecte?.id || societe?.oblige_cdp_pilotage_connecte"
              class="edit-field"
              [nzPlaceHolder]="societe.oblige_cdp_pilotage_connecte?.nom"
              formControlName="oblige_cdp_pilotage_connecte"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
    <div class="button-wrapper">
      <div class="additional-buttons">
        <button
          nz-button
          nzTooltipTitle="Sauvegarder"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small save-button"
          [disabled]="!checkFormChanges()"
          (click)="editSociete()"
        >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
        </button>
        <button
          nz-button
          nzTooltipTitle="Annuler"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small cancel-button"
          (click)="goBack()"
        >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</nz-content>
