<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="18" nz-col nz-form>
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
            (keyup.enter)="queryAdmins()"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher un administrateur..."
            type="text"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="queryAdmins()" nz-icon nzType="search"></span>
      </ng-template>
      <div [nzSpan]="3" class="padding-left-10" nz-col>
        <button
          (click)="toogleFilters = !toogleFilters"
          class="filters-toogle secondary-button"
          nz-button
          nzType="default"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
      <div [nzSpan]="3" class="padding-left-10" nz-col>
        <button
          class="add-button"
          nz-button
          nzType="primary"
          routerLink="/administrateurs/new"
        >
          <span nz-icon nzTheme="outline" nzType="plus-circle"></span>
          Ajouter
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || admins.length === 0"/>

    <div *ngIf="toogleFilters" class="filters-panel">
      <form [formGroup]="queryForm" class="ant-advanced-search-form" nz-form>
        <div nz-row>
          <div nz-col nzSpan="20">
            <div class="filter-fields" nz-row>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_roles"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="role"
                      id="statut-cee"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="
                          Rôle"
                      nzSize="small"
                    >
                      <nz-option
                        nzLabel="Super administrateur"
                        nzValue="ROLE_SUPER_ADMIN"
                      ></nz-option>
                      <nz-option
                        nzLabel="Administrateur"
                        nzValue="ROLE_ADMIN"
                      ></nz-option>
                      <nz-option
                        nzLabel="Chargé d'Affaires"
                        nzValue="ROLE_CHARGE_AFFAIRES"
                      ></nz-option>
                      <nz-option
                        nzLabel="Contrôleur CEE"
                        nzValue="ROLE_CONTROLEUR_CEE"
                      ></nz-option>
                      <nz-option
                        nzLabel="Contrôleur CEE + MPR"
                        nzValue="ROLE_CONTROLEUR_CEE_MPR"
                      ></nz-option>
                      <nz-option
                        nzLabel="Observateur"
                        nzValue="ROLE_OBSERVATEUR"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_activation"
                      class="custom-filter-field"
                      formControlName="is_active"
                      nzAllowClear="true"
                      nzPlaceHolder="Activation compte"
                      nzSize="small"
                    >
                      <nz-option nzLabel="Actif" nzValue=true></nz-option>
                      <nz-option nzLabel="Inactif" nzValue=false></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div nz-col nzJustify="center" nzSpan="4">
            <div nz-row nzJustify="center">
              <button
                (click)="resetForm()"
                class="filter-button"
                nz-button
                nzBlock
                nzShape="round"
                nzSize="small"
                nzType="default"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center">
              <button
                (click)="queryAdmins()"
                class="filter-button blue-grey-button-color"
                nz-button
                nzBlock
                nzShape="round"
                nzSize="small"
                nzType="primary"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <nz-table
      #fixedTable
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzData]="admins"
      [nzFrontPagination]="false"
      [nzLoading]="isLoadingData"
      [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize"
      [nzScroll]="{ x: '500px' }"
      [nzShowTotal]="total_admins_template"
      [nzTotal]="total_admins"
      class="projet-list-table"
      nzPaginationPosition="both"
    >
      <ng-template #total_admins_template>
        <button
          (nzOnCancel)="cancel()"
          (nzOnConfirm)="confirmDelete()"
          *ngIf="checked_admins.size > 0"
          [nzIcon]="popConfirmIcon"
          class="secondary-button mass-action-button"
          nz-button
          nz-popconfirm
          nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement tous les administrateurs sélectionnés ?"
          nzType="default"
        >
          <span nz-icon nzType="delete"></span>
          Supprimer
        </button>
        <button
          (click)="exporter()"
          *ngIf="checked_admins.size > 0"
          class="secondary-button mass-action-button"
          nz-button
          nzType="default"
        >
          <span nz-icon nzType="download"></span>
          Exporter
        </button>
        <span>{{ total_admins }} {{ total_admins == 1 ? ' administrateur' : ' administrateurs' }} </span>
        <ng-template #popConfirmIcon></ng-template>
      </ng-template>

      <thead class="liste-projets-head">
      <tr>
        <th
          (nzCheckedChange)="onAllChecked($event)"
          [(nzChecked)]="all_checked"
          [nzIndeterminate]="indeterminate"
          nzWidth="30px"
        ></th>
        <th>Nom complet</th>
        <th>Email</th>
        <th>Rôle</th>
        <th nzAlign="center" nzWidth="180px">Activation compte</th>
        <th nzAlign="center" nzRight nzWidth="90px">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of fixedTable.data">
        <td
          (nzCheckedChange)="onItemChecked(data.id, $event)"
          [nzChecked]="checked_admins.has(data.id)"
        ></td>
        <td>{{ data.first_name }} {{ data.last_name }}</td>
        <td>{{ data.email }}</td>
        <td>{{ roleToLabel(data.role) }}</td>
        <td nzAlign="center">
          <nz-tag
            [nzBordered]="false"
            [nzColor]="data.is_active ? 'green' : 'volcano'"
            class="active-tag"
          >{{ data.is_active ? "Oui" : "Non" }}
          </nz-tag
          >
        </td>
        <td nzAlign="center" nzRight nzWidth="90px">
          <button
            [nzDropdownMenu]="menu"
            class="project-actions-button"
            nz-button
            nz-dropdown
          >
            <span nz-icon nzType="setting"></span>
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item routerLink="/administrateurs/{{ data.id }}">
                <a
                  ><span nz-icon nzType="eye" nzTheme="outline"></span>
                  Détails</a
                >
              </li>
              <li nz-menu-item routerLink="/administrateurs/edit/{{ data.id }}">
                <a
                ><span nz-icon nzTheme="outline" nzType="edit"></span>
                  Éditer</a
                >
              </li>
              <li
                (nzOnCancel)="cancel()"
                (nzOnConfirm)="deleteAdmin(data.id)"
                nz-menu-item
                nz-popconfirm
                nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement l'administrateur sélectionné ?"
              >
                <a
                ><span nz-icon nzTheme="outline" nzType="delete"></span>
                  Supprimer</a
                >
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
