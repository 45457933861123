<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Modification de l'administrateur {{ admin?.username }}</div>
    <form
      [formGroup]="editForm"
      class="form-layout"
      nz-form
    >
      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Prénom
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Le prénom est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="admin.first_name"
                  formControlName="first_name"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Nom
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Le nom est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="admin.last_name"
                  formControlName="last_name"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Nom d'utilisateur
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Le nom d'utilisateur est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="admin.username"
                  formControlName="username"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Email
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="L'email est obligatoire"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="admin.email"
                  formControlName="email"
                  nz-input
                  type="email"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Rôle
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Le rôle est obligatoire"
            >
              <nz-select [(ngModel)]="admin.role" formControlName="role">
                <nz-option
                  nzLabel="Super administrateur"
                  nzValue="ROLE_SUPER_ADMIN"
                ></nz-option>
                <nz-option
                  nzLabel="Administrateur"
                  nzValue="ROLE_ADMIN"
                ></nz-option>
                <nz-option
                  nzLabel="Chargé d'Affaires"
                  nzValue="ROLE_CHARGE_AFFAIRES"
                ></nz-option>
                <nz-option
                  nzLabel="Contrôleur CEE"
                  nzValue="ROLE_CONTROLEUR_CEE"
                ></nz-option>
                <nz-option
                  nzLabel="Contrôleur CEE + MPR"
                  nzValue="ROLE_CONTROLEUR_CEE_MPR"
                ></nz-option>
                <nz-option
                  nzLabel="Observateur"
                  nzValue="ROLE_OBSERVATEUR"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap
            >ID CRM
            </nz-form-label
            >
            <nz-form-control [nzSpan]="14" nz-col>
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  [(ngModel)]="admin.id_crm"
                  formControlName="id_crm"
                  nz-input
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row-content" nz-row>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Origine
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Le client est obligatoire"
            >
              <nz-select
                [nzDropdownMatchSelectWidth]="false"
                formControlName="client_id"
                nzAllowClear="true"
              >
                <nz-option
                  *ngFor="let client of clients"
                  [nzLabel]="client.name"
                  [nzValue]="client.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nz-col nzLabelWrap nzRequired
            >Accès clients
            </nz-form-label
            >
            <nz-form-control
              [nzSpan]="14"
              nz-col
              nzErrorTip="Les accès clients sont obligatoires"
            >
              <nz-select
                [ngModel]="clients_access"
                [nzDropdownMatchSelectWidth]="false"
                formControlName="clients_access"
                nzAllowClear="true"
                nzMode="multiple"
              >
                <nz-option
                  *ngFor="let client of clients"
                  [nzLabel]="client.name"
                  [nzValue]="client.id"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>

      <div [nzSpan]="12" nz-col nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="8" nz-col nzLabelWrap>Actif</nz-form-label>
          <nz-form-control [nzSpan]="14" nz-col>
            <nz-switch
              [(ngModel)]="admin.is_active"
              formControlName="is_active"
            ></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            [disabled]="!checkFormChanges()"
            class="circle-button small save-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Sauvegarder"
            (click)="editAdmin()"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            (click)="goBack()"
            class="circle-button small cancel-button"
            nz-button
            nz-tooltip
            nzTooltipPlacement="leftTop"
            nzTooltipTitle="Annuler"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
