import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminsService {
  constructor(private http: HttpClient) {}

  getAllAdmins(page: number, limit: number, client: string, query: any) {
    return this.http.post(
      `${environment.apiUrl}/admins/all/page=${page}&limit=${limit}&client=${client}`,
      query,
    );
  }

  getAllAdminsOrderedById(
    page: number,
    limit: number,
    client: string,
    query: any,
  ) {
    return this.http.post(
      `${environment.apiUrl}/admins/all/Ordered/page=${page}&limit=${limit}&client=${client}`,
      query,
    );
  }

  getFilterAdmins(page: number, limit: number, query: any) {
    return this.http.post(
      `${environment.apiUrl}/admins/page=${page}&limit=${limit}`,
      query,
    );
  }

  getAdminById(id: number) {
    return this.http.get(`${environment.apiUrl}/admins/${id}`);
  }

  editAdmin(id: number, payload: any) {
    return this.http.put(`${environment.apiUrl}/admins/${id}`, payload);
  }

  createAdmin(payload: any) {
    return this.http.post(`${environment.apiUrl}/admins/create`, payload);
  }

  deleteAdmin(admin_id: any) {
    return this.http.delete(`${environment.apiUrl}/admins/${admin_id}`).pipe(
      catchError((error) => {
        throw error;
      }),
    );
  }

  exportAllAdmins(client: string, query: any) {
    return this.http.post(
      `${environment.apiUrl}/admins/export/client/${client}`,
      query,
      { responseType: 'blob' },
    );
  }

  exportAdmins(admins_ids: number[]) {
    return this.http.post(
      `${environment.apiUrl}/admins/export`,
      { admins_ids },
      { responseType: 'blob' },
    );
  }

  deleteManyAdmins(ids: number[]) {
    return this.http
      .post(`${environment.apiUrl}/admins/bulk-delete`, { ids })
      .pipe(
        catchError((error) => {
          throw error;
        }),
      );
  }
}
