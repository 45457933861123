import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjetsService } from 'src/app/services/projets.service';
import { SidebarService } from 'src/app/services/sidebar.service';

import { AuthService } from '../../../services/auth.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  openMap: { [name: string]: boolean } = {
    projets: true,
    contacts: false,
    parametrage: false,
    communications: false,
    hub_developpeur: false,
  };
  hasProjects = false;
  imageSrc: any;
  loadingAvatar: boolean = true;

  constructor(
    protected sidebarService: SidebarService,
    private readonly authService: AuthService,
    private readonly projetsService: ProjetsService,
    private readonly DocumentService: DocumentService,
    private router: Router
  ) { }

  async ngOnInit() {
    
    const current_user = this.authService.getUserPayload();
    this.projetsService.hasProjet(current_user.sub ? current_user.sub : current_user.id).subscribe((res: any) => {
      this.hasProjects = res;
    });
    this.sidebarService.avatar$.subscribe((avatar) => {
      this.imageSrc = avatar;
      this.loadingAvatar = false;
    });
    this.loadAvatar();
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

  logout() {
    console.log('logout');
    this.authService.logout().subscribe({
      next: () => {
        this.authService.isLoggedIn.next(false);
        this.router.navigate(['/login']);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  canAccess(tab_name: string) {
    return this.authService.canAccess(tab_name);
  }

  getUsername() {
    const user = this.authService.getUserPayload();
    return user.firstname ? user.firstname : user.first_name;
  }

  loadAvatar(): void {
    this.authService.getCurrentUser().subscribe(
      (user: any) => {
        const avatar = user.avatar;
        if (avatar) {
          this.DocumentService.previewDocumentByName(avatar, 'avatar').subscribe(
            (res: any) => {
              const blob = new Blob([res], { type: 'image/jpeg' });
              this.imageSrc = URL.createObjectURL(blob);
              this.loadingAvatar = false;
            },
            (error) => {
              this.loadingAvatar = false;
            }
          );
        } else {
          this.loadingAvatar = false;
        }
        
      },
      (error) => {
        this.loadingAvatar = false;
      }
    );
  }
}
