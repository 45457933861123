export const labels: any = {
  all: {
    typeLogement: 'Type de Logement',
    nomEquipement: "Nom de l'équipement",
  },
  'BAR-TH-112': {
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement remplacé",
    etas: 'Efficacité énergétique saisonnière (Etas)',
    type_equipement: "Type d'équipement installé",
    carac_equipement: "Caractéristique de l'appareil installé :",
    emission_monoxyde:
      "Émission de monoxyde de carbone rapportée à 13% d'O2 (mg/Nm³)",
    emission_particules: "Émission de particules rapportée à 13% d'O2 (mg/Nm³)",
    emission_oxydes_azote:
      "Émission d'oxydes d'azote rapportées à 13% d'O2 (mg/Nm³)",
    emission_cog:
      "Émission de composés organiques gazeux rapportées à 13% d'O2 (mg/Nm3)",
    rendement: 'Rendement énergétique (en %)',
  },
  'RES-CH-106': {
    classeIsolation: "Classe d'isolation thermique",
    longueur: 'Longueur calorifugée (en mètres)',
    dureeAnnuelle: "Durée annuelle d'utilisation du réseau (en mois)",
    diametreNominal:
      'Diamètre nominal (DN) de la canalisation (en millimètres)',
    natureFluide: 'Nature du fluide caloporteur',
    conductivite_thermique: 'Conductivité thermique de l’isolant (en W/m.K)',
  },
  'AGRI-TH-104': {
    mode: "Durée annuelle d'utilisation de la chaleur récupérée D en heures",
    puissanceRecuperee:
      'Puissance thermique du système de récupération de chaleur en kW',
    puissanceDejaRecuperee:
      'Puissance thermique déjà récupérée sur le groupe de production de froid en kW',
    puissanceCompresseur:
      'Somme des puissances électriques nominales indiquées sur les plaques du ou des compresseur(s) raccordé(s) au système de récupération de chaleur en kW',
  },
  'BAR-EN-101': {
    surface: "Surface de l'isolant en m²",
    nombre_foyer_non_precaire: 'Nombre de foyers à revenu non précaire',
    nombre_foyer_faible_revenu: 'Nombre de foyers à faible revenu',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
  },
  'BAR-EN-103': {
    surface_isolant: "Surface de l'isolant en m²",
    nombre_foyer_non_precaire: 'Nombre de foyers à revenu non précaire',
    nombre_foyer_faible_revenu: 'Nombre de foyers à faible revenu',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
  },
  'IND-UT-135': {
    plagedetemperature:
      'Plage de température de consigne du réseau hydraulique',
    dureefonctionnement: "Durée de fonctionnement de l'usage du froid",
    puissanceelectrique:
      'Puissance électrique nominale du groupe de production de froid (en kW) (hors compresseur de secours)',
    dimention:
      "Le système de freecooling est dimensionné pour couvrir, lorsqu'il fonctionne, 100% du besoin minimal en froid",
    comporte: 'Le système de freecooling comporte',
    automatisme: "Il comporte un système d'automatisme et de régulation",
    difference:
      'La différence entre la température extérieure d’entrée d’air et température de consigne de départ d’eau est de 3°C au minimum',
  },
  'IND-BA-110': {
    installation: 'Système de chauffage',
    coefficient: 'Hauteur sous faîtage ( en mètre)',
    mode: 'Rythme d’exploitation',
    puissanceConvectif: 'Puissance Convectif en kW',
    puissanceRadiatif: 'Puissance Radiatif en kW',
  },
  'IND-BA-112': {
    mode: 'Mode de fonctionnement du système de récupération',
    puissanceTAR: 'Puissance TAR en kW',
    puissance: 'Puissance thermique récupérée en kW',
    isConnected:
      'La tour aéroréfrigérante est-elle connectée à un groupe de production de froid',
  },
  'IND-BA-117': {
    installation: 'Les appareils installés sont du type',
    mode: "Mode d'occupation des locaux",
    puissance: 'Puissance utile totale installée en kW',
  },
  'IND-UT-129': {
    nature: "Nature de l'opération",
    mode: 'Mode de fonctionnement du site',
    Puissance_totale_des_bruleurs:
      'Puissance électrique nominale de la presse en kW',
    puissanceHydraulique:
      'Puissance électrique nominale de la presse hydraulique existante en kW',
    puissancePresseNeuve:
      'Puissance électrique nominale de la presse tout électrique neuve en kW',
    puissancePresseHybride:
      'Puissance électrique nominale de la presse hybride neuve en kW',
  },
  'IND-UT-134': {
    puissance: 'Puissance nominale P en kW',
    mode: 'Mode de fonctionnement du site industriel',
    duree: 'Durée du contrat (année)',
    ipe: 'IPE mesuré (Indice de Performance Énergétique)',
  },
  'IND-UT-103': {
    usage: 'Usage de la chaleur',
    mode: 'Mode de fonctionnement du site',
    puissanceelectrique:
      'Puissance électrique nominale du compresseur d’air (en kW)',
    puissance: "Puissance thermique de l'échangeur (en kW thermique)",
    echangeur: 'Mise en place d’un échangeur',
  },
  'IND-UT-120': {
    puissance: 'Puissance électrique nominale du compresseur d’air en kW',
  },
  'IND-UT-112': {
    puissance: 'Puissance nominale du moteur (en kW)',
  },
  'IND-UT-122': {
    puissance:
      "Somme des puissances électriques nominales des compresseurs d'air connectés au sécheur (en kW)",
    nombre: "Nombre de compresseurs d'air",
    mode: 'Mode de fonctionnement du site',
  },
  'IND-UT-124': {
    puissance:
      'Puissance électrique nominale totale des compresseurs pilotés en kW',
    nombre: 'Nombre de compresseurs pilotés',
    compresseurs: 'Puissance pour chaque compresseur pilotés',
    sequenceur: 'Séquenceur',
  },
  'IND-UT-104': {
    mode: 'Mode de fonctionnement du site',
    puissance: 'Puissance utile nominale de la chaudière en kW',
  },
  'IND-UT-105': {
    mode: 'Mode de fonctionnement du site',
    puissance: 'Puissance utile nominale de la chaudière en kW',
  },
  'IND-UT-118': {
    usage: "Type d'opération",
    mode: 'Mode de fonctionnement du site',
    puissance:
      'Somme des puissances thermiques nominales des brûleurs neufs en kW',
    temperature: 'Température des fumées T à la sortie du four en °C',
  },
  'IND-UT-125': {
    mode: 'Mode de fonctionnement du site',
    puissance: 'Puissance des chaudières en kW',
  },
  'IND-UT-130': {
    mode: 'Mode de fonctionnement',
    puissance: 'Puissance utile nominale de la chaudière en kW',
  },
  'IND-UT-113': {
    condenseur: 'Type de système de condensation frigorifique',
    mode: 'Mode de fonctionnement du site industriel',
    puissance:
      "Puissance électrique nominale de l'installation frigorifique en kW",
    Delta_T_C: 'Différence de température',
    differenceEauSeul:
      "Différence entre la température de condensation du fluide frigorigène et la température de l'eau en entrée du condenseur",
    differenceAirSec:
      "Différence entre la température de condensation du fluide frigorigène et la température de l'air sec",
    differenceEvaporatif:
      "Différence entre la température de condensation du fluide frigorigène et la température de l'air au bulbe humide",
  },
  'IND-UT-115': {
    puissance:
      'Puissance électrique nominale du groupe de production de froid en kW',
  },
  'IND-UT-116': {
    mode: 'Mode de condensation',
    puissance:
      'Puissance électrique nominale du groupe de production de froid en kW',
  },
  'IND-UT-117': {
    mode: "Durée annuelle d'utilisation de la chaleur récupérée D en heures",
    puissanceRecuperee:
      'Puissance thermique du système de récupération de chaleur en kW',
    puissanceDejaRecuperee:
      'Puissance thermique déjà récupérée sur le groupe de production de froid en kW',
    puissanceCompresseur:
      'Somme des puissances électriques nominales indiquées sur les plaques du ou des compresseur(s) raccordé(s) au système de récupération de chaleur en kW',
  },
  'IND-UT-121': {
    mode: "Rythme de fonctionnement de l'usine",
    reseau: 'Réseau de',
    nombre: "Nombre de matelas d'isolation mis en place",
  },
  'IND-UT-131': {
    usage: "Type d'usage",
    mode: "Mode de fonctionnement du système d'isolation",
    longueur: 'Longueur isolée en m',
    surface: "Surface d'isolant posé en m²",
    temperature: 'Température du fluide (°C)',
  },
  'BAR-TH-110': {
    type: 'Type de logement',
    nombre: 'Nombre de radiateur(s) basse température mis en place',
  },
  'IND-UT-102': {
    application: 'Application',
    puissance: 'Puissance nominale du moteur en kW',
    classemoteur: 'Classe du moteur',
    moteur: 'Moteur asynchrone neuf',
    marque_moteur: 'Marque du moteur',
    reference_moteur: 'Référence du moteur',
    marque_variateur_vitesse: 'Marque du variateur de vitesse',
    reference_variateur_vitesse: 'Référence du variateur de vitesse',
  },
  'IND-UT-114': {
    application: 'Application',
    puissance: 'Puissance nominale du moto-variateur en kW',
  },
  'IND-UT-123': {
    puissance: 'Puissance nominale du moteur en kW',
  },
  'IND-UT-132': {
    puissance: 'Puissance utile du moteur en kW',
  },
  'IND-UT-127': {
    type: 'Type de transmission mise en place',
    puissance: 'Puissance électrique nominale du moteur associé en kW',
  },
  'IND-UT-136': {
    installation: "Type d'nstallation",
    puissance:
      'Puissance électrique nominale du moteur entraînant le système moto-régulé (en kW)',
    regime: 'Le régime de fonctionnement est saisonnier',
    modeSaisonnier: 'Mode de fonctionnement du site (saisonnier)',
    modeNormal: 'Mode de fonctionnement du site (normal)',
  },
  'IND-UT-137': {
    energie_thermique: 'Énergie thermique annuelle Q du système (en kWh/an)',
    energie_electrique:
      'Énergie électrique annuelle absorbée Eélec par le système (en kWh/an)',
    delta_temperature: 'Écart de température',
    temperature_eau: "Température de l'eau en sortie du condenseur",
    nombre_pac: 'Nombre de Pompes à chaleur du système',
  },
  'AGRI-UT-103': {
    puissanceelectrique:
      'Puissance électrique nominale du groupe de production de froid en kW',
  },
  'AGRI-UT-101': {
    puissanceelectrique: 'Puissance électrique nominale du moto variateur',
    application: "Type d'application",
  },
  'AGRI-TH-110': {
    type: 'Type de serre(s)',
    surfacedeserre: 'Surface de serre chauffée (en m²)',
    puissancethermique:
      'Puissance thermique nominale de la chaudière installée (en kW)',
    rendementfull: 'Rendement PCI de la chaudière à pleine charge (en %)',
    rendementnotfull: 'Rendement PCI de la chaudière à 30 % de charge (en %)',
  },
  'AGRI-TH-108': {
    surface: 'Surface de serre chauffée (m²)',
    type: 'Type de Serre',
    puissance: 'PAC > 400 ?',
    cop: 'Coefficient de performance',
    efficacite: 'Efficacité énergétique saisonnière (en %)',
  },
  'AGRI-TH-119': {
    surface: 'Surface de serre équipée (m²)',
  },
  'AGRI-EQ-106': {
    regulation: 'Type de régulation',
    volume: 'Volume de céréales (m³)',
  },
  'AGRI-UT-104': {
    application: 'Application',
    puissance:
      'Puissance électrique nominale totale du groupe de production de froid en kW',
  },
  'AGRI-EQ-105': {
    nbvehicules: 'Nombre de véhicules concernés par l’opération',
  },
  'AGRI-TH-113': {
    typebatiment: 'Types de bâtiments',
    debit: 'Débit global minimum par m² de bâtiment (m³/h/m²)',
    surface: 'Surface de bâtiment équipé (m²)',
  },
  'BAT-TH-122': {
    secteur_activite: "Secteur d'activité",
    surface:
      'Surface climatisée gérée par les équipements de programmation d’intermittence mis en place (en m²)',
    puissance_frigorifique:
      'La climatisation est assurée par un système centralisé préexistant dont la puissance frigorifique totale est supérieure ou égale à 50 kW',
    programmation_heure_fixe:
      'Le système assure une programmation à heure fixe journalière et hebdomadaire de la fourniture de froid selon les allures confort, réduit et arrêt',
  },
  'BAT-TH-155': {
    DN_Equipt: 'Type de points singuliers',
    Temp_fluide: 'Température',
    secteur: 'Secteur',
    Points_singuliers: 'Nombre de matelas',
  },
  'BAT-EN-107': {
    energiedechauffage: 'Énergie de chauffage',
    secteurdactivite: "Secteur d'activité",
    surfacedisolant: "Surface d'isolant en m²",
    resistancethermique: 'Résistance thermique R (en m².K/W)',
  },
  'BAT-TH-102': {
    surface: 'Surface chauffée (m²)',
    usage: "Type d'usage",
    secteur: 'Secteur',
    nombre_chaudieres: 'Nombre de chaudière dans la chaufferie',
    puissance_totale: 'Puisssance totale de la chaufferie après travaux en KW',
    chaudiere_puissance: 'Puissance pour chaque chaudière',
    classe_regulateur: 'Classe du régulateur installé',
  },
  'BAT-TH-103': {
    surface: 'Surface chauffée (m²)',
    secteur: 'Secteur',
  },
  'BAT-TH-108': {
    surface: 'Surface chauffée (m²)',
    secteur: "Secteur d'activité",
    energie: 'Energie de chauffage',
  },
  'BAR-TH-113': {
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement",
    cuve_fioul: 'Dépose d’une cuve à fioul',
    puissance: 'Puissance thermique nominale de la chaudière (en kW)',
    etas: 'Efficacité énergétique saisonnière (en %)',
    classe_regulateur: 'Classe du régulateur',
    mode_alimentation: "Mode d'alimentation de la chaudière",
    is_flamme_v7:
      'La chaudière biomasse installée est labellisée flamme verte 7',
    emissions_particules: 'Emissions saisonnières de particules (en mg/Nm³)',
    emissions_carbone:
      'Emissions saisonnières de monoxyde de carbone (en mg/Nm³)',
    emissions_azote: "Emissions saisonnières d'oxydes d'azote (en mg/Nm³) :",
    emissions_organique:
      'Emissions saisonnières de composés organique gazeux (en mg/Nm³)',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-129': {
    logement: 'Type de logement',
    surface: 'Surface chauffée en m²',
    coefficient: 'Coeffcient de performance saisonnier (SCOP)',
  },
  'BAR-TH-148': {
    logement: 'Type de logement',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    profil_soutirage: 'Profil de soutirage',
    etas: 'ETAS',
    typeinstallation: "Type d'installation",
    coefficient: 'Coefficient de Performance',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-158': {
    logement: 'Type de logement',
    nombre: "Nombre d'émetteurs électriques installés",
  },
  'BAR-TH-159': {
    secteur: 'Type de logement',
    etas: 'Efficacité énergétique saisonnière ηS (en %)',
    surface: 'Surface chauffée en m²',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement",
    classe_regulateur: 'Classe du régulateur',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-SE-106': {
    logement: 'Type de logement',
    chauffage: 'Energie concernée par le service de suivi',
  },
  'BAR-SE-108': {
    type: 'Type de logement',
    puissancethermique:
      "Puissance thermique nominale de l'installation de chauffage en kW",
  },
  'BAR-TH-111': {
    type: "Type de l'énergie de chauffage",
    surface: 'Surface habitable en m²',
  },
  'BAR-TH-117': {
    type: 'Type de logement',
    nombre: 'Nombre de robinets thermostatiques installés',
  },
  'BAR-TH-118': {
    type: "Programmation d'intermittence pour",
    surface: 'Surface habitable en m²',
    cat: 'Energie de chauffage',
    nombre: "Nombre d'appartements",
  },
  'BAR-TH-137': {
    nombre: "Nombre d'appartements raccordés",
    alimentation_reseau: 'Alimentation du réseau de chaleur',
    surface: 'Surface habitable S (m²)',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    energie_renouvelable:
      'Le réseau de chaleur est alimenté majoritairement par des énergies renouvelables ou de récupération',
    equipement: 'Type d’équipement remplacé',
    cuve_fioul: 'Dépose d’une cuve à fioul',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-101': {
    surface_capteurs:
      'Surface hors-tout totale de capteurs solaires vitrés thermiques installés (m²)',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    etas: 'ETAS',
    profil_soutirage: 'Profil de soutirage',
    energie_appoint: 'Energie de l’appoint',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
    capacite_ballon:
      "Capacité de stockage du ballon d'eau chaude solaire (litres)",
    classe_energetique_ballon:
      "Classe d'efficacité énergétique du ballon d'eau chaude solaire",
  },
  'BAR-TH-143': {
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement",
    cuve_fioul: 'Dépose d’une cuve à fioul',
    etat_appoint: 'ETAS de l’appoint (%)',
    etat_capteurs: 'ETAS des capteurs (%)',
    surface_capteurs: 'Surface (hors tout) des capteurs installés (m²)',
    capacite_stockage:
      "Capacité de stockage du (des) ballon(s) d'eau chaude solaire(s) (en litre)",

    productivite: 'Productivité des capteurs solaires (en W/m²)',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-162': {
    surface: 'Surface totale des capteurs hybrides mis en place (m²)',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-160': {
    typeIsolant: "Type d'isolant",
    classeIsolant: "Classe de l'isolant installé",
    longueur:
      'Longueur isolée du réseau de chauffage ou d’ECS hors du volume chauffé',
    datederemplacement:
      'Date de remplacement de l’installation de chauffage ou d’eau chaude sanitaire',
  },
  'BAR-TH-161': {
    temperature: 'Température des fluides (°C)',
    nbrHousseCat1:
      'Nombre de housses isolantes de diamètre (en mm) 20 ≤ DN ≤ 65',
    nbrHousseCat2:
      'Nombre de housses isolantes de diamètre (en mm) 65 < DN ≤ 100',
    echangeurs: "Nombre d'échangeurs à plaques isolés",
    DN_Equipt: 'Type de point singulier',
    Temp_fluide: 'Température du fluide caloporteur (°C )',
    nombre_pieces: 'Nombre de pièces',
  },
  'BAR-EN-102': {
    surface: "Surface d'isolant (en m²)",
    type: 'Type d’opération',
    resistance: 'Résistance thermique R de l’isolant installé',
    typeLogement: 'Type de logement',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-EN-104': {
    typeLogement: 'Type de logement',
    nombre:
      'Nombre de fenêtres ou portes-fenêtres complètes avec vitrage isolant posées',
    surface: 'Surface totale',
    coefficient: 'Coefficients de transmission surfaciques (Uw)',
    facteur: 'Facteur solaire Sw (Sw)',
    type: 'Type de fenêtre',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-EN-105': {
    surface: "Surface d'isolant (en m²)",
    resistance: 'Résistance thermique R de l’isolant installé',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'IND-EN-102': {
    type_batiment: 'Type du bâtiment',
    surface: "Surface d'isolant (en m²)",
    resistance: 'Résistance thermique R de l’isolant installé',
  },
  'BAR-EN-106': {
    surface: "Surface d'isolant posé(en m²)",
    resistance: 'Résistance thermique R (m².K/W)',
    type: 'Type de logement',
    logement: 'Etat du logement',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-EN-107': {
    surface: "Surface d'isolant posé(en m²)",
    resistance: 'Résistance thermique R (m².K/W)',
    logement: 'Type de logement',
    type: 'Type d’opération',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-124': {
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    surface: 'Surface des capteurs posés (en m²)',
    zonegeographique: 'Zone géographique',
    logement: 'Type de logement',
    profil_soutirage: 'Profil de soutirage',
    energie_appoint: 'Energie de l’appoint',
    etas: 'ETAS',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-125': {
    nombre: 'Nombre de logements',
    efficacite:
      "Rendement en température (efficacité thermique) de l'échangeur de chaleur (%)",
    surface: 'Surface habitable (en m²)',
    ventilation: "Type d'installation",
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    classe_energetique: 'Classe énergétique du système de ventilation',
    puissance_absorbe: 'Puissance électrique absorbée pondérée (WThC)',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-127': {
    installation: "Type d'installation",
    ventilation: 'Type de ventilation mécanique contrôlée',
    caisson:
      'Type de caisson pour ventilation mécanique simple flux hygroréglable',
    puissance_absorbe:
      'Puissance électrique absorbée pondérée du caisson de ventilation',
    nature_systeme: 'Système mis en place',
    classe_energetique: 'Classe énergétique du système de ventilation',
    nombre: 'Nombre de logements',
    surface: 'Surface habitable (en m²)',
  },
  'BAR-TH-107': {
    nombre_appartements: "Nombre d'appartements",
    nombre_installed:
      'Nombre des chaudières éligibles installées (sans compter les chaudières de secours)',
    puissance_totale: 'Puissance totale de la chaufferie après travaux',
    puissance_chaudiere: 'Puissance pour chaque chaudière',
    pac: 'Pompes à chaleur (PAC)',
    puissance_pac: 'Puissance totale PAC',
    entretien: 'Durée du contrat de conduite',
  },
  'BAR-TH-169': {
    nombre_appartements: "Nombre d'appartements",
    type: 'La Pompe à Chaleur est de type',
    cop: 'Coefficient de performance (COP)',
    facteur: 'Facteur R de la PAC',
    profil_soutirage: 'Profil de soutirage',
    etas: 'Efficacité énergétique (en %)',
  },
  'BAR-TH-123': {
    nombre: "Nombre d'appartements",
  },
  'BAR-TH-139': {
    classeMoteur: 'Classe du moteur',
    nombre_pompes: 'Nombre de pompes',
    pompes: 'Puissance unitaire nominale pour chaque pompe',
  },
  'BAR-TH-163': {
    chaudiere_modestes:
      'Nombre de chaudière(s) à raccorder au conduit pour des ménages modestes',
    chaudiere_classiques:
      'Nombre de chaudière(s) à raccorder au conduit pour des ménages classiques',
    type_conduit: "L'opération concerne la mise en place d’un (de) conduit(s)",
    type_remplacement:
      'Le conduit collectif vient en remplacement d’un ou plusieurs conduits de fumée collectifs de type',
    longueur_conduit:
      'Longueur du conduit individuel d’évacuation des produits de combustion (en m)',
    type_conduit_collectif:
      'L’installation des conduits individuels d’évacuation est réalisée en réutilisant un conduit collectif de type',
    remplacement_conduit_evacuation:
      "Remplacement d'un conduit d’évacuation des produits de combustion incompatible avec des chaudières individuelles au gaz à condensation",
  },
  'BAR-SE-104': {
    nombre: "Nombre d'appartements",
  },
  'BAR-SE-109': {
    nombre: 'Nombre de logements',
    installation: 'L’installation de chauffage collectif est alimentée par',
  },
  'BAR-TH-102': {
    besoin: 'Besoin annuel en eau chaude sanitaire (Kwh / an)',
    production: 'Production solaire utile (Kwh / an)',
  },
  'BAR-TH-135': {
    besoin:
      "Besoin annuel en eau chaude sanitaire à produire par l'énergie solaire exprimé en kWh par an",
    taux: "Taux de couverture par l'énergie solaire de l'installation (en %)",
    type: 'Type de logement',
    chauffage: 'Chauffe-eau collectifs à',
    zone: 'Zone Géographique',
  },
  'BAR-TH-155': {
    nombre: "Nombre d'appartement",
    ventilation: "Installation d'une ventilation hybride de type",
    caisson: 'Caisson',
  },
  'BAR-TH-145': {
    surface: 'Surface habitable du bâtiment résidentiel après rénovation (m²)',
    CefInitial:
      'Consommation conventionnelle en énergie finale avant les travaux de rénovation (kW/m².an)',
    CefProjet:
      'Consommation conventionnelle en énergie finale après les travaux de rénovation (kW/m².an)',
    conso_primaire_av_travaux:
      'Consommation conventionnelle en énergie primaire avant les travaux de rénovation (Cep initial) (kWh/m².an)',
    conso_primaire_apr_travaux:
      'Consommation conventionnelle en énergie primaire après les travaux de rénovation (Cep projet) (kWh/m².an)',
    type_travaux: 'Types de travaux envisagés',
    pourcentage_energie_renouvelable: "Les travaux conduisent à l'utilisation",
    nombre_logements: 'Nombre de logements',
    date_livraison: 'Date de livraison envisagée',
  },
  'RES-CH-103': {
    secteuractivite: "Secteur d'activité",
    surfacechauffee: 'Surface chauffée (m²)',
  },
  'RES-EC-101': {
    puissanceEclairage: 'Puissance installée de l’éclairage régulé',
  },
  'RES-EC-107': {
    nbHorloges: 'Nombre d’horloges installées',
  },
  'RES-EC-104': {
    typeInstallation: "type d'installation",
    nombreLumiere: 'Nombre de luminaires installés',
  },
  'RES-EC-103': {
    puissanceEclairage: 'Puissance installée de l’éclairage régulé',
  },
  'RES-EC-102': {
    puissanceEclairage: 'Puissance installée de l’éclairage régulé',
  },
  'RES-CH-104': {
    nbappartement: "Nombre d'appartements",
  },
  'TRA-EQ-121': {
    nombrecycles: 'Nombre de cycles neufs acquis',
  },
  'TRA-EQ-122': {
    nombreengin:
      "Nombre d'engins automoteurs non routiers concernés par l'opération",
  },
  'TRA-EQ-113': {
    volumentlubrifiant:
      'Volume de lubrifiants économiseurs d’énergie concernés par l’opération (en m³)',
    performancelubrifiant: 'Performance du lubrifiant (%)',
  },
  'TRA-EQ-104': {
    lubrifiants: 'Types de lubrifiants pour véhicules de catégorie M1',
    volumelubrifiant:
      'Volume de lubrifiants économiseurs d’énergie concernés par l’opération (en litre)',
    performancelubrifiant: 'Performance du lubrifiant (%)',
  },
  'TRA-EQ-101': {
    longueur: "Longueur de l'unité de transport intermodal",
    poidstotal: "Poids total en charge (PTC) de l'UTI",
    nbvoyages: 'Nombre total de voyages moyen sur un an',
  },
  'TRA-EQ-103': {
    categorievehicule: 'Catégorie des véhicules',
    nbvehicules: 'Nombre de véhicules équipés par catégorie',
  },
  'TRA-EQ-115': {
    Nombre_Vehicule: 'Nombre de véhicules neufs optimisés',
  },
  'TRA-EQ-114': {
    Nombre_Vehicule: 'Nombre de véhicules neufs optimisés',
    Moyenne_Emission_CO2: 'Moyenne Emission CO2',
  },
  'TRA-EQ-117': {
    Declaration: "Type d'opération",
    Nombre_Vehicule: 'Nombre de véhicules neufs optimisés',
    Moyenne_Emission_CO2: 'Moyenne Emission CO2',
    Emission_CO2: 'Emission CO2',
  },
  'BAR-EN-109': {
    type: 'Type de logement',
    surface: 'Surface de toiture protégée (en m²)',
    facteursolaire: 'Facteur solaire',
  },
  'BAT-EN-109': {
    type: 'Type de logement',
    secteur: "Secteur d'activité",
    surface: 'Surface de toiture protégée (en m²)',
    surfaceBatiment: 'Surface totale du bâtiment (en m²)',
    facteursolaire: 'Facteur solaire',
  },
  'BAR-TH-166': {
    nombre_appartements: "Nombre d'appartements",
    usage: 'Utilisation de la PAC',
    type_pac: 'Conception de la PAC',
    etas: 'Efficacité énergétique saisonnière (Etas en %)',
    nombre_pacs: 'Nombre des PAC de la chaufferie',
    puissance_totale: 'Puissance totale de la chaufferie après travaux',
    pacs: 'Puissance pour chaque PAC',
    chaudiere: 'Chaudières collectives à haute performance énergétique',
    pac_absorption: 'PAC collectives à absorption de type air/eau ou eau/eau',
    type_pac2: 'Type de la PAC',
    equipement: "Type d'équipement",
  },
  'BAR-TH-150': {
    nombre_appartements: "Nombre d'appartements",
    usage: 'Utilisation de la PAC',
    puissance_pac: 'Puissance de la PAC installée (en kw)',
    type_pac: 'Type de PAC',
    etas: 'Efficacité énergétique saisonnière (Etas en %)',
    cop: 'Coefficient de performance (COP)',
    puissance_totale: 'Puissance totale de la chaufferie après travaux',
    chaudiere: 'Chaudières collectives à haute performance énergétique',
    equipement: "Type d'équipement",
  },
  'BAT-EQ-134': {
    classe_energetique: 'Classe énergétique du meuble installé',
    type_meuble: 'Type de meuble frigorifique de vente installé',
    longueur_totale:
      'Longueur totale de meubles frigorifiques de vente installés (en m)',
  },
  'BAT-TH-159': {
    secteur_activite: "Secteur d'activité",
    puissance_thermique: 'Puissance thermique souscrite (en kW)',
  },
  'BAT-TH-160': {
    surface:
      'Surface chauffée ou refroidie par les unités terminales concernées par l’opération (en m²)',
    secteur: "Secteur d'activité",
    typeUnite: 'Type d’unité terminale (eau/air)',
    classeSyeteme: 'Classe du système de gestion technique du bâtiment',
  },
  'BAT-EN-111': {
    surface_fenetre:
      'Surface de fenêtres ou portes-fenêtres complètes pariétodynamiques posées (m²)',
    secteur: 'Secteur',
    locaux:
      'Les locaux dans lesquels sont mises en place une ou plusieurs fenêtres ou portes-fenêtres pariétodynamiques sont équipés d’un système de ventilation mécanique de type',
  },
  'BAT-TH-158': {
    surface: 'Surface totale chauffée par la PAC (m²)',
    secteur: 'Secteur d’activité',
    puissance_pac: 'Puissance de la PAC (en kW)',
    type_pac: 'Type de PAC',
    etas: 'Efficacité énergétique saisonnière (Etas) pour le chauffage',
    etas2: 'Efficacité énergétique saisonnière (Etas) pour le refroidissement',
    scop: 'Coefficient de performance saisonnier (SCOP)',
    seer: 'Efficacité énergétique saisonnière (SEER)',
  },
  'BAR-TH-168': {
    surface: 'Surface hors-tout de capteurs solaires mis en place (m²)',
    usage: 'Usage',
  },
  'BAR-TH-164': {
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: "Revenu(s) du ménage de l'an dernier",
    cons_conv_av_travaux:
      'Consommation conventionnelle d’énergie finale avant travaux (Cef initial) (en kWh/m².an)',
    cons_conv_apr_travaux:
      'Consommation conventionnelle d’énergie finale après travaux (Cef projet) (en kWh/m².an)',
    cons_prm_av_travaux:
      'Consommation conventionnelle d’énergie primaire avant travaux (en kWh/m².an)',
    cons_prm_apr_travaux:
      'Consommation conventionnelle d’énergie primaire après travaux (en kWh/m².an)',
    surface: 'Surface habitable de la maison rénovée (en m²)',
    certification_install_chauffage:
      'L’opération ne conduit pas à l’installation d’équipement de chauffage ou de production d’ECS fonctionnant majoritairement au fioul, charbon ou gaz',
    travaux_iso_murs:
      'Travaux d’isolation thermique des murs couvrant au moins 75% de la surface totale des murs donnant sur l’extérieur (ITI ou ITE)',
    travaux_iso_toitures:
      'Travaux d’isolation thermique des toitures (toiture-terrasse ou rampant de toiture) couvrant au moins 75% de la surface totale des toitures',
    travaux_iso_planchers:
      'Travaux d’isolation thermique des planchers, des combles perdus ou des planchers bas couvrant au moins 75% de la surface totale des planchers situés entre un volume chauffé et un volume non chauffé',
  },
  'BAR-EQ-110': {
    nombre_luminaires: 'Nombre de luminaires installés',
    indice_protection: 'Indice de protection aux chocs (IK)',
    moyen_detection: 'Moyens de détection',
  },
  'AGRI-UT-102': {
    application: 'Application',
    puissance: 'Puissance nominale du moteur en kW',
  },
  'AGRI-TH-117': {
    surface: 'Surface de serre équipée (m²)',
  },
  'AGRI-EQ-102': {
    typeInstallation: "Type d'installation",
    typeSerre: 'Le type de serres',
    surfaceSerreChauffee: 'Surface de serre chauffée (m²)',
    dispositionPilotageEcran:
      'Les écrans thermiques sont installés au-dessus des cultures et pilotés automatiquement',
  },
  'AGRI-EQ-109': {
    typeCouverture: 'La mise en place d’une serre avec couverture',
    typeSerre: 'Le type de serre',
    surfaceSerreIsolee: 'La surface de la serre (m²)',
    coeffThermique: 'Le coefficient de transmission thermique (W/m².K)',
    coeffLumineuse: 'Le coefficient de transmission lumineuse (%)',
  },
  'BAT-EQ-133': {
    type_equipement: "Type d'équipement",
    classe: "Classe d'équipement",
    secteur: "Secteur d'activité",
    nombre: 'Nombre de systèmes mis en place',
  },
  'BAT-EN-112': {
    surface: 'Surface de toiture couverte par un revêtement réflectif',
  },
  'BAT-TH-135': {
    puissance:
      'Puissance électrique nominale totale du groupe de production de froid en kW',
    application: 'Application du groupe de production de froid',
  },
  'BAT-TH-154': {
    efficacite: 'Efficacité du récupérateur (ε) en %',
    installation: "Mode d'installation du système de récupération",
    usage: 'Usage du bâtiment',
    nombreChambres: 'Nombre de chambres équipées',
    nombreDouches: 'Nombre de Douches équipées',
    nombreSalons: 'Nombre de salons équipées',
    nombreBaigneurs: 'Nombre de baigneurs par an',
    usageInegaux: 'Usage du bâtiment',
  },
  'BAR-TH-116': {
    typelogement: 'Type de logement',
    surfchauffee: 'Surface chauffée en m²',
    datebat:
      "Bâtiment résidentiel existant depuis plus de 2 ans à la date d'engagement de l'opération",
  },
  'BAR-TH-165': {
    puissanceChaudiere: 'Puissance de la chaudière',
    qtedechaleur: 'Quantité de chaleur nette utile produite par la chaudière',
    equipement: "Type d'équipement",
  },
  'BAR-TH-104': {
    secteur: 'Type de logement',
    etas: 'Efficacité énergétique saisonnière ηS (en %)',
    surface: 'Surface chauffée en m²',
    type_pac: 'Type de PAC',
    conception: 'Conception de PAC',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement",
    cuve_fioul: 'Dépose d’une cuve à fioul',
  },
  'BAR-TH-171': {
    secteur: 'Type de logement',
    etas: 'Efficacité énergétique saisonnière ηS (en %)',
    surface: 'Surface chauffée en m²',
    usage: 'Utilisation de PAC',
    conception: 'Conception de PAC',
    classe_regulateur: 'Classe du régulateur',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement remplacé",
    cuve_fioul: 'Dépose d’une cuve à fioul',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-172': {
    etas: 'Efficacité énergétique saisonnière ηS (en %)',
    surface: 'Surface chauffée en m²',
    usage: 'Utilisation de PAC',
    conception: 'Conception de PAC',
    classe_regulateur: 'Classe du régulateur',
    nombre_personnes_foyer: 'Nombre de personnes dans le foyer',
    revenus_menage: 'Revenus du ménage',
    equipement: "Type d'équipement remplacé",
    cuve_fioul: 'Dépose d’une cuve à fioul',
    etiquette_energetique:
      "Étiquette énergétique issue d'un Diagnostic de Performance Énergétique récent",
  },
  'BAR-TH-173': {
    secteur: 'Type de logement',
    surface: 'Surface chauffée en m²',
    classe_regulation: 'Classe du système de régulation',
    type_emetteur: "Type d'émetteur",
    operation: "Type d'opération",
    recup_temps_reel:
      'Le système récupère en temps réel le signal EcoWatt ou EcoGaz',
  },
  'BAR-TH-106': {
    nombre: "Nombre d'appartements",
    type: 'Type de logement',
    surface: 'Surface habitable en m²',
    puissance: 'Puissance thermique nominale de la chaudière',
    classe_regulateur: 'Classe du régulateur',
  },
  'BAT-EQ-123': {
    application: 'Application',
    puissance: 'Puissance électrique nominale du moto-variateur en kW',
  },
  'BAT-TH-112': {
    application: 'Application',
    puissance: 'Puissance électrique nominale du moto-variateur en kW',
    classemoteur: 'Classe du moteur',
  },
  'BAT-EQ-127': {
    secteur: 'Secteur des travaux',
    puissance_totale:
      'Puissance totale des luminaires à modules LED installés (P en watt)',
    irc: 'Indice de rendu des couleurs (IRC)',
    efficacite_lumineuse: 'Efficacité lumineuse (lm/W)',
  },
  'BAT-TH-125': {
    secteur: 'Secteur',
    type: "Installation d'une ventilation mécanique simple flux",
    surface: 'Surface ventilée (en m2)',
  },
  'BAT-TH-126': {
    ventilation: 'Type de ventilation',
    secteur: 'Secteur',
    surface: 'Surface ventilée (en m2)',
  },
  'BAT-TH-142': {
    local: "Installation de déstratificateurs ou brasseurs d'air dans",
    chauffage: 'Le local est chauffé par un système',
    hauteur: 'Hauteur sous plafond ou sous faîtage du local (en m)',
    puissanceRadiatif:
      'Puissance nominale du système de chauffage radiatif du local (en kW)',
    puissanceConvectif:
      'Puissance nominale du système de chauffage convectif du local (en kW)',
    systeme: "Le système de déstratification d'air est à écoulement",
  },
  'BAT-EN-101': {
    secteur: 'Secteur',
    surface: 'Surface (en m2)',
    energie: 'Energie de chauffage',
  },
  'BAT-EN-102': {
    secteur: 'Secteur',
    surface: 'Surface (en m2)',
    energie: 'Energie de chauffage',
    resistance: 'Résistance thermique (m².K/W)',
  },
  'BAT-EN-103': {
    secteur: 'Secteur',
    surface: 'Surface (en m2)',
  },
  'BAT-EN-104': {
    secteur: 'Secteur',
    surface: 'Surface (en m2)',
    coefficient: 'Coefficient de transmission surfacique Uw',
  },
  'BAT-TH-146': {
    longueur:
      "Longueur isolée de réseau de chauffage ou d'ECS hors du volume chauffé (en m)",
  },
  'BAT-TH-111': {
    besoin: 'Besoin annuel en eau chaude sanitaire (Kwh / an)',
    production: 'Production solaire utile (Kwh / an)',
  },
  'BAT-EN-106': {
    surface: "Surface d'isolant posé (m²)",
    secteur: 'Secteur',
    type: 'Type de construction',
  },
  'BAT-EN-108': {
    surface: "Surface d'isolant posé (m²)",
    secteur: 'Secteur',
    type: 'Type de construction',
  },
  'BAT-TH-121': {
    type: 'Type de chauffage',
    besoin: 'Besoin annuel en eau chaude sanitaire (en kwh)',
    taux: "Taux de couverture par l'énergie solaire de l'installation (en %)",
  },
  'BAT-TH-116': {
    classe: 'Classe',
    secteur: 'Secteur',
    operation: "Nature de l'opération",
    surface: 'Surface',
    usage: 'Usage',
    hasChauffage: 'Chauffage',
    surfaceChauffage: 'Surface de chauffage (m²)',
    hasRefroidissement: 'Refroidissement Climatisation',
    surfaceRefroidissement: 'Surface de refroidissement climatisation (m²)',
    hasECS: 'ECS',
    surfaceECS: "Surface d'ECS (m²)",
    hasEclairage: 'Eclairage',
    surfaceEclairage: "Surface d'éclairage (m²)",
    hasAuxiliaire: 'Auxiliaire',
    surfaceAuxiliaire: "Surface d'auxiliaire (m²)",
  },
  'BAT-TH-127': {
    surface: 'Surface chauffée (m²)',
    secteur: 'Secteur',
    usage_chaleur: 'Usage de la chaleur du réseau',
    puissance: 'Puissance souscrite (kW)',
    alimentation_reseau: 'Alimentation du réseau de chaleur',
    equipement: "Type d'équipement",
  },
  'BAT-TH-157': {
    puissance: 'Puissance nominale de la chaudière (kW)',
    quantite:
      'Quantité de chaleur nette utile produite par la chaudière biomasse installée en Kwh/an',
    equipement: "Type d'équipement",
    secteur: "Secteur d'activité",
    surface: 'Surface totale chauffée (m²)',
  },
  'BAT-TH-134': {
    application: 'Application du groupe de production de froid',
    puissance:
      'Puissance électrique nominale totale du groupe de production de froid en kW',
    condensation: 'Système de condensation',
  },
  'BAT-TH-139': {
    mode: "Durée annuelle d'utilisation de la chaleur récupérée D en heures",
    puissanceRecuperee:
      'Puissance thermique du système de récupération de chaleur en kW',
    puissanceDejaRecuperee:
      'Puissance thermique déjà récupérée sur le groupe de production de froid en kW',
    puissanceCompresseur:
      'Somme des puissances électriques nominales indiquées sur les plaques du ou des compresseur(s) raccordé(s) au système de récupération de chaleur en kW',
  },
  'BAT-TH-143': {
    secteur: "Secteur de l'application",
    surfaceChauffee: 'Surface totale chauffée (m²)',
    surfaceRafraichie: 'Surface totale rafraîchie (m²)',
  },
  'BAT-TH-145': {
    application: 'Application du groupe de production de froid',
    puissance:
      'Puissance électrique nominale totale du groupe de production de froid en kW',
  },
  'BAT-EQ-117': {
    type: "Type de l'installation frigorifique",
    puissanceNegative: 'Puissance frigorifique négative installée en kW',
    puissancePositive: 'Puissance frigorifique positive installée en kW',
  },
  'BAT-EQ-130': {
    type: 'Type de bâtiment',
    application: 'Application du groupe de production de froid',
    puissance:
      'Puissance électrique nominale totale du groupe de production de froid en kW',
    condenseur: 'Type de système de condensation frigorifique',
    Delta_T_C: 'Différence de température',
    differenceEauSeul: 'Différence de température (Condenseur à eau seul)',
    differenceAirSec: 'Différence de température (Condenseur à air sec)',
    differenceEvaporatif: 'Différence de température (Condenseur évaporatif)',
  },

  'BAT-TH-113': {
    surface: 'Surface totale chauffée (m²)',
    secteur: 'Secteur',
    pac: 'Puissance thermique de la PAC installée',
    cop: 'Coefficient de performance',
    type_pac: 'Conception de la PAC',
    efficacite: 'Efficacité énergétique saisonnière (en %)',
    type_pac2: 'Type de la PAC',
    equipement: "Type d'équipement",
  },
  'BAT-SE-103': {
    surface: 'Surface totale chauffée (m²)',
  },
  'BAT-SE-105': {
    surface: 'Surface chauffée (m²)',
    secteur: "Secteur d'activité",
  },
  'BAT-TH-104': {
    surface: 'Surface chauffée (m²)',
    secteur: 'Secteur',
  },
  'BAT-TH-105': {
    surface: 'Surface chauffée (m²)',
    secteur: 'Secteur',
  },
  'BAT-TH-109': {
    surface: 'Surface chauffée (m²)',
    secteur: 'Secteur',
  },
  'TRA-EQ-129': {
    type_operation: "Type d'opération",
    type_vehicule: 'Type de véhicules',
    certif_location:
      'La durée de location, hors reconduction tacite, est supérieure ou égale à soixante mois',
    certif_agglomeration:
      'Les véhicules spécialisés de type bennes à ordures ménagères, acquis ou loués dans le cadre de cette opération, sont destinés à desservir des communes situées dans une agglomération de plus de 250 000 habitants',
    nombre_vehicule: 'Nombre de véhicules',
  },
};
