<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="element-details-title">
            {{ beneficiaire.prenom + " " + beneficiaire.nom }}
          </h2>
          <nz-tag class="timestamp" [nzBordered]="true">
            <span nz-icon nzType="clock-circle"></span>
            <span
              >Créé :
              {{ beneficiaire.createdAt | date : "dd/MM/YYYY HH:mm" }}</span
            >
          </nz-tag>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col *ngIf="canEdit('BENEFICIAIRES')">
            <button
              nz-button
              nzType="default"
              class="secondary-button margin-left-10"
              routerLink="/beneficiaires/edit/{{ beneficiaire.id }}"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4>
      <span nz-icon nzType="solution" nzTheme="outline"></span> Détails du
      bénéficiaire
    </h4>
    <div nz-row class="details-container">
      <nz-card class="box">
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Projet</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            <a routerLink="/projets/{{ beneficiaire.projet.id }}">{{
              beneficiaire.projet.numeroDossier +
                " - " +
                beneficiaire.projet.name
            }}</a>
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Type Bénéficiaire
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ beneficiaire.type }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Civilité</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.civilite }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Nom</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ beneficiaire.nom }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Prénom</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ beneficiaire.prenom }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Fonction</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.fonction }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Téléphone</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.telephone.trim() | phoneNumber }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Email</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ beneficiaire.email }}</div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Raison sociale</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.raison_sociale }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">SIREN</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.siren.trim() | siren }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse des travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.adresse }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse de la résidence principale
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.adresse_facture }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse du siège social
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.adresseSiege }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Remise sur devis/facture CEE
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.remisSurDevisFacture }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Remise sur devis/facture MPR
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.remisSurDevisMPR }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Entreprise réalisant les travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.apporteur_affaire }}
          </div>
        </div>
        <div nz-row class="element-detail-row" *ngIf="beneficiaire.apporteur_affaire_siret !== ''">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            SIRET de l'entreprise réalisant les travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.apporteur_affaire_siret }}
          </div>
        </div>
        <div nz-row class="element-detail-row" *ngIf="beneficiaire.apporteur_affaire_nom_signataire !== ''">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Nom du signataire de l'entreprise de travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.apporteur_affaire_nom_signataire }}
          </div>
        </div>
        <div nz-row class="element-detail-row" *ngIf="beneficiaire.apporteur_affaire_prenom_signataire !== ''">
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Prénom du signataire de l'entreprise de travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.apporteur_affaire_prenom_signataire }}
          </div>
        </div>
        <div nz-row class="element-detail-row">
          <div nz-col [nzSpan]="8" class="field-grey-label">Commentaire</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ beneficiaire.commentaire }}
          </div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
