import { Component, HostListener } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { BeneficiairesService } from 'src/app/services/beneficiaires.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-list-beneficiaires',
  templateUrl: './list-beneficiaires.component.html',
  styleUrl: './list-beneficiaires.component.css',
})
export class ListBeneficiairesComponent {
  beneficiaires: any[] = [];
  pageIndex = 1;
  checked_beneficiaires = new Set<number>();
  clients: any[] = [];
  societes: any[] = [];
  gestionnaires: any[] = [];
  pageSize = 100;
  checked = false;
  indeterminate = false;
  total_beneficiaires: number = 0;
  isLoadingData = true;
  nzShowArrow = true;
  selected_types = [];
  selected_clients = [];
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  toggleFilters = false;
  isDesktop: boolean = true;

  constructor(
    private fb: NonNullableFormBuilder,
    private beneficiairesService: BeneficiairesService,
    private readonly authService: AuthService,
    private neoscopeService: NeoscopeService
  ) {}
  ngOnInit(): void {
    const stringControlNames = ['source', 'type', 'search_text'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    this.beneficiairesService
      .getBeneficiairesWithFilter(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.beneficiaires = data.rows;
        console.log(this.beneficiaires);
      });

    this.beneficiairesService
      .getBeneficiairesWithFilter(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.beneficiaires = data.rows.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
    this.neoscopeService
      .getClientsNeoscopeByIDs(this.authService.getUserClientsAccess())
      .subscribe((res: any) => {
        this.clients = res;
      });

    this.checkViewport();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewport();
  }

  private checkViewport() {
    this.isDesktop = window.innerWidth > 768;
  }
  listOfColumn = [
    { title: 'Nom Complet', width: '110px' },
    { title: 'Raison sociale', width: '170px' },
    { title: 'Téléphone', width: '120px' },
    { title: 'Email', width: '170px' },
    { title: 'Adresse des traveaux', width: '320px' },
    { title: 'Client', width: '100px' },
  ];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_beneficiaires.add(id);
    } else {
      this.checked_beneficiaires.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.beneficiaires.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.beneficiaires.every((item) =>
      this.checked_beneficiaires.has(item.id)
    );
    this.indeterminate =
      this.beneficiaires.some((item) =>
        this.checked_beneficiaires.has(item.id)
      ) && !this.checked;
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.beneficiairesService
      .getBeneficiairesWithFilter(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.beneficiaires = data.rows;
        this.total_beneficiaires = data.count;
        this.isLoadingData = false;
      });
  }

  queryBeneficiaires() {
    this.isLoadingData = true;
    this.beneficiairesService
      .getBeneficiairesWithFilter(
        this.pageIndex,
        this.pageSize,
        this.queryForm.value
      )
      .subscribe((data: any) => {
        this.beneficiaires = data.rows;
        this.total_beneficiaires = data.count;
        this.isLoadingData = false;
      });
  }

  async confirmDelete(): Promise<void> {
    for (const user of this.checked_beneficiaires) {
      await this.beneficiairesService.delete(user);
    }
    this.checked_beneficiaires.clear();
    this.queryBeneficiaires();
  }
  resetForm(): void {
    this.queryForm.reset();
    this.selected_types = [];
  }
  cancel(): void {}

  exporter() {
    const date = new Date();

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}_${hour}-${minute}`;
    if (this.checked) {
      this.beneficiairesService
        .exportAllBeneficiaires(this.queryForm.value)
        .subscribe((x) => {
          importedSaveAs(x, 'Export_Bénéficiaires_' + formattedDate + '.xlsx');
        });
    } else {
      this.beneficiairesService
        .exportBeneficiaires(Array.from(this.checked_beneficiaires))
        .subscribe((x) => {
          importedSaveAs(x, 'Export_Bénéficiaires_' + formattedDate + '.xlsx');
        });
    }
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
