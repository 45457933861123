<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="projet-title">
            {{ admin.first_name }} {{ admin.last_name }}
          </h2>
          <nz-tag [nzColor]="'yellow'" [nzBordered]="false" class="status-tag">
            <span nz-icon nzType="admin" nzTheme="outline"></span>
            <span> Administrateur </span>
          </nz-tag>
          <nz-tag
            [nzColor]="getStatus().color"
            [nzBordered]="false"
            class="status-tag"
          >
            <span nz-icon nzType="tag" nzTheme="outline"></span>
            <span>{{ getStatus().text }}</span>
          </nz-tag>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col class="margin-right" *ngIf="canDelete('ADMINISTRATEURS')">
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer définitivement cet admin ?"
              nzType="default"
              class="secondary-button mass-action-button"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              [nzIcon]="popConfirmIcon"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
          </div>
          <div nz-col *ngIf="canEdit('ADMINISTRATEURS')">
            <button
              nz-button
              nzType="default"
              class="secondary-button"
              routerLink="/administrateurs/edit/{{ admin.id }}"
            >
              <span nz-icon nzType="edit"></span>
              <span class="action-text">Éditer</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div nz-row class="details-container">
        <nz-card class="box">
          <h4 class="info-card-title">
            <span nz-icon nzType="info-circle" nzTheme="outline"></span> Détails d'admin
          </h4>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">
                Nom d'utilisateur
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">{{ admin.username }}</div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Email</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ admin.email }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Rôle</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ admin.role }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">ID CRM</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ admin.id_crm }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Origine</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              {{ client.name }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="8" class="field-grey-label">Accès clients</div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="14" class="wrap">
              <span *ngFor="let client of accessibleClients; let i = index">
                {{ client.name }}<span *ngIf="i < accessibleClients.length - 1">, </span>
              </span>
            </div>
          </div>
        </nz-card>
      </div>
  </div>
  <ng-template #checkedTemplate
    ><span nz-icon nzType="check"></span
  ></ng-template>
  <ng-template #unCheckedTemplate
    ><span nz-icon nzType="close"></span
  ></ng-template>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
