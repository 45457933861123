<nz-content>
  <div class="inner-content">
    <div nz-row nzAlign="middle">
      <div nz-col nzSpan="22">
        <h2 class="categorie-title">
          {{ categorie_prix?.code }} - {{ categorie_prix?.nom }}
        </h2>
        <nz-tag class="timestamp timestamp-tag" [nzBordered]="true">
          <span nz-icon nzType="clock-circle"></span>
          <span
            >Modifié :
            {{ categorie_prix?.date | date : "dd/MM/YYYY HH:mm" }}</span
          ></nz-tag
        >
        <nz-tag class="timestamp-tag" [nzBordered]="true" [nzColor]="'green'">
          <span nz-icon nzType="tags"></span>
          <span>{{ totale_societe }} sociétés</span></nz-tag
        >
      </div>
      <div nz-col nzSpan="2" class="align-text-right" *ngIf="canEdit('PRIX')">
        <button
          nz-button
          nzType="default"
          class="secondary-button"
          routerLink="/categories-prix/edit/{{ categorie_prix?.code }}"
        >
          <span nz-icon nzType="edit"></span>
          Éditer
        </button>
      </div>
    </div>
    <div class="margin-top-20" nz-row>
      <div nz-col nzSpan="24">
        <nz-card class="box">
          <h3>Tranches de prix</h3>
          <nz-steps
            class="margin-top-20"
            [nzCurrent]="categorie_prix?.tranches.length + 1"
            nzSize="default"
          >
            <nz-step
              nzIcon="right-circle"
              id="tranche-prix"
              class="tranche-prix"
              *ngFor="let tranche of categorie_prix.tranches"
              [nzTitle]="tranche.volume_min + ' Gwh'"
              [nzDescription]="tranche.prix + ' €'"
            ></nz-step>
            <nz-step nzIcon="right-circle" nzTitle="+∞ Gwh"></nz-step>
          </nz-steps>
        </nz-card>
      </div>
    </div>
    <div class="margin-top-20" nz-row nzGutter="22">
      <div nz-col nzSpan="14">
        <nz-card class="box">
          <h3>Prix Coup de pouce</h3>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Chauffage résidentiel individuel Classique (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{ categorie_prix?.prix_cdp | number : "1.2-2" : "fr" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Chauffage résidentiel individuel Précaire (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{ categorie_prix?.prix_precaire | number : "1.2-2" : "fr" }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Chauffage résidentiel individuel Précaire Boosté (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{
                categorie_prix?.prix_cdp_chauffage_precaire_boosted
                  | number : "1.2-2" : "fr"
              }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Chauffage résidentiel individuel Modeste Boosté (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{
                categorie_prix?.prix_cdp_chauffage_modeste_boosted
                  | number : "1.2-2" : "fr"
              }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Chauffage résidentiel individuel Classique Boosté (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{
                categorie_prix?.prix_cdp_chauffage_classique_boosted
                  | number : "1.2-2" : "fr"
              }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Rénovation globale collectif (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{
                categorie_prix?.prix_cdp_reno_globale_collectif
                  | number : "1.2-2" : "fr"
              }}
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzSpan]="19" class="field-grey-label">
              Pilotage connecté (€)
            </div>
            <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
            <div nz-col [nzSpan]="4" class="align-text-right">
              {{
                categorie_prix?.prix_cdp_pilotage_connecte
                  | number : "1.2-2" : "fr"
              }}
            </div>
          </div>
        </nz-card>
      </div>
      <div nz-col nzSpan="10">
        <nz-card class="box">
          <h3>Rémunérations partenaires possibles</h3>
          <br />
          <div nz-row nzJustify="center">
            <span *ngFor="let rem of categorie_prix?.renumerations">
              <nz-tag [nzColor]="'green'" [nz-tooltip]="rem.block_auto_cee" [nzTooltipTitle]="rem.block_auto_cee ? 'Offre automatique bloquée pour ce pourcentage' : null"
                >{{ rem.value }} % 
                <span *ngIf="rem.block_auto_cee" nz-icon nzType="exclamation-circle" nzTheme="outline"></span>
                </nz-tag
              >
            </span>
          </div>
          <br />
        </nz-card>
      </div>
    </div>
  </div>
</nz-content>
