import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrl: './create-admin.component.css',
})
export class CreateAdminComponent {
  createForm: FormGroup;
  isFormModified: boolean;
  passwordVisible = false;
  confirmpasswordVisible = false;
  clients: any[];
  clients_access: any[];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminsService,
    private message: NzMessageService,
    private router: Router,
    private neoscopeService: NeoscopeService
  ) {}

  ngOnInit(): void {
    this.createForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        id_crm: [''],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        role: ['', Validators.required],
        client_id: ['', Validators.required],
        clients_access: [[], Validators.required],
      },
      { validator: this.checkPasswords }
    );

    this.createForm.valueChanges.subscribe(() => {
      if (this.createForm.hasError('notSame')) {
        this.createForm.get('confirmPassword')?.setErrors({ notSame: true });
      } else {
        this.createForm.get('confirmPassword')?.setErrors(null);
      }
    });

    this.neoscopeService
      .getAllClientsNeoscope(1, 100, {})
      .subscribe((res: any) => {
        this.clients = res.rows;
      });
  }

  createBasicMessage(): void {
    this.message.success('La création est faite avec succès', {
      nzDuration: 3000,
    });
  }

  createAdmin(): void {
    if (this.createForm.valid) {
      const payload = { ...this.createForm.value, is_active: false };
      delete payload.confirmPassword;
      this.adminService.createAdmin(payload).subscribe((newAdmin: any) => {
        this.createBasicMessage();
        this.router.navigate(['/administrateurs']);
      });
    } else {
      console.log('Form is invalid');
      Object.values(this.createForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }

  goBack(): void {
    this.router.navigate(['/administrateurs']);
  }

  private checkPasswords(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { notSame: true };
  }
}
