import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminsService } from 'src/app/services/admins.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrl: './edit-admin.component.css',
})
export class EditAdminComponent {
  editForm: FormGroup;
  admin: any;
  admin_id: number;
  isFormModified: boolean;
  clients: any[];
  clients_access: any[];
  isFormSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private adminService: AdminsService,
    private message: NzMessageService,
    private router: Router,
    private neoscopeService: NeoscopeService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.editForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      role: ['', Validators.required],
      twoFactorAuthenticationEnabled: [false],
      is_active: [false],
      id_crm: [''],
      client_id: ['', Validators.required],
      clients_access: [[], Validators.required],
    });
    this.admin_id = this.route.snapshot.params['id'];
    this.adminService.getAdminById(this.admin_id).subscribe((admin: any) => {
      this.admin = admin;
      this.clients_access = JSON.parse(admin.clients_access);
      this.editForm.patchValue(admin);
      this.loadingService.offLoading();
    });

    this.neoscopeService
      .getAllClientsNeoscope(1, 100, {})
      .subscribe((res: any) => {
        this.clients = res.rows;
      });
  }

  createBasicMessage(): void {
    this.message.success('La modification est faite avec succès', {
      nzDuration: 3000,
    });
  }

  editAdmin(): void {
    if (this.editForm.valid) {
      const payload = { ...this.admin };
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });
      this.adminService
        .editAdmin(this.admin_id, payload)
        .subscribe((updatedAdmin: any) => {
          this.isFormSubmitting = true;
          this.admin = updatedAdmin;
          this.createBasicMessage();
          this.router.navigate(['/administrateurs']);
        });
    } else {
      Object.values(this.editForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    return !this.editForm.pristine;
  }

  goBack(): void {
    this.router.navigate(['/administrateurs']);
  }
}
