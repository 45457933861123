<div class="messages-container" #messageContainer>
  <div *ngFor="let item of messages; let i = index" [ngClass]="{
      'current-user-message': item.auteur === currentUser.username,
      'non-user-message': item.auteur !== currentUser.username,
      'different-user-next': i < messages.length - 1 && messages[i + 1].auteur !== item.auteur
    }" class="message">
    <ng-container *ngIf="item.type === 'separator'; else messageTemplate">
      <div class="message-separator">
        <span>{{ item.date | customDate }}</span>
      </div>
    </ng-container>
    <ng-template #messageTemplate>
      <div class="avatar" *ngIf="shouldDisplayAvatar(i)" (mouseenter)="showTooltip(item.auteur)" (mouseleave)="hideTooltip()">
        <ng-container *ngIf="item.isFromPartenaire; else defaultAvatar">
          <nz-avatar nzIcon="user" nzSrc="https://new.dev.neoscope.eu/assets/images/userMadame.png"></nz-avatar>
        </ng-container>
        <ng-template #defaultAvatar>
          <nz-avatar nzIcon="user" class="message-sender" [nzSrc]="item.avatarSrc"></nz-avatar>
        </ng-template>
        <div class="tooltip" *ngIf="tooltipVisible && tooltipText === item.auteur">
          {{ item.auteur }}
        </div>
      </div>
      <div class="message-body" [ngClass]="{ 'indented': !shouldDisplayAvatar(i) }">
        <div class="message-content">
          <div *ngIf="item.filename">
            <a (click)="download(item.filename)">{{ extractName(item.filename) }}</a>
          </div>
          <div class="message-text" [innerHTML]="item.contenu | linkify"></div>
        </div>
        <span class="message-time">{{ item.createdAt | customDate }}</span>
      </div>
    </ng-template>
  </div>
</div>