import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/services/auth.service';
import { AdminsService } from 'src/app/services/admins.service';
import { DocumentService } from 'src/app/services/document.service';
import { catchError, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css'],
})
export class MessageListComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @Input() messages: any[];
  loading: boolean = false;
  currentUser: any;
  private adminAvatarCache = new Map<number, string>();
  private avatarRequestInProgress = new Map<number, Observable<any>>(); 
  private processedMessageIds = new Set<number>();
  tooltipVisible: boolean = false;
  tooltipText: string = '';

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private AdminService: AdminsService,
    private DocumentService: DocumentService,
  ) { }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next: (res) => {
        this.currentUser = res;
      },
      error: (err) => {
        throw err;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['messages'] && this.messages.length > 0) {
      this.processAllMessages();
      this.groupMessagesByDate();
      this.scrollToBottom()
    }
  }

  ngOnDestroy() { }

  addNewMessage(newMessage: any) {
    this.messages.push(newMessage);
    this.processMessage(newMessage);
    this.groupMessagesByDate();
    this.scrollToBottom();
  }
  
  groupMessagesByDate() {
    if (!this.messages || this.messages.length === 0) return;
  
    const groupedMessages: any[] = [];
    let lastTimestamp: number | null = null;
  
    this.messages.forEach((message, index) => {
      const messageTimestamp = new Date(message.createdAt).getTime();
  
      if (lastTimestamp === null || (messageTimestamp - lastTimestamp) > 30 * 60 * 1000) {
        groupedMessages.push({ type: 'separator', date: new Date(messageTimestamp) });
      }
  
      groupedMessages.push(message);
      lastTimestamp = messageTimestamp;
    });
  
    this.messages = groupedMessages;
  }

  private processMessage(message: any) {
    if (message.admin_id && !this.processedMessageIds.has(message.id)) {
      this.processedMessageIds.add(message.id);
  
      if (this.adminAvatarCache.has(message.admin_id)) {
        message.avatarSrc = this.adminAvatarCache.get(message.admin_id);
        return; 
      }
      this.AdminService.getAdminById(message.admin_id).subscribe((admin: any) => {
        if (admin && admin.avatar) {
          this.DocumentService.previewDocumentByName(admin.avatar, 'avatar')
            .subscribe(
              (res: any) => {
                const blob = new Blob([res], { type: 'image/jpeg' });
                message.avatarSrc = URL.createObjectURL(blob);
              },
              (error) => {
                console.error('Failed to load avatar:', error);
                message.avatarSrc = null;
              }
            );
        } else {
          message.avatarSrc = null;
        }
      });
    }
  }
  
  private processAllMessages() {
    const avatarObservables: Observable<any>[] = this.messages
      .filter(message => message.admin_id && !this.processedMessageIds.has(message.id))
      .map(message => {
        if (this.adminAvatarCache.has(message.admin_id)) {
          return of({
            message,
            avatarUrl: this.adminAvatarCache.get(message.admin_id),
          });
        }
        if (this.avatarRequestInProgress.has(message.admin_id)) {
          return this.avatarRequestInProgress.get(message.admin_id)!.pipe(
            map(avatarUrl => ({ message, avatarUrl }))
          );
        }

        const avatarRequest$ = this.AdminService.getAdminById(message.admin_id).pipe(
          switchMap((admin: any) => {
            if (admin && admin.avatar) {
              return this.DocumentService.previewDocumentByName(admin.avatar, 'avatar').pipe(
                map(res => {
                  const blob = new Blob([res], { type: 'image/jpeg' });
                  const avatarUrl = URL.createObjectURL(blob);
                  this.adminAvatarCache.set(message.admin_id, avatarUrl); 
                  this.avatarRequestInProgress.delete(message.admin_id); 
                  return avatarUrl;
                }),
                catchError(() => {
                  this.avatarRequestInProgress.delete(message.admin_id);
                  return of(null);
                })
              );
            }
            this.avatarRequestInProgress.delete(message.admin_id); 
            return of(null);
          }),
          shareReplay(1) 
        );

        this.avatarRequestInProgress.set(message.admin_id, avatarRequest$);

        return avatarRequest$.pipe(
          map(avatarUrl => ({ message, avatarUrl }))
        );
      });

    forkJoin(avatarObservables).subscribe(results => {
      results.forEach(({ message, avatarUrl }) => {
        this.processedMessageIds.add(message.id);
        message.avatarSrc = avatarUrl;
      });
    });
    this.scrollToBottom()
  }

  showTooltip(auteur: string) {
    this.tooltipVisible = true;
    this.tooltipText = auteur;
  }

  hideTooltip() {
    this.tooltipVisible = false;
    this.tooltipText = '';
  }


  shouldDisplayAvatar(index: number): boolean {
    if (index === 0) {
      return true;
    }
    const currentMessage = this.messages[index];
    const previousMessage = this.messages[index - 1];
    return currentMessage.auteur !== previousMessage.auteur;
  }


  getMessageContentClass(message: any) {
    return message.auteur === this.currentUser.username
      ? 'current-user-message'
      : 'non-user-message';
  }

  extractName(file: any) {
    const filename = JSON.parse(file).filename;
    return filename;
  }

  download(file: any) {
    this.chatService
      .downloadFile(JSON.parse(file).s3filename)
      .subscribe((data) => {
        importedSaveAs(data, JSON.parse(file).filename);
      });
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Scroll to bottom failed', err);
      }
    }, 0);
  }
}