<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div nz-row class="align-items">
          <h2 class="beneficiary-title">
            {{ reglementaire.titre }}
          </h2>
          <nz-tag class="timestamp" [nzBordered]="true">
            <span nz-icon nzType="clock-circle"></span>
            <span
              >Créé :
              {{ reglementaire.createdAt | date : "dd/MM/YYYY HH:mm" }}</span
            >
          </nz-tag>
        </div>
      </div>
      <div nz-col nzSpan="8" class="action-buttons">
        <div nz-row nzJustify="end">
          <div nz-col class="margin-right" >
            <button
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?"
              nzType="default"
              class="secondary-button mass-action-button"
              (nzOnConfirm)="confirmDelete()"
              (nzOnCancel)="cancel()"
              *ngIf="canDelete('REGLEMENTAIRE')"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              nz-button
              nzType="default"
              class="secondary-button margin-left-10"
              routerLink="/reglementaire/edit/{{ reglementaire.numero }}"
              *ngIf="canEdit('REGLEMENTAIRE')"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <div nz-row class="details-container">
      <nz-card class="box">
        <h4 class="info-card-title">
          <span nz-icon nzType="info-circle" nzTheme="outline"></span> Détails
          du Reglementaire
        </h4>
        
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Version FOST
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ reglementaire.version_simulateur }}</div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Contrôle sur site</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            <nz-tag *ngIf="reglementaire.IsControlled" nzColor="success">
                <span>Oui</span>
              </nz-tag>
              <nz-tag *ngIf="!reglementaire.IsControlled" nzColor="error">
                <span>Non</span>
              </nz-tag>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Infobulle à afficher</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ reglementaire.infobulle }}</div>
        </div>
        <!-- <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Prénom</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.prenom }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Fonction</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.fonction }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Téléphone</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.telephone }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Email</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ reglementaire.email }}</div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Raison sociale</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.raison_sociale }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">SIREN</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">{{ reglementaire.siren }}</div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse des travaux
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.adresse }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse de la résidence principale
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.adresse_facture }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Adresse du siège social
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.adresseSiege }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Remise sur devis/facture CEE
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.remisSurDevisFacture }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">
            Remise sur devis/facture MPR
          </div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.remisSurDevisMPR }}
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="8" class="field-grey-label">Commentaire</div>
          <div nz-col [nzSpan]="1" class="field-grey-label">:</div>
          <div nz-col [nzSpan]="14" class="wrap">
            {{ reglementaire.commentaire }}
          </div>
        </div> -->
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon> </ng-template>
</nz-content>
