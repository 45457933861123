<nz-content>
  <div class="inner-content">
    <div nz-row>
      <div nz-col nzSpan="16">
        <div class="align-items" nz-row>
          <h2 class="element-details-title">
            {{ reglementaire.numero }} - {{ reglementaire.titre }}
          </h2>
        </div>
        <div nz-row class="margin-top-5">
          <nz-tag [nzBordered]="true" class="timestamp">
            <span nz-icon nzType="clock-circle"></span>
            <span
              >Créé : {{ reglementaire.createdAt | date : "dd/MM/YYYY" }}</span
            >
          </nz-tag>
          <nz-tag [nzBordered]="true" class="timestamp">
            <span nz-icon nzType="clock-circle"></span>
            <span>Fin : {{ reglementaire.ends_at | date : "dd/MM/YYYY" }}</span>
          </nz-tag>
        </div>
      </div>
      <div class="action-buttons" nz-col nzSpan="8">
        <div nz-row nzJustify="end">
          <div class="margin-right" nz-col>
            <button
              (nzOnCancel)="cancel()"
              (nzOnConfirm)="confirmDelete()"
              *ngIf="canDelete('REGLEMENTAIRE')"
              class="secondary-button mass-action-button"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?"
              nzType="default"
            >
              <span nz-icon nzType="delete"></span>
              <span class="action-text">Supprimer</span>
            </button>
            <button
              *ngIf="canEdit('REGLEMENTAIRE')"
              class="secondary-button margin-left-10"
              nz-button
              nzType="default"
              routerLink="/reglementaire/edit/{{ reglementaire.numero }}"
            >
              <span nz-icon nzType="edit"></span>
              Éditer
            </button>
          </div>
        </div>
      </div>
    </div>

    <nz-divider></nz-divider>

    <h4 class="info-card-title">
      <span nz-icon nzTheme="outline" nzType="file"></span> Détails fiche
    </h4>

    <div class="details-container" nz-row>
      <nz-card class="box">
        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>Version FOST</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            {{ reglementaire.version_simulateur }}
          </div>
        </div>
        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Contrôle sur site
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            <nz-tag *ngIf="reglementaire.IsControlled" nzColor="success">
              <span>Oui</span>
            </nz-tag>
            <nz-tag *ngIf="!reglementaire.IsControlled" nzColor="error">
              <span>Non</span>
            </nz-tag>
          </div>
        </div>
        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Infobulle à afficher
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            {{ reglementaire.infobulle }}
          </div>
        </div>

        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>Lien PDF</div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            <a [href]="reglementaire.pdf_link" target="_blank">{{
              reglementaire.pdf_link
            }}</a>
          </div>
        </div>

        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Informations obligatoires
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            <quill-editor
              [(ngModel)]="reglementaire.infos_obligatoires"
              [disabled]="true"
              [modules]="quillModules"
              placeholder="Informations obligatoires ..."
            ></quill-editor>
          </div>
        </div>

        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Documents justificatifs demandés
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            {{ reglementaire.has_docs_justificatifs ? "Oui" : "Non" }}
          </div>
        </div>

        <div
          *ngIf="reglementaire.has_docs_justificatifs"
          class="element-detail-row"
          nz-row
        >
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Documents justificatifs
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            <quill-editor
              [(ngModel)]="reglementaire.docs_justificatifs"
              [disabled]="true"
              [modules]="quillModules"
              placeholder="Documents justificatifs"
            ></quill-editor>
          </div>
        </div>

        <div class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Fiche MaPrimeRénov'
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            {{ reglementaire.has_mpr ? "Oui" : "Non" }}
          </div>
        </div>

        <div *ngIf="reglementaire.has_mpr" class="element-detail-row" nz-row>
          <div [nzSpan]="6" class="field-grey-label" nz-col>
            Informations obligatoires MaPrimeRénov'
          </div>
          <div [nzSpan]="1" class="field-grey-label" nz-col>:</div>
          <div [nzSpan]="17" class="wrap" nz-col>
            <quill-editor
              [(ngModel)]="reglementaire.infos_obligatoires_mpr"
              [disabled]="true"
              [modules]="quillModules"
            ></quill-editor>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
  <ng-template #popConfirmIcon></ng-template>
</nz-content>
