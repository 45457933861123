import {
  DatePipe,
  NgOptimizedImage,
  registerLocaleData,
} from '@angular/common';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import fr from '@angular/common/locales/fr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzAlertComponent } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarComponent } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import {
  NzModalComponent,
  NzModalContentDirective,
  NzModalService,
} from 'ng-zorro-antd/modal';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { authInterceptor } from './interceptors/auth.interceptor';
import { AccessDeniedComponent } from './layout/access-denied/access-denied.component';
import { AuthenticatedLayoutComponent } from './layout/authenticatedLayout/authenticated-layout/authenticated-layout.component';
import { SidebarComponent } from './layout/nav/sidebar/sidebar.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { UnauthenticatedLayoutComponent } from './layout/unauthenticatedLayout/unauthenticated-layout/unauthenticated-layout.component';
import { CreateAdminComponent } from './pages/admins/create-admin/create-admin.component';
import { EditAdminComponent } from './pages/admins/edit-admin/edit-admin.component';
import { ListAdminsComponent } from './pages/admins/list-admins/list-admins.component';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { TwoFactAuthComponent } from './pages/auth/two-fact-auth/two-fact-auth.component';
import { EditBandeauInfoComponent } from './pages/bandeau-info/edit-bandeau-info/edit-bandeau-info.component';
import { ListBandeauInfoComponent } from './pages/bandeau-info/list-bandeau-info/list-bandeau-info.component';
import { DetailsBeneficiaireComponent } from './pages/beneficiaires/details-beneficiaire/details-beneficiaire.component';
import { EditBeneficiaireComponent } from './pages/beneficiaires/edit-beneficiaire/edit-beneficiaire.component';
import { ListBeneficiairesComponent } from './pages/beneficiaires/list-beneficiaires/list-beneficiaires.component';
import { CreateCategorieComponent } from './pages/categories-prix/create-categorie/create-categorie.component';
import { DetailsPrixComponent } from './pages/categories-prix/details-prix/details-prix.component';
import { EditPrixComponent } from './pages/categories-prix/edit-prix/edit-prix.component';
import { ListPrixComponent } from './pages/categories-prix/list-prix/list-prix.component';
import { DetailsClientComponent } from './pages/clients-neoscope/details-client/details-client.component';
import { EditClientComponent } from './pages/clients-neoscope/edit-client/edit-client.component';
import { ListClientsComponent } from './pages/clients-neoscope/list-clients/list-clients.component';
import { AdminDashboardComponent } from './pages/dashboard/admin/admin.component';
import { EditGestionCommercialeComponent } from './pages/gestion-commerciale/edit-gestion-commerciale/edit-gestion-commerciale.component';
import { ListGestionCommercialeComponent } from './pages/gestion-commerciale/list-gestion-commerciale/list-gestion-commerciale.component';
import { ListJournalisationComponent } from './pages/journalisation/list-journalisation/list-journalisation.component';
import { ListMesProjetsComponent } from './pages/mes-projets/list-mes-projets/list-mes-projets.component';
import { ChatComponent } from './pages/messagerie/chat/chat.component';
import { ChannelFilesComponent } from './pages/messagerie/chat/components/channel-files/channel-files.component';
import { ChannelHeaderComponent } from './pages/messagerie/chat/components/channel-header/channel-header.component';
import { ChannelMembersComponent } from './pages/messagerie/chat/components/channel-members/channel-members.component';
import { DirectoryComponent } from './pages/messagerie/chat/components/directory/directory.component';
import { FileTagComponent } from './pages/messagerie/chat/components/file-tag/file-tag.component';
import { MessageInputComponent } from './pages/messagerie/chat/components/message-input/message-input.component';
import { MessageListComponent } from './pages/messagerie/chat/components/message-list/message-list.component';
import { CreateChatComponent } from './pages/messagerie/create-chat/create-chat.component';
import { ListDiscussionsComponent } from './pages/messagerie/list-discussions/list-discussions.component';
import { CreateObligesComponent } from './pages/obliges/create-obliges/create-obliges.component';
import { EditObligesComponent } from './pages/obliges/edit-obliges/edit-obliges.component';
import { ListObligesComponent } from './pages/obliges/list-obliges/list-obliges.component';
import { PersonnalisationComponent } from './pages/personnalisation/personnalisation.component';
import { CorbeilleProjetsComponent } from './pages/projets/corbeille-projets/corbeille-projets.component';
import { DetailsFicheComponent } from './pages/projets/details-fiche/details-fiche.component';
import { DetailsProjetComponent } from './pages/projets/details-projet/details-projet.component';
import { EditProjetComponent } from './pages/projets/edit-projet/edit-projet.component';
import { ListProjetsComponent } from './pages/projets/list-projets/list-projets.component';
import { CreateReglementaireComponent } from './pages/reglementaires/create-reglementaire/create-reglementaire.component';
import { EditReglementairesComponent } from './pages/reglementaires/edit-reglementaires/edit-reglementaires.component';
import { ListReglementairesComponent } from './pages/reglementaires/list-reglementaires/list-reglementaires.component';
import { CreateManagementComponent } from './pages/role-manager/create-management/create-management.component';
import { EditManagementComponent } from './pages/role-manager/edit-management/edit-management.component';
import { ListRoleManagersComponent } from './pages/role-manager/list-role-manager/list-role-manager.component';
import { CreateSocieteComponent } from './pages/societes/create-societe/create-societe.component';
import { DetailsSocieteComponent } from './pages/societes/details-societe/details-societe.component';
import { EditSocieteComponent } from './pages/societes/edit-societe/edit-societe.component';
import { ListSocietesComponent } from './pages/societes/list-societes/list-societes.component';
import { EditStatutsCeeComponent } from './pages/statuts-cee/edit-statuts-cee/edit-statuts-cee.component';
import { ListStatutsCeeComponent } from './pages/statuts-cee/list-statuts-cee/list-statuts-cee.component';
import { EditStatutsMprComponent } from './pages/statuts-mpr/edit-statuts-mpr/edit-statuts-mpr.component';
import { ListStatutsMprComponent } from './pages/statuts-mpr/list-statuts-mpr/list-statuts-mpr.component';
import { DetailsUserComponent } from './pages/users/details-user/details-user.component';
import { EditUsersComponent } from './pages/users/edit-users/edit-users.component';
import { ListUsersComponent } from './pages/users/list-users/list-users.component';
import { DetailsZohoBugsComponent } from './pages/zoho-bugs/details-zoho-bugs/details-zoho-bugs.component';
import { ListZohoBugsComponent } from './pages/zoho-bugs/list-zoho-bugs/list-zoho-bugs.component';
import { AuthService } from './services/auth.service';
import { SidebarService } from './services/sidebar.service';
import { EditStatutComponent } from './utils/UI/edit-statut/edit-statut.component';
import { StatusTableComponent } from './utils/UI/status-table/status-table.component';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { DetailsObligeComponent } from './pages/obliges/details-oblige/details-oblige.component';
import { DetailsReglementaireComponent } from './pages/reglementaires/details-reglementaire/details-reglementaire.component';
import { DetailsAdminComponent } from './pages/admins/details-admin/details-admin.component';

registerLocaleData(fr);

export function initializeApp(
  authService: AuthService,
): () => Promise<boolean> {
  return () => firstValueFrom(authService.checkAuthStatuts());
}

const config: SocketIoConfig = {
  url: environment.apiUrl,
  options: { withCredentials: true },
};

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    AdminDashboardComponent,
    DetailsProjetComponent,
    DetailsFicheComponent,
    DetailsUserComponent,
    ListProjetsComponent,
    ListBeneficiairesComponent,
    ListObligesComponent,
    EditObligesComponent,
    EditProjetComponent,
    ListUsersComponent,
    ListSocietesComponent,
    EditUsersComponent,
    EditSocieteComponent,
    ListAdminsComponent,
    ListPrixComponent,
    LoginComponent,
    ListStatutsCeeComponent,
    StatusTableComponent,
    ListStatutsMprComponent,
    CreateObligesComponent,
    CorbeilleProjetsComponent,
    PersonnalisationComponent,
    EditStatutComponent,
    EditStatutsCeeComponent,
    EditStatutsMprComponent,
    DetailsPrixComponent,
    EditPrixComponent,
    CreateCategorieComponent,
    ListBandeauInfoComponent,
    EditBandeauInfoComponent,
    ListReglementairesComponent,
    EditReglementairesComponent,
    CreateReglementaireComponent,
    ListGestionCommercialeComponent,
    ListRoleManagersComponent,
    EditManagementComponent,
    EditGestionCommercialeComponent,
    CreateManagementComponent,
    ListDiscussionsComponent,
    ListZohoBugsComponent,
    DetailsZohoBugsComponent,
    AuthenticatedLayoutComponent,
    UnauthenticatedLayoutComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    TwoFactAuthComponent,
    CreateAdminComponent,
    EditAdminComponent,
    ListClientsComponent,
    EditClientComponent,
    DetailsClientComponent,
    ChatComponent,
    MessageListComponent,
    MessageInputComponent,
    ChannelHeaderComponent,
    DirectoryComponent,
    ChannelMembersComponent,
    ChannelFilesComponent,
    FileTagComponent,
    ListMesProjetsComponent,
    ListJournalisationComponent,
    DetailsSocieteComponent,
    CreateSocieteComponent,
    DetailsBeneficiaireComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    CreateChatComponent,
    EditBeneficiaireComponent,
    DetailsObligeComponent,
    DetailsReglementaireComponent,
    DetailsAdminComponent,
    LinkifyPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzBreadCrumbModule,
    NzListModule,
    NzCollapseModule,
    NzButtonModule,
    NzGridModule,
    NzAutocompleteModule,
    NzAvatarModule,
    NzTableModule,
    NzDividerModule,
    NzDescriptionsModule,
    NzTagModule,
    NzFormModule,
    NzToolTipModule,
    NzSelectModule,
    NzDropDownModule,
    NzInputModule,
    ReactiveFormsModule,
    NzDrawerModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    NzSwitchModule,
    NzTabsModule,
    NzCardModule,
    NzTimelineModule,
    NzTimePickerComponent,
    NzCalendarComponent,
    NzModalComponent,
    NzDatePickerModule,
    NzModalContentDirective,
    NzColorPickerModule,
    NzStepsModule,
    NzModalModule,
    NzImageModule,
    NzUploadModule,
    NzInputNumberModule,
    NgOptimizedImage,
    NzAlertComponent,
    NzSpinComponent,
    NzResultModule,
    NzSkeletonModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: fr_FR,
    },
    NzModalService,
    SidebarService,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [SidebarComponent],
})
export class AppModule {}
