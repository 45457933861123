import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AdminsService } from 'src/app/services/admins.service';

@Component({
  selector: 'app-list-admins',
  templateUrl: './list-admins.component.html',
  styleUrls: ['./list-admins.component.css'],
})
export class ListAdminsComponent implements OnInit {
  isLoadingData = true;
  toogleFilters = false;
  total_admins: number = 0;
  checked_admins = new Set<number>();
  all_checked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize = 10;
  client = 'Neoscope';
  nzShowArrow = true;
  selected_roles = [];
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  admins: any[] = [];
  selected_activation: boolean | null = null;

  constructor(
    private adminsService: AdminsService,
    private fb: NonNullableFormBuilder,
    private notification: NzNotificationService,
  ) {}

  ngOnInit(): void {
    const stringControlNames = ['search_text'];
    const arrayControlNames = ['role'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    arrayControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control([]));
    });
    this.queryForm.addControl('is_active', this.fb.control(null));
  }

  exporter() {
    if (this.all_checked) {
      this.adminsService
        .exportAllAdmins(this.client, this.queryForm.value)
        .subscribe((x) => {
          importedSaveAs(x, 'export_admins.xlsx');
        });
    } else {
      this.adminsService
        .exportAdmins(Array.from(this.checked_admins))
        .subscribe((x) => {
          importedSaveAs(x, 'export_admins.xlsx');
        });
    }
  }

  queryAdmins() {
    this.isLoadingData = true;
    this.adminsService
      .getAllAdminsOrderedById(
        this.pageIndex,
        this.pageSize,
        this.client,
        this.queryForm.value,
      )
      .subscribe((data: any) => {
        this.admins = data.rows;
        this.total_admins = data.count;
        this.isLoadingData = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.adminsService
      .getAllAdminsOrderedById(
        this.pageIndex,
        this.pageSize,
        this.client,
        this.queryForm.value,
      )
      .subscribe((data: any) => {
        this.admins = data.rows;
        this.total_admins = data.count;
        this.isLoadingData = false;
      });
  }

  cancel(): void {}

  async deleteAdmin(admin_id: number) {
    this.adminsService.deleteAdmin(admin_id).subscribe({
      next: (res) => {
        this.queryAdmins();
      },
      error: (error) => {
        this.notification.error('Erreur', error.error.message, {
          nzStyle: { backgroundColor: '#f1aaaa' },
        });
      },
    });
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_admins.add(id);
    } else {
      this.checked_admins.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.admins.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.all_checked = this.admins.every((item) =>
      this.checked_admins.has(item.id),
    );
    this.indeterminate =
      this.admins.some((item) => this.checked_admins.has(item.id)) &&
      !this.all_checked;
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_roles = [];
    this.selected_activation = null;
    this.queryAdmins();
  }

  roleToLabel(role: string): string {
    const roleMapping: { [key: string]: string } = {
      ROLE_SUPER_ADMIN: 'Super administrateur',
      ROLE_ADMIN: 'Administrateur',
      ROLE_CHARGE_AFFAIRES: "Chargé d'affaires",
      ROLE_CONTROLEUR_CEE: 'Contrôleur CEE',
      ROLE_CONTROLEUR_CEE_MPR: 'Contrôleur CEE + MPR',
      ROLE_OBSERVATEUR: 'Observateur',
    };

    return roleMapping[role] || role;
  }

  async confirmDelete() {
    if (this.checked_admins.size > 0) {
      const idsToDelete = Array.from(this.checked_admins);
      this.adminsService.deleteManyAdmins(idsToDelete).subscribe({
        next: (res) => {
          this.queryAdmins();
          this.checked_admins.clear();
          this.all_checked = false;
          this.indeterminate = false;
        },
        error: (err) => {
          this.notification.error('Erreur', err.error.message, {
            nzStyle: { backgroundColor: '#f1aaaa' },
          });
        },
      });
    }
  }
}
