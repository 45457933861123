import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormRecord,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PrixService } from 'src/app/services/prix.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { SocietesService } from 'src/app/services/societes.service';

@Component({
  selector: 'app-edit-societe',
  templateUrl: './edit-societe.component.html',
  styleUrls: ['./edit-societe.component.css'],
})
export class EditSocieteComponent {
  pageIndex = 1;
  pageSize = 10;
  client = 'Neoscope';
  editForm: FormGroup;
  societe: any;
  isApporteurAffaire: any;
  entreprises_travaux: any = [];
  categories: any;
  societe_id: any;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});
  controlArray: Array<{ index: number; show: boolean }> = [];
  focusedFields: { [key: string]: boolean } = {};
  checked = true;
  isFormModified: boolean = false;
  hasPendingFields = false;
  showButtons: boolean = false;
  obliges: any[] = [];
  adminsCommerciaux: any;
  clients: any;
  isFormSubmitting: boolean = false;

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private societesService: SocietesService,
    private prixService: PrixService,
    private projetservice: ProjetsService,
    private message: NzMessageService,
    private router: Router,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.upLoading();
    this.societe_id = this.route.snapshot.params['id'];
    try {
      this.societesService
        .getSocieteById(this.societe_id)
        .subscribe((societe: any) => {
          this.authService.canAccessClientData(societe.source);
          this.societe = societe;
          this.isApporteurAffaire = societe.isApporteurAffaire;
          this.entreprises_travaux = societe.entreprises_travaux;
          this.loadingService.offLoading();
        });
    } catch (error) {
      console.error('Error fetching societe', error);
    }

    this.prixService
      .getAllCategorieSociete(this.pageIndex, this.pageSize, this.client, '')
      .subscribe((categories: any) => {
        this.categories = categories.rows;
      });
    this.projetservice.getAllObliges().subscribe((data: any) => {
      this.obliges = data;
    });
    this.societesService.getAllCommercials().subscribe((data) => {
      this.adminsCommerciaux = data;
    });
    this.societesService.getAllClients().subscribe((data) => {
      this.clients = data;
      console.log(this.clients);
    });

    this.editForm = this.fb.group({
      name: ['', Validators.required],
      secteur_principal: ['', Validators.required],
      gestionnaire_id: [],
      secteur_activite: ['', Validators.required],
      siret: ['', [Validators.minLength(14), Validators.maxLength(14),  Validators.required]],
      source: ['', Validators.required],
      address: ['', Validators.required],
      num_client_ext: [],
      // idPartenaireCrm: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)] ],
      classement: [''],
      ville: ['', Validators.required],
      fast_cash_status: ['', Validators.required],
      fast_cash_counter: [5],
      isApporteurAffaire: [false, Validators.required],
      categorie_code: ['', Validators.required],
      entreprises_travaux: [],
      oblige_id: [],
      oblige_cdp_bat_res_coll_id: [],
      isCPResidentielChauffage: [false, Validators.required], 
      isCPRenoGlobaleCollectif: [false, Validators.required], 
      oblige_cdp_id: [],
      oblige_cdp_reno_globale_collectif: [],
      oblige_precaire_id: [],
      isCPPilotageConnecte: [false, Validators.required], 
      isCPTertiaireChauffage: [false, Validators.required], 
      shared_access: [false, Validators.required],
      oblige_cdp_pilotage_connecte: [],
    });

    Object.keys(this.editForm.controls).forEach((key) => {
      this.focusedFields[key] = false;
    });

    this.editForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  checkFormChanges(): boolean {
    if (this.isFormSubmitting) {
      return false;
    }
    this.isFormModified = !this.editForm.pristine;
    return this.isFormModified;
  }
  editSociete() {
    if (this.editForm.valid) {
      this.isFormSubmitting = true;
      const payload: { [key: string]: any } = {};
      Object.keys(this.editForm.controls).forEach((key) => {
        if (this.editForm.controls[key].dirty) {
          payload[key] = this.editForm.controls[key].value;
        }
      });

      this.societesService
        .editSociete(this.societe_id, payload, this.entreprises_travaux)
        .subscribe({
          next: (updatedSociete: any) => {
            Object.keys(payload).forEach((key) => {
              this.societe[key] = updatedSociete[key];
            });
            this.message.create(
              'success',
              'Vos modifications ont été enregistrées'
            );
            this.router.navigate(['/societes']);
          },
          error: (error: any) => {
            console.error('Error updating societe:', error);
          },
        });
    } else {
      console.log(this.editForm);
      console.log('Form is invalid');
      Object.values(this.editForm.controls).forEach(control => {
        if (control.invalid) {
          const payload = { ...this.societe };
          Object.keys(this.editForm.controls).forEach((key) => {
            if (this.editForm.controls[key]) {
              payload[key] = this.editForm.controls[key].value;
            }
          });
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  siretValidator: ValidatorFn = (control: AbstractControl) => {
    const siret = control.value;
    if (siret) {
      const digitOnly = siret.replace(/\s+/g, '');
      console.log(digitOnly);
      console.log(digitOnly.length);
      if (digitOnly.length !== 14) {
        return { invalidLength: true };
      }
    }
    return null;
  };

  cancelChanges() {
    throw new Error('Method not implemented.');
  }

  goBack() {
    this.router.navigate(['/societes']);
  }

  addNewEntrepriseTravaux() {
    if (this.isValidEntrepriseTravaux()) {
      const new_entreprise_travaux = { nom: '', id_crm: '' };
      this.entreprises_travaux.push(new_entreprise_travaux);
    }
  }
  deleteEntrepriseTravaux(index: number) {
    this.entreprises_travaux.splice(index, 1);
  }

  isValidEntrepriseTravaux() {
    let is_valid = true;
    console.log(this.entreprises_travaux);
    this.entreprises_travaux.forEach((et: any) => {
      if (et.id_crm === '' || et.nom === '') {
        is_valid = false;
      }
    });
    return is_valid;
  }

  toggleButtons() {
    this.showButtons = !this.showButtons;
  }
}
