<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit></ng-template>
  <div class="form-container">
    <div class="title">Modification du Rôle Manager</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Manager</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input nz-input [(ngModel)]="maangerFullName" formControlName="manager_id"  />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Managé</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                  <input
                    nz-input
                    [(ngModel)]="managedFullName"
                    formControlName="managed_id"
                  />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col nzRequired [nzSpan]="4"
              >État</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="7">
              <nz-select
                class="custom-filter-field"
                formControlName="etat_management"
                [(ngModel)]="etat_management"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option nzValue="1" nzLabel="Actif"></nz-option>
                <nz-option nzValue="0" nzLabel="En attente"></nz-option>
                <nz-option nzValue="-1" nzLabel="Refusé"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content"></div>

      <div class="button-wrapper">
        <div class="additional-buttons">
          <button
            nz-button
            nzTooltipTitle="Sauvegarder"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small save-button"
            [disabled]="!checkFormChanges()"
            (click)="editManager()"
          >
            <span class="icon">
              <span nz-icon nzType="save"></span>
            </span>
          </button>
          <button
            nz-button
            nzTooltipTitle="Annuler"
            nzTooltipPlacement="leftTop"
            nz-tooltip
            class="circle-button small cancel-button"
            (click)="goBack()"
          >
            <span class="icon">
              <span nz-icon nzType="rollback"></span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</nz-content>
