import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormControl,
  FormRecord,
  NonNullableFormBuilder,
} from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { AuthService } from 'src/app/services/auth.service';
import { NeoscopeService } from 'src/app/services/neoscope.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css'],
})
export class ListUsersComponent implements OnInit {
  users: any[] = [];
  pageIndex = 1;
  checked_users = new Set<number>();
  clients: any[] = [];
  societes: any[] = [];
  gestionnaires: any[] = [];
  pageSize = 100;
  checked = false;
  indeterminate = false;
  total_users: number = 0;
  isLoadingData = true;
  nzShowArrow = true;
  selected_societes = [];
  selected_clients = [];
  selected_gestionnaires = [];
  queryForm: FormRecord<FormControl<any>> = this.fb.record({});
  toggleFilters = false;
  isDesktop: boolean = true;

  constructor(
    private usersService: UsersService,
    private message: NzMessageService,
    private readonly authService: AuthService,
    private fb: NonNullableFormBuilder,
    private neoscopeService: NeoscopeService
  ) {}
  ngOnInit(): void {
    const stringControlNames = ['source', 'activation', 'search_text'];
    const arrayControlNames = ['societe', 'gestionnaire'];
    stringControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control(''));
    });
    arrayControlNames.forEach((controlName) => {
      this.queryForm.addControl(controlName, this.fb.control([]));
    });
    this.checkViewport();
    this.usersService.getAllCommercials().subscribe((gestionnaires: any) => {
      this.gestionnaires = gestionnaires;
    });
    this.usersService.getAllSocietes().subscribe((data: any) => {
      this.societes = data;
    });
    this.neoscopeService.getClientsNeoscopeByIDs(this.authService.getUserClientsAccess()).subscribe((res: any) => {
      this.clients = res;
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewport();
  }

  private checkViewport() {
    this.isDesktop = window.innerWidth > 768;
  }

  listOfColumn = [
    { title: 'Nom complet', width: '120px' },
    { title: 'Société', width: '130px' },
    { title: 'Tél', width: '70px' },
    { title: 'Email', width: '130px' },
    { title: 'Client', width: '60px' },
    { title: "Chargé d'Affaires", width: '100px' },
    { title: 'Date de création', width: '80px' },
    { title: 'Activation compte', width: '90px' },
    { title: 'Manager', width: '60px' },
    { title: 'Nb de simulations enregistrées', width: '130px' },
    { title: "Nb d'offre CEE émises", width: '100px' },
  ];

  getStatus(user: any) {
    switch (user.is_active) {
      case 0:
        return {
          color: 'gold',
          text: 'En attente',
        };

      case 1:
        return {
          color: 'green',
          text: 'Actif',
        };
      default:
        return {
          color: 'red',
          text: 'Refusé',
        };
    }
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.checked_users.add(id);
    } else {
      this.checked_users.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.users.forEach((item) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.users.every((item) => this.checked_users.has(item.id));
    this.indeterminate =
      this.users.some((item) => this.checked_users.has(item.id)) &&
      !this.checked;
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex } = params;
    this.pageIndex = pageIndex;
    this.isLoadingData = true;
    this.usersService
      .getUsersFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.users = data.rows;
        this.total_users = data.count;
        this.isLoadingData = false;
      });
  }

  queryUsers() {
    this.isLoadingData = true;
    this.usersService
      .getUsersFilter(this.pageIndex, this.pageSize, this.queryForm.value)
      .subscribe((data: any) => {
        this.users = data.rows;
        this.total_users = data.count;
        this.isLoadingData = false;
      });
  }

  async confirmDelete(): Promise<void> {
    this.isLoadingData = true;
    for (const user of this.checked_users) {
      await this.usersService.delete(user);
    }
    this.checked_users.clear();
    this.queryUsers();
  }

  resetForm(): void {
    this.queryForm.reset();
    this.selected_gestionnaires = [];
    this.selected_societes = [];
    this.queryUsers();
  }

  async deleteUser(user_id: number): Promise<void> {
    this.isLoadingData = true;
    await this.usersService.delete(user_id);
    this.queryUsers();
  }

  cancel(): void {}

  exporter() {
    this.isLoadingData = true;
    const date = new Date();

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}_${hour}-${minute}`;
    if (this.checked) {
      this.usersService.exportAllUsers(this.queryForm.value).subscribe((x) => {
        importedSaveAs(x, 'Export_Utilisateurs_' + formattedDate + '.xlsx');
        this.isLoadingData = false;
      });
    } else {
      this.usersService
        .exportUsers(Array.from(this.checked_users))
        .subscribe((x) => {
          importedSaveAs(x, 'Export_Utilisateurs_' + formattedDate + '.xlsx');
          this.isLoadingData = false;
        },
        () => {
          this.isLoadingData = false;
          this.message.error(
            'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique'
          );
        }
      );
    }
  }

  validerUser(user_id: number) {
    this.isLoadingData = true;
    this.usersService.validateUser(user_id).subscribe(
      () => {
        this.message.success('Le compte utilisateur a été validé avec succès');
        this.queryUsers();
      },
      () => {
        this.isLoadingData = false;
        this.message.error(
          'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique'
        );
      }
    );
  }

  refuserUser(user_id: number) {
    this.isLoadingData = true;
    this.usersService.refuseUser(user_id).subscribe(
      () => {
        this.message.success('Le compte utilisateur a été refusé');
        this.queryUsers();
      },
      () => {
        this.isLoadingData = false;
        this.message.error(
          'Une erreur est survenue. Veuillez réessayer plus tard ou contacter le service informatique'
        );
      }
    );
  }

  canEdit(tab_name: string) {
    return this.authService.canEdit(tab_name);
  }

  canDelete(tab_name: string) {
    return this.authService.canDelete(tab_name);
  }

  canAction(action_name: string) {
    return this.authService.canAction(action_name);
  }
}
