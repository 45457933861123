<nz-content>
  <div class="inner-content">
    <div class="search-panel" nz-row nzJustify="space-between">
      <form [formGroup]="queryForm" [nzSpan]="21" nz-col nz-form>
        <nz-input-group
          [nzSuffix]="suffixIconSearch"
          class="main-search"
          nzSize="large"
        >
          <input
          (keyup.enter)="queryData()"
            class="main-search-input"
            formControlName="search_text"
            nz-input
            placeholder="Rechercher une gestion par nom département..."
            type="text"
          />
        </nz-input-group>
      </form>
      <ng-template #suffixIconSearch>
        <span (click)="queryGestion()" nz-icon nzType="search"></span>
      </ng-template>
      <div [nzSpan]="3" class="padding-left-10" nz-col>
        <button
          (click)="toogleFilters = !toogleFilters"
          class="filters-toogle secondary-button"
          nz-button
          nzType="default"
        >
          <span nz-icon nzType="filter"></span>
          Filtres
        </button>
      </div>
    </div>
    <br *ngIf="isLoadingData || gestions.length === 0" />
    <div *ngIf="toogleFilters" class="filters-panel">
      <form [formGroup]="queryForm" class="ant-advanced-search-form" nz-form>
        <div nz-row>
          <div nz-col nzSpan="20">
            <div class="filter-fields" nz-row>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_res_ind"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_residentiel_individuel"
                      id="statut-cee"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Résidentiel Individuel"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_res_ind"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_res_coll"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_residentiel_collectif"
                      id="statut-mpr"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Résidentiel Collectif"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_res_coll"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_tertiaire"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_tertiaire"
                      id="projet_fiches"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Tertiaire"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_tertiaire"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_transport"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_transport"
                      id="type_de_projet"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Transport"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_transport"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="6" nz-col>
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_agriculture"
                      [nzDropdownMatchSelectWidth]="false"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_agriculture"
                      id="projet-bloque"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Agriculture"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_agriculture"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="5" nz-col nzAlign="bottom">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_industrie"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_industrie"
                      id="industrie"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Industrie"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_industrie"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div [nzSpan]="5" nz-col nzAlign="bottom">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      [(ngModel)]="selected_resp_reseaux"
                      [nzShowArrow]="nzShowArrow"
                      class="custom-filter-field"
                      formControlName="responsable_reseaux"
                      id="oblige"
                      nzAllowClear="true"
                      nzMode="multiple"
                      nzPlaceHolder="Responsable Réseaux"
                      nzSize="small"
                    >
                      <nz-option
                        *ngFor="let responsable of responsables_reseaux"
                        [nzLabel]="responsable.first_name + ' ' + responsable.last_name"
                        [nzValue]="responsable.id"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div class="filter-fields" nz-col></div>
          </div>
          <div nz-col nzJustify="center" nzSpan="4">
            <div nz-row nzJustify="center">
              <button
                (click)="resetForm()"
                class="filter-button"
                nz-button
                nzBlock
                nzShape="round"
                nzSize="small"
                nzType="default"
              >
                Effacer
              </button>
            </div>
            <div nz-row nzJustify="center">
              <button
                (click)="queryGestion()"
                class="filter-button blue-grey-button-color"
                nz-button
                nzBlock
                nzShape="round"
                nzSize="small"
                nzType="primary"
              >
                Appliquer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <nz-table
    
      #fixedTable
      [nzData]="gestions"
      [nzFrontPagination]="false"
      [nzLoading]="isLoadingData"
      [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize"
      [nzScroll]="{ x: '2000px' }"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzTotal]="totalGestions"
      [nzShowTotal]="total_gestions_template"
      class="gestion-list-table"
      nzPaginationPosition="both"
    >

    <ng-template #total_gestions_template>
      <span>{{ totalGestions }} {{ totalGestions == 1 ? ' résultat' : ' résultats' }} </span>
      <ng-template #popConfirmIcon> </ng-template>
    </ng-template>
      <thead class="liste-gestions-head">
        <tr>
          <th nzWidth="90px">Code</th>
          <th>Nom département</th>
          <th>Responsable résidentiel individuel</th>
          <th>Responsable résidentiel collectif</th>
          <th>Responsable tertiaire</th>
          <th>Responsable industriel</th>
          <th>Responsable transport</th>
          <th>Responsable agriculture</th>
          <th>Responsable réseaux</th>
          <th nzRight nzWidth="90px" nzAlign="center" *ngIf="canEdit('GESTION-COMMERCIALE')">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let gestion of fixedTable.data">
          <td>{{ gestion.code }}</td>
          <td>{{ gestion.nom }}</td>
          <td>
            {{ gestion.responsableResidentielIndividuel?.first_name || "N/A" }}
            {{ gestion.responsableResidentielIndividuel?.last_name }}
          </td>
          <td>
            {{ gestion.responsableResidentielCollectif?.first_name || "N/A" }}
            {{ gestion.responsableResidentielCollectif?.last_name }}
          </td>
          <td>
            {{ gestion.responsableTertiaire?.first_name || "N/A" }}
            {{ gestion.responsableTertiaire?.last_name }}
          </td>
          <td>
            {{ gestion.responsableIndustrie?.first_name || "N/A" }}
            {{ gestion.responsableIndustrie?.last_name }}
          </td>
          <td>
            {{ gestion.responsableTransport?.first_name || "N/A" }}
            {{ gestion.responsableTransport?.last_name }}
          </td>
          <td>
            {{ gestion.responsableAgriculture?.first_name || "N/A" }}
            {{ gestion.responsableAgriculture?.last_name }}
          </td>
          <td>
            {{ gestion.responsableReseaux?.first_name || "N/A" }}
            {{ gestion.responsableReseaux?.last_name }}
          </td>
          <td nzRight nzWidth="90px" nzAlign="center" *ngIf="canEdit('GESTION-COMMERCIALE')">
            <button
              [nzDropdownMenu]="menu"
              class="gestion-actions-button"
              nz-button
              nz-dropdown
            >
              <span nz-icon nzType="setting"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li (click)="editGestion(gestion.code)" nz-menu-item>
                  <a
                    ><span nz-icon nzTheme="outline" nzType="edit"></span>
                    Éditer</a
                  >
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-content>
