<nz-content class="content-wrapper">
  <ng-template #suffixIconEdit> </ng-template>
  <ng-template #cadenas><span nz-icon nzType="lock"></span></ng-template>
  <div class="form-container">
    <div class="title">Projet</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Nom du projet</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez renseigner ce champ avec un maximum de 30 caractères"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.name"
                  formControlName="name"
                  maxlength="30"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Référence</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez renseigner ce champ avec un maximum de 13 caractères"
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.numeroDossier"
                  formControlName="numeroDossier"
                  maxlength="13"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Statut CEE
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le satut CEE."
            >
              <nz-select
                class="custom-filter-field"
                [ngModel]="projet.stage"
                formControlName="stage"
                nzAllowClear="true"
                nzSize="small"
                [nzDropdownMatchSelectWidth]="false"
                [ngModel]="projet.stage"
              >
                <nz-option
                  *ngFor="let statut of statuts"
                  [nzValue]="statut.code"
                  [nzLabel]="statut.label"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Utilisateur
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner l'utilisateur."
              formControlName="user"
            >
              <nz-select
                id="user"
                formControlName="user"
                class="custom-filter-field"
                [nzPlaceHolder]="
                  projet.user.firstname + '' + projet.user.lastname
                "
                [ngModel]="projet.user.id"
                formControlName="partenaire_id"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let user of users"
                  [nzValue]="user.id"
                  [nzLabel]="user.firstname + ' ' + user.lastname"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Bénéficiaire</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le bénéficiare"
            >
              <nz-input-group [nzSuffix]="cadenas" class="edit-field">
                <input
                  nz-input
                  [ngModel]="
                    projet.beneficiaire
                      ? projet.beneficiaire.prenom +
                        ' ' +
                        projet.beneficiaire.nom
                      : ''
                  "
                  formControlName="beneficiaire"
                  readonly
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Secteur CEE
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le secteur CEE."
            >
              <nz-select
                formControlName="secteur"
                [(ngModel)]="projet.secteur"
                [nzPlaceHolder]="projet.secteur"
                class="custom-filter-field"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  nzValue="RESIDENTIEL"
                  nzLabel="Résidentiel"
                ></nz-option>
                <nz-option nzValue="TERTIAIRE" nzLabel="Tertiaire"></nz-option>
                <nz-option nzValue="INDUSTRIE" nzLabel="Industrie"></nz-option>
                <nz-option nzValue="TRANSPORT" nzLabel="Transport"></nz-option>
                <nz-option
                  nzValue="AGRICULTURE"
                  nzLabel="Agriculture"
                ></nz-option>
                <nz-option nzValue="RESEAU" nzLabel="Réseaux"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Offre automatique</nz-form-label
            >
            <nz-form-control [nzSpan]="17" nz-col>
              <nz-switch formControlName="offre_automatique"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Origine de l'Affaire</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner l'origine de l'Affaire."
            >
              <nz-select
                formControlName="source"
                [(ngModel)]="projet.source"
                class="custom-filter-field"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option nzValue="Neoscope" nzLabel="Neoscope"></nz-option>
                <nz-option nzValue="Viessmann" nzLabel="Viessmann"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row class="row-content">
        <div nz-col [nzSpan]="24" nzSm="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="4"
              >Description interne</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="19"
              nzErrorTip="Ce champ est obligatoire."
            >
              <fieldset>
                <textarea
                  class="default-border-radius"
                  maxlength="1000"
                  nz-input
                  rows="3"
                  [ngModel]="projet.commentaire"
                  formControlName="commentaire"
                ></textarea>
              </fieldset>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Gestion</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Chargé(-e) d'Affaires</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le chargé(-e) d'Affaires."
            >
              <nz-select
                class="custom-filter-field"
                id="controleur"
                formControlName="superAdmin_id"
                [ngModel]="projet.admin?.id"
                [nzPlaceHolder]="
                  projet.admin
                    ? projet.admin.first_name + ' ' + projet.admin.last_name
                    : ''
                "
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let admin of admins"
                  [nzValue]="admin.id"
                  [nzLabel]="admin.first_name + ' ' + admin.last_name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Contrôleur(euse)</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le/la contrôleur(euse)"
            >
              <nz-select
                class="custom-filter-field"
                id="controleur"
                formControlName="controleur_id"
                [ngModel]="projet.controleur?.id"
                [nzPlaceHolder]="
                  projet.controleur
                    ? projet.controleur.first_name +
                      ' ' +
                      projet.controleur.last_name
                    : ''
                "
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let controleur of controleurs"
                  [nzValue]="controleur.id"
                  [nzLabel]="controleur.first_name + ' ' + controleur.last_name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Financeur</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
            >Obligé
          </nz-form-label>
          <nz-form-control
            nz-col
            [nzSpan]="14"
            nzErrorTip="Veuillez sélectionner l'obligé."
          >
            <nz-select
              [ngModel]="projet.oblige.id"
              class="edit-field"
              [nzPlaceHolder]="projet.oblige.nom"
              formControlName="oblige_id"
              nzAllowClear="true"
              nzSize="small"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option
                *ngFor="let oblige of obliges"
                [nzValue]="oblige.id"
                [nzLabel]="oblige.nom"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Valorisation CEE</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Volume CEE</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.resultat"
                  formControlName="resultat"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired=""
              >Prix unitaire bénéficiaire (euros)</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire."
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.clientTaux"
                  formControlName="clientTaux"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Montant prime bénéficiaire (euros)</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire."
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.clientPart"
                  formControlName="clientPart"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired=""
              >Commission société travaux (euros)</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire."
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.partenaireTaux"
                  formControlName="partenaireTaux"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Société travaux</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Société
            </nz-form-label>
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner la socité."
            >
              <nz-select
                id="societe"
                formControlName="societe_id"
                [ngModel]="projet.societe_id"
                class="edit-field"
                [nzPlaceHolder]="projet.societe.name"
                formControlName="societe_id"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let societe of societes"
                  [nzValue]="societe.id"
                  [nzLabel]="societe.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Collaborateur</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le collaborateur."
            >
              <nz-select
                formControlName="collaborateur_id"
                [ngModel]="projet.collaborateur_id"
                class="edit-field"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let collaborateur of collaborateurs"
                  [nzValue]="collaborateur.id"
                  [nzLabel]="
                    collaborateur.firstname + ' ' + collaborateur.lastname
                  "
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Gestion MPR</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Statut MPR</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Veuillez sélectionner le satut MPR"
            >
              <nz-select
                formControlName="mpr_id"
                [ngModel]="projet.statut_mpr.code"
                [nzPlaceHolder]="projet.statut_mpr.code"
                class="edit-field"
                nzSize="small"
                nzAllowClear="true"
                [nzDropdownMatchSelectWidth]="false"
              >
                <nz-option
                  *ngFor="let statut of statuts_MPR"
                  [nzValue]="statut.code"
                  [nzLabel]="statut.label"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >Montant (euros)</nz-form-label
            >
            <nz-form-control
              nz-col
              [nzSpan]="14"
              nzErrorTip="Ce champ est obligatoire."
            >
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.primeRenovAmount"
                  formControlName="primeRenovAmount"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>

  <div class="form-container">
    <div class="title">Connecteur CRM</div>
    <form
      class="form-layout"
      nz-form
      [formGroup]="editForm"
      (ngSubmit)="editProject()"
    >
      <div nz-row class="row-content">
        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
              >ID Workdrive</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [(ngModel)]="projet.idworkdrive"
                  formControlName="idworkdrive"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
          <nz-form-item>
            <nz-form-label nzLabelWrap nz-col [nzSpan]="8"
              >Lien Workdrive public</nz-form-label
            >
            <nz-form-control nz-col [nzSpan]="14">
              <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
                <input
                  nz-input
                  [ngModel]="projet.offre_file_name"
                  formControlName="offre_file_name"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-col [nzSpan]="12" nzSm="12" nzXs="24">
        <nz-form-item>
          <nz-form-label nzLabelWrap nz-col [nzSpan]="8" nzRequired
            >ID Affaire Zoho</nz-form-label
          >
          <nz-form-control nz-col [nzSpan]="14">
            <nz-input-group [nzSuffix]="suffixIconEdit" class="edit-field">
              <input
                nz-input
                [ngModel]="projet.idProjetcrm"
                formControlName="idProjetcrm"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
    <div class="button-wrapper">
      <div class="additional-buttons">
        <button
          nz-button
          nzTooltipTitle="Sauvegarder"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small save-button"
          [disabled]="!checkFormChanges()"
          type="submit"
          (click)="editProject()"
        >
          <span class="icon">
            <span nz-icon nzType="save"></span>
          </span>
        </button>
        <button
          nz-button
          nzTooltipTitle="Annuler"
          nzTooltipPlacement="leftTop"
          nz-tooltip
          class="circle-button small cancel-button"
          (click)="goBack()"
        >
          <span class="icon">
            <span nz-icon nzType="rollback"></span>
          </span>
        </button>
      </div>
    </div></div
></nz-content>
