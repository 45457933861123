import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormRecord, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PrixService } from 'src/app/services/prix.service';
import { ProjetsService } from 'src/app/services/projets.service';
import { SocietesService } from 'src/app/services/societes.service';

@Component({
  selector: 'app-create-societe',
  templateUrl: './create-societe.component.html',
  styleUrl: './create-societe.component.css'
})
export class CreateSocieteComponent {
  pageIndex = 1;
  pageSize = 10;
  client = 'Neoscope';
  createForm: FormGroup;
  societe: any;
  isApporteurAffaire: boolean = false;
  entreprises_travaux: any = [];
  categories: any;
  validateForm: FormRecord<FormControl<string>> = this.fb.record({});
  controlArray: Array<{ index: number; show: boolean }> = [];
  focusedFields: { [key: string]: boolean } = {};
  checked = true;
  isFormModified: boolean = false;
  hasPendingFields = false;
  showButtons: boolean = false;
  obliges: any[] = [];
  adminsCommerciaux: any;
  clients: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private societesService: SocietesService,
    private prixService: PrixService,
    private projetservice: ProjetsService,
    private message: NzMessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.prixService
      .getAllCategorieSociete(this.pageIndex, this.pageSize, this.client, '')
      .subscribe((categories: any) => {
        this.categories = categories.rows;
      });
    this.projetservice.getAllObliges()
      .subscribe((data: any) => {
        this.obliges = data;
      })
    this.societesService.getAllCommercials()
      .subscribe((data) => {
        this.adminsCommerciaux = data;
      })
    this.societesService.getAllClients()
      .subscribe((data) => {
        this.clients = data;
      })

    this.createForm = this.fb.group({
      name: ['', Validators.required],
      secteur_principal: ['', Validators.required],
      gestionnaire_id: [],
      secteur_activite: ['', Validators.required],
      siret: ['', [Validators.minLength(14), Validators.maxLength(14),  Validators.required]],
      source: ['', Validators.required],
      address: ['', Validators.required],
      num_client_ext: [],
      // idPartenaireCrm: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)] ],
      classement: [''],
      ville: ['', Validators.required],
      fast_cash_status: ['', Validators.required],
      fast_cash_counter: [5],
      isApporteurAffaire: [false, Validators.required],
      categorie_code: ['', Validators.required],
      entreprises_travaux: [],
      oblige_id: [],
      oblige_cdp_bat_res_coll_id: [],
      isCPResidentielChauffage: [false, Validators.required], 
      isCPRenoGlobaleCollectif: [false, Validators.required], 
      oblige_cdp_id: [],
      oblige_cdp_reno_globale_collectif: [],
      oblige_precaire_id: [],
      isCPPilotageConnecte: [false, Validators.required], 
      isCPTertiaireChauffage: [false, Validators.required], 
      shared_access: [false, Validators.required],
      oblige_cdp_pilotage_connecte: [],
    });

    Object.keys(this.createForm.controls).forEach((key) => {
      this.focusedFields[key] = false;
    });

    this.createForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }
  checkFormChanges(): boolean {
    this.isFormModified = !this.createForm.pristine;
    return this.isFormModified;
  }
  

  addNewEntrepriseTravaux() {
    if (this.isValidEntrepriseTravaux()) {
      const new_entreprise_travaux = { nom: '', id_crm: '' };
      this.entreprises_travaux.push(new_entreprise_travaux);
    }
  }

  deleteEntrepriseTravaux(index: number) {
    this.entreprises_travaux.splice(index, 1);
  }
  isValidEntrepriseTravaux() {
    let is_valid = true;
    this.entreprises_travaux.forEach((et: any) => {
      if (et.id_crm === '' || et.nom === '') {
        is_valid = false;
      }
    });
    return is_valid;
  }

  
  createBasicMessage(): void {
    this.message.success('la création est faite avec succès', {
      nzDuration: 3000
    });
  }
  createFailureMessage(): void {
    this.message.error('formulaire invalide', {
      nzDuration: 3000
    });
  }
  goBack() {
    this.router.navigate(['/societes']);
  }
  createSociete() {
    if (this.createForm.valid) {
      const payload = { ...this.societe };
      Object.keys(this.createForm.controls).forEach((key) => {
        if (this.createForm.controls[key]) {
          payload[key] = this.createForm.controls[key].value;
        }
      });
      payload['entreprises_travaux'] = this.entreprises_travaux;
      this.societesService
        .crateSociete(payload)
        .subscribe((data: any) => {
          this.societe = data;
          this.createBasicMessage();
          this.router.navigate(['/societes']);
        });
    } else {
      this.createFailureMessage();
      Object.values(this.createForm.controls).forEach(control => {
        if (control.invalid) {
          const payload = { ...this.societe };
          Object.keys(this.createForm.controls).forEach((key) => {
            if (this.createForm.controls[key]) {
              payload[key] = this.createForm.controls[key].value;
            }
          });
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  
}
